<template>
  <Alert type="danger" v-if="!!error">
    {{ error?.message }}
    <template
      #description
      v-if="Array.isArray(error.description) && error.description.length > 0"
    >
      <ul role="list">
        <li v-for="text in error.description" :key="text">{{ text }}</li>
      </ul>
    </template>
  </Alert>
</template>

<script lang="ts">
import { PropType } from "vue";
import { Options, Vue } from "vue-class-component";

import { FormSubmitError } from "@/core/Form";

import Alert from "@/components/snap-ui/Alert.vue";

@Options({
  props: {
    error: Object as PropType<FormSubmitError>,
  },
  components: { Alert },
})
export default class FormError extends Vue {
  error?: FormSubmitError = undefined;
}
</script>
