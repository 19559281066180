import { createApp } from "vue";

import App from "./App.vue";

import "./registerServiceWorker";

import router from "./router";
import store from "./store";
import { apolloProvider } from "./providers/apollo.provider";
import * as Sentry from "./util/sentry";

const app = createApp(App);

app.config.globalProperties.$store = store;
app.config.globalProperties.$router = router;

// @ts-ignore
if (window.Cypress) window.app = app.config.globalProperties;

Sentry.init(app);

app.use(store).use(router).use(apolloProvider).mount("#app");
