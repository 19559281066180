<template>
  <!-- Start of snap-mobile Zendesk Widget script -->
  <component
    v-if="isAllowedRoute"
    :is="'script'"
    id="ze-snippet"
    src="https://static.zdassets.com/ekr/snippet.js?key=5bc2bbff-7f33-4a91-a392-f210c3828178"
  >
  </component>
  <!-- End of snap-mobile Zendesk Widget script -->
</template>

<script lang="ts">
import { watch } from "vue";
import { mapGetters } from "vuex";
import { Options, Vue } from "vue-class-component";

import { FeatureToggle } from "@/store/treatments.module";

const ALLOWED_ROUTES = [/*"login",*/ "home"];

@Options({
  computed: { ...mapGetters(["featureOn"]) },
})
export default class ZendeskHelpWidget extends Vue {
  featureOn?: FeatureToggle;

  mounted() {
    this.onRouteUpdate(this.$route);

    watch<typeof this.$route>(
      () => this.$route,
      (to: typeof this.$route) => {
        this.onRouteUpdate(to);
      }
    );
  }

  get isAllowedRoute() {
    return (
      this.featureOn?.("USER-743-show-zendesk-help-widget") &&
      ALLOWED_ROUTES.includes(this.$route.name as string)
    );
  }

  onRouteUpdate(to: typeof this.$route) {
    if (this.featureOn?.("USER-743-show-zendesk-help-widget")) {
      const iframe = document.getElementById("launcher");

      if (iframe) {
        iframe.style.display = ALLOWED_ROUTES.includes(to.name as string)
          ? "block"
          : "none";
      }
    }
  }
}
</script>
