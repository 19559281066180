import { Module, GetterTree, ActionTree, MutationTree } from "vuex";

import type { RootState } from "./index";

export type ExpectType =
  | "permission"
  | "impersonate"
  | "complete"
  | "redirect"
  | "invite";

export type RequiredType = "firstName" | "lastName" | "phoneNumber";

export type Scenario = {
  skipConsumerPassing?: boolean;
};

export const isValidRequiredType = (name: string): boolean =>
  ["firstName", "lastName", "phoneNumber"].includes(name);

export type ConsumerData = {
  redirectTo?: string;
  consumer?: string;
  expect?: ExpectType;
  required?: Array<RequiredType>;
  scenario?: Scenario;
};

const state = {};

const getters: GetterTree<ConsumerData, RootState> = {
  redirectTo: (state) => state.redirectTo,
  expect: (state) => state.expect,
  consumer: (state) => state.consumer,
  requiredAttributes: (state) => state.required,
  skipConsumerPassing: (state) => !!state.scenario?.skipConsumerPassing,
};

const mutations: MutationTree<ConsumerData> = {
  setConsumeData(state, consumerData: ConsumerData) {
    Object.assign(state, consumerData);
  },

  clearConsumerData(state) {
    delete state.redirectTo;
    delete state.consumer;
    delete state.expect;
    delete state.required;
    delete state.scenario;
  },
};

const actions: ActionTree<ConsumerData, RootState> = {
  setConsumeData({ commit }, consumerData: ConsumerData) {
    if (consumerData) commit("setConsumeData", consumerData);
  },

  clearConsumerData({ commit }) {
    commit("clearConsumerData");
  },
};

export const consumer: Module<ConsumerData, RootState> = {
  state,
  getters,
  mutations,
  actions,
};
