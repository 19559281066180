import { RouteRecordRaw } from "vue-router";
import InternalPage from "@/layouts/InternalPage.vue";
import BasicPage from "@/layouts/BasicPage.vue";

import { PageContext } from "@/types/ui";

export const OrgsRoutes: Array<RouteRecordRaw> = [
  {
    path: "/orgs",
    name: "orgs/list",
    component: () =>
      import(/* webpackChunkName: "staff" */ "@/views/orgs/OrgListWrapper.vue"),
    meta: {
      title: "Your Organizations",
      requiresAuth: true,
      hasPermission: "computed:show_orgs_admin",
      layout: InternalPage,
      context: PageContext.OrgAdmin,
    },
  },

  {
    path: "/orgs/:orgId/messaging",
    name: "org/messaging",
    component: () =>
      import(/* webpackChunkName: "staff" */ "@/views/orgs/OrgMessaging.vue"),

    meta: {
      title: "Messaging Admin",
      requiresAuth: true,
      hasPermission: "computed:show_orgs_admin",
      layout: InternalPage,
      context: PageContext.OrgAdmin,
    },
  },

  {
    path: "/orgs/:orgId/messaging/new",
    name: "org/channel-create",
    component: () =>
      import(
        /* webpackChunkName: "staff" */ "@/views/orgs/OrgChannelCreate.vue"
      ),

    meta: {
      title: "Channel Create",
      requiresAuth: true,
      hasPermission: "computed:show_orgs_admin",
      layout: BasicPage,
      context: PageContext.OrgAdmin,
    },
  },

  {
    path: "/orgs/:orgId/messaging/:channelId/import-members",
    name: "org/channel-import-members",
    component: () =>
      import(
        /* webpackChunkName: "staff" */ "@/views/orgs/OrgChannelMembersImport.vue"
      ),

    meta: {
      title: "Channel Members Import",
      requiresAuth: true,
      hasPermission: "computed:show_orgs_admin",
      layout: InternalPage,
      context: PageContext.OrgAdmin,
    },
  },

  {
    path: "/orgs/:orgId/messaging/:channelId/import-roster-members",
    name: "org/channel-import-roster-members",
    component: () =>
      import(
        /* webpackChunkName: "staff" */ "@/views/orgs/OrgChannelRosterMembersImport.vue"
      ),

    meta: {
      title: "Channel Roster Members Import",
      requiresAuth: true,
      hasPermission: "computed:show_orgs_admin",
      layout: InternalPage,
      context: PageContext.OrgAdmin,
    },
  },

  {
    path: "/orgs/:orgId/messaging/:channelId/members",
    name: "org/channel-members",
    component: () =>
      import(
        /* webpackChunkName: "staff" */ "@/views/orgs/OrgChannelMembers.vue"
      ),

    meta: {
      title: "Channel Members",
      requiresAuth: true,
      hasPermission: "computed:show_orgs_admin",
      layout: InternalPage,
      context: PageContext.OrgAdmin,
    },
  },
  {
    path: "/orgs/:orgId/rosters",
    name: "org/rosters",
    component: () =>
      import(/* webpackChunkName: "staff" */ "@/views/orgs/OrgRoster.vue"),

    meta: {
      title: "Roster",
      requiresAuth: true,
      hasPermission: "computed:show_orgs_admin",
      layout: InternalPage,
      context: PageContext.OrgAdmin,
    },
  },
  {
    path: "/orgs/:orgId/roster/new",
    name: "org/roster-create",
    component: () =>
      import(
        /* webpackChunkName: "staff" */ "@/views/orgs/OrgRosterCreate.vue"
      ),

    meta: {
      title: "Roster Create",
      requiresAuth: true,
      hasPermission: "computed:show_orgs_admin",
      layout: BasicPage,
      context: PageContext.OrgAdmin,
    },
  },
  {
    path: "/orgs/:orgId/roster/:rosterId/members",
    name: "org/roster-members",
    component: () =>
      import(
        /* webpackChunkName: "staff" */ "@/views/orgs/OrgRosterMembers.vue"
      ),

    meta: {
      title: "Roster Members",
      requiresAuth: true,
      hasPermission: "computed:show_orgs_admin",
      layout: InternalPage,
      context: PageContext.OrgAdmin,
    },
  },
];
