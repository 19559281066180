import type { LocationQuery } from "vue-router";
import type { ConsumerData } from "@/store/consumer.module";

import { serverApiUrl, appendSearchParams } from "./api";

export const getSAMLClientData = async (
  query: LocationQuery
): Promise<ConsumerData> => {
  if (query.RelayState) {
    return {
      redirectTo: appendSearchParams(serverApiUrl("/saml/ecommerce"), {
        SAMLRequest: query.SAMLRequest as string,
        RelayState: query.RelayState as string,
      }),
      consumer: "store",
      expect: "redirect",
    };
  }

  return {
    redirectTo: appendSearchParams(serverApiUrl("/saml/grammarly"), {
      // TODO: `encodeURIComponent` was here. But as far as we use searchParams.set(), not necessary. Need to check
      SAMLRequest: query.SAMLRequest as string,
    }),
    consumer: "grammarly",
    expect: "redirect",
  };
};
