import { toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { role: "list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Alert = _resolveComponent("Alert")!

  return (!!_ctx.error)
    ? (_openBlock(), _createBlock(_component_Alert, {
        key: 0,
        type: "danger"
      }, _createSlots({
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.error?.message) + " ", 1)
        ]),
        _: 2
      }, [
        (Array.isArray(_ctx.error.description) && _ctx.error.description.length > 0)
          ? {
              name: "description",
              fn: _withCtx(() => [
                _createElementVNode("ul", _hoisted_1, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.error.description, (text) => {
                    return (_openBlock(), _createElementBlock("li", { key: text }, _toDisplayString(text), 1))
                  }), 128))
                ])
              ]),
              key: "0"
            }
          : undefined
      ]), 1024))
    : _createCommentVNode("", true)
}