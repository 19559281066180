import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputField = _resolveComponent("InputField")!
  const _component_LanguageSelect = _resolveComponent("LanguageSelect")!
  const _component_Select = _resolveComponent("Select")!
  const _directive_keyboard_submit = _resolveDirective("keyboard-submit")!

  return _withDirectives((_openBlock(), _createElementBlock("form", {
    onSubmit: _cache[4] || (_cache[4] = _withModifiers(($event: any) => (_ctx.update()), ["prevent"]))
  }, [
    _createVNode(_component_InputField, {
      class: "block mb-4",
      label: "First Name",
      type: "text",
      modelValue: _ctx.firstName,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.firstName) = $event)),
      validation: _ctx.v$.firstName
    }, null, 8, ["modelValue", "validation"]),
    _createVNode(_component_InputField, {
      class: "block mb-4",
      label: "Last Name",
      type: "text",
      modelValue: _ctx.lastName,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.lastName) = $event)),
      validation: _ctx.v$.lastName
    }, null, 8, ["modelValue", "validation"]),
    _createVNode(_component_LanguageSelect, {
      class: "block mb-4",
      label: "Language",
      modelValue: _ctx.language,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.language) = $event))
    }, null, 8, ["modelValue"]),
    (_ctx.isHsGradYearVisible)
      ? (_openBlock(), _createBlock(_component_Select, {
          key: 0,
          class: "mb-4",
          label: "High School Graduation Year",
          placeholder: "High School Graduation Year",
          modalTitle: "High School Graduation Year",
          modelValue: _ctx.hsGradYear,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.hsGradYear) = $event)),
          options: _ctx.years,
          validation: _ctx.v$.hsGradYear
        }, null, 8, ["modelValue", "options", "validation"]))
      : _createCommentVNode("", true)
  ], 32)), [
    [_directive_keyboard_submit]
  ])
}