import { apolloClient } from "@/providers/apollo.provider";

import type { Consumer } from "@/types/graphql";

import ConsumersQuery from "@/graphql/consumers.query.graphql";

export const consumersList = async (): Promise<Array<Consumer>> => {
  return apolloClient
    .query({ query: ConsumersQuery })
    .then(({ data }) =>
      [...data.consumers].sort((a: Consumer, b: Consumer) =>
        (a?.title as string) > (b?.title as string) ? 1 : -1
      )
    );
};

export const consumerByMask = async (
  redirectTo?: string
): Promise<Consumer | undefined> => {
  const consumers = await consumersList();
  return consumers.find((consumer) =>
    redirectTo?.match(new RegExp(consumer.mask as string, "i"))
  );
};

export const consumerById = async (
  id?: string
): Promise<Consumer | undefined> => {
  const consumers = await consumersList();
  return consumers.find((consumer) => consumer.id === id);
};
