import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.isAllowedRoute)
    ? (_openBlock(), _createBlock(_resolveDynamicComponent('script'), {
        key: 0,
        id: "ze-snippet",
        src: "https://static.zdassets.com/ekr/snippet.js?key=5bc2bbff-7f33-4a91-a392-f210c3828178"
      }))
    : _createCommentVNode("", true)
}