<template>
  <Loading class="w-10 h-10 mx-auto my-10" v-if="$apollo.loading"></Loading>

  <div v-if="!$apollo.loading">
    <PageSection>
      <h1 class="text-xl font-semibold text-gray-800">Participants</h1>
      <p class="text-sm text-gray-500">
        You can access participants’ Raise accounts to help fundraise more.
      </p>
      <hr class="my-3" />

      <ul
        role="list"
        class="gap-3 grid grid-cols-1 md:grid-cols-4 lg:grid-cols-4 2xl:grid-cols-5"
      >
        <li
          v-for="child in children"
          :key="child.id as string"
          class="relative bg-white p-4 border border-gray-200 rounded-lg text-center flex gap-3 md:block"
          :class="{
            'opacity-50': child.isDisabled || !canImpersonateChild(child),
          }"
        >
          <snap-dropdown
            v-if="canManageChildren"
            class="absolute top-1 right-1"
            minimal
            :options.prop="childMenuOptions(child)"
            @snap-dropdown-item-selected="onChildMenuItemSelected"
            track-current-selection="false"
            modal-type="drawer"
          />

          <snap-avatar
            :image-src="child.profilePicture || ''"
            avatar-link="javascript:void(0)"
            size="profile"
            class="inline-block"
          ></snap-avatar>

          <div class="md:mt-2 flex-1 self-center text-left md:text-center">
            <p class="text-gray-800 font-medium leading-4">
              {{ child.fullName }}
            </p>

            <snap-button
              variant="primary"
              size="sm"
              full-width
              v-if="canImpersonateChild(child)"
              @click="canImpersonateChild(child) && impersonate(child, true)"
              class="mt-2"
              :title="helpFundraiseLink"
              >Help Fundraise
            </snap-button>
          </div>
        </li>
        <li class="p-4 overflow-hidden text-center" v-if="canManageChildren">
          <button
            class="w-24 h-24 rounded-full bg-gray-100"
            @click="$emit('child:create')"
          >
            <snap-icon icon="user-add-solid" size="xl"></snap-icon>
          </button>
        </li>
      </ul>
    </PageSection>

    <PageSection v-if="parents.length > 0">
      <h1 class="text-xl font-semibold text-gray-800">Guardians</h1>
      <hr class="my-3" />

      <ul
        role="list"
        class="gap-3 grid grid-cols-1 md:grid-cols-4 lg:grid-cols-4 2xl:grid-cols-5"
      >
        <li
          v-for="parent in parents"
          :key="parent.id as string"
          class="relative bg-white p-4 border border-gray-200 rounded-lg text-center flex gap-3 md:block"
          :class="{
            'opacity-50': parent.isDisabled,
          }"
        >
          <snap-avatar
            :image-src="parent.profilePicture || ''"
            avatar-link="javascript:void(0)"
            size="profile"
            class="inline-block"
          ></snap-avatar>

          <div class="md:mt-2 flex-1 self-center text-left md:text-center">
            <p class="text-gray-800 font-medium leading-4 md:mb-2">
              {{ parent.fullName }}
            </p>
            <Link :to="{ name: 'home' }" v-if="isItMe(parent)"
              >Edit Profile
            </Link>
          </div>
        </li>
      </ul>
    </PageSection>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { mapGetters } from "vuex";
import type {
  DropdownMenuItem,
  ICON_TYPES,
} from "@snap-mobile/snap-ui/dist/types/utils";

import type { Family, User, UserWithPermissions } from "@/types/graphql";

import Loading from "@/components/ui/Loading.vue";
import PageSection from "@/components/internal/common/PageSection.vue";

import FamilyQuery from "@/graphql/family.query.graphql";

import { $confirm } from "@/services/notification";
import { subgraphUrl } from "@/util/api";
import { FeatureToggle } from "@/store/treatments.module";
import { userChildUnassign } from "@/services/user.service";
import Link from "@/components/snap-ui/Link.vue";

@Options({
  emits: ["child:create", "child:update"],
  computed: {
    ...mapGetters(["me", "featureOn", "featureOff"]),
  },

  components: {
    Link,
    Loading,
    PageSection,
  },

  apollo: {
    family: {
      query: FamilyQuery,
    },
  },
})
export default class FamilyTree extends Vue {
  me?: UserWithPermissions;
  featureOn?: FeatureToggle;
  featureOff?: FeatureToggle;

  family?: Family;

  get children(): Array<User> {
    return (this.family?.children || []) as Array<User>;
  }

  get parents(): Array<User> {
    return (this.family?.parents || []) as Array<User>;
  }

  get amIChild(): boolean {
    return this.children.findIndex((child) => child.id === this.me?.id) >= 0;
  }

  isItMe(parent: User): boolean {
    return this.me?.id === parent.id;
  }

  childMenuOptions(
    child: User
  ): Array<DropdownMenuItem & { enabled?: boolean }> {
    return [
      {
        name: "impersonate",
        value: child,
        text: "Impersonate Child",
        icon: "login-solid" as ICON_TYPES,
        divider: true,
        enabled: this.canImpersonateChild(child),
      },
      {
        name: "edit",
        value: child,
        text: "Edit",
        icon: "pencil-alt-solid" as ICON_TYPES,
        enabled: true,
      },
      {
        name: "delete",
        value: child,
        text: "Delete",
        icon: "trash-solid" as ICON_TYPES,
        enabled: true,
      },
    ].filter((item) => item.enabled);
  }

  // canImpersonateParent(user: User): boolean {
  //   // It's not me and this user the last person in an impersonation chain
  //   return this.me?.id !== user.id && this.me?.parents?.at(-1) === user.id;
  // }

  canImpersonateChild(user: User): boolean {
    // It's not me and I'm not a child
    return this.me?.id !== user.id && !this.amIChild;
  }

  get canManageChildren(): boolean {
    return (
      !!this.featureOn?.("USER-799-allow-create-child-account") &&
      !this.amIChild
    );
  }

  get helpFundraiseLink(): string {
    return `${subgraphUrl("raise")}/participants/task-manager`;
  }

  async impersonate(user: User, redirect = true) {
    await this.$store.dispatch("userImpersonate", user.id);
    await this.$store.dispatch("status", "loading");

    if (redirect) {
      location.href = this.helpFundraiseLink;
    } else {
      location.reload();
    }
  }

  // async impersonateLogout(user: User) {
  //   if (
  //     await $confirm({
  //       title: `Do you want to impersonate back a ${user.fullName}?`,
  //       type: "info",
  //     })
  //   ) {
  //     await this.$router.push({
  //       name: "link/impersonate_logout",
  //     });
  //   }
  // }

  async removeChild(child: User) {
    if (
      await $confirm({
        title: `Do you want to remove ${child.fullName}?`,
        type: "warning",
      })
    ) {
      await userChildUnassign(child.id as string);
      this.refetch();
    }
  }

  refetch() {
    this.$apollo.queries.family.refetch();
  }

  async onChildMenuItemSelected(event: CustomEvent) {
    const { name, value: child } = event.detail;

    switch (name) {
      case "impersonate":
        await this.impersonate(child, false);
        break;

      case "edit":
        this.$emit("child:update", child);
        break;

      case "delete":
        await this.removeChild(child);
        break;
    }
  }
}
</script>
