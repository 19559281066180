export type ConfigVarNameType = "GRAPHQL_URI" | "SPLIT_IO_API_KEY";
export type ConfigType = Record<ConfigVarNameType, string>;

const maybe = (name: ConfigVarNameType): string | undefined => {
  const value = window?.["__config"]?.[name];
  return value && !value.startsWith("%") ? value : undefined;
};

export const config = {
  GRAPHQL_URI: maybe("GRAPHQL_URI") || process.env.VUE_APP_GRAPHQL_URI,
  SPLIT_IO_API_KEY:
    maybe("SPLIT_IO_API_KEY") || process.env.VUE_APP_SPLIT_IO_API_KEY,
  IS_PRODUCTION: "development" !== process.env.NODE_ENV,
  DEFAULT_THEME: "mobile",
  FULLSTORY_ORG_ID: "190J6X",
};
