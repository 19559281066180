import { Options, Vue } from "vue-class-component";

@Options<Field>({
  props: {
    modelValue: String,
  },
  emits: ["update:modelValue", "focus", "blur"],
})
export default class Field extends Vue {
  modelValue!: string;

  onInput(event: Event): void {
    this.$emit("update:modelValue", (event.target as HTMLInputElement)?.value);
  }
}
