<template>
  <div>
    <div class="flex justify-between">
      <label
        for="input-file"
        class="block text-sm font-medium text-gray-700 mb-1"
        >{{ label }}
      </label>
      <span class="text-sm text-gray-500" v-if="cornerHint">
        {{ cornerHint }}
      </span>
    </div>

    <div class="flex border border-gray-300 bg-white rounded-md">
      <span
        class="inline-flex items-center px-3 bg-gray-50 text-gray-500 sm:text-sm border-r rounded-l-md cursor-pointer relative overflow-hidden"
      >
        <span>Browse...</span>
        <input
          type="file"
          id="input-file"
          @change="onFileChange($event)"
          class="absolute inset-0 opacity-0 cursor-pointer"
          :accept="accept"
        />
      </span>

      <div
        class="shadow-sm block flex-1 sm:text-sm min-w-0 px-3 py-2 overflow-hidden"
      >
        <span v-if="file" data-testid="file_name">{{ file?.name }}</span>
        <span v-if="!file" class="text-gray-500">No file selected</span>
      </div>

      <snap-icon
        icon="x-solid"
        size="xs"
        @click="clear()"
        v-if="file"
        class="cursor-pointer px-2"
      ></snap-icon>
    </div>

    <div class="pt-2">
      <p v-if="helpText" class="text-sm text-gray-500">{{ helpText }}</p>
    </div>

    <div class="pt-2" v-if="!!validation && validation.$invalid">
      <p class="text-sm text-red-500">
        {{ validation?.$silentErrors[0]?.$message }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { Validation } from "@vuelidate/core";

import { loadImage } from "@/util/file";

@Options<InputFileField>({
  props: [
    "accept",
    "cornerHint",
    "helpText",
    "label",
    "modelValue",
    "validation",
  ],
  emits: ["update:modelValue"],

  watch: {
    modelValue(value: string) {
      if (value === "") (this as unknown as InputFileField).clear();
    },
  },
})
export default class InputFileField extends Vue {
  accept?: string;
  cornerHint?: string;
  helpText?: string;
  label?: string;
  validation?: Validation;

  file?: File;
  content?: string;

  created() {
    this.file = undefined;
    this.content = "";
  }

  async onFileChange(event: Event): Promise<void> {
    const profilePicture = event.target;

    if (profilePicture && profilePicture instanceof HTMLInputElement) {
      const files = profilePicture.files;
      if (files && files.length > 0) {
        this.file = files[0];
        // TODO: It's possible not an image in the future
        this.content = await loadImage(this.file, 512);
        this.$emit("update:modelValue", this.content);
      }
    }
  }

  clear() {
    this.file = undefined;
    this.content = "";
    this.$emit("update:modelValue", this.content);
  }
}
</script>
