<template>
  <a
    :href="href || 'javascript:void(0)'"
    @click="$emit('click')"
    class="text-blue-700 font-semibold"
    :target="target || '_self'"
  >
    <snap-icon
      v-if="icon"
      :icon="icon"
      size="xs"
      class="align-text-top m-0.5"
    ></snap-icon>
    <span class="hover:underline inline-block"><slot></slot></span>
  </a>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    icon: String,
    href: String,
    target: String,
  },
  emits: ["click"],
})
export default class InlineLink extends Vue {
  href?: string;
  icon?: string;
  target?: string;
}
</script>
