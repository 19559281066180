import { ObjectDirective /*, DirectiveBinding, VNode*/ } from "vue";

const Autofocus: ObjectDirective = {
  mounted(el: HTMLElement /*, binding: DirectiveBinding, vnode: VNode*/) {
    setTimeout(() => {
      const input = el.querySelector("input");
      if (input && input.focus) input.focus();
    }, 250);
  },
};

export default Autofocus;
