import { UserChallenges } from "@/types/challenges";
import store from "@/store";

const FEATURE_FLAGS: Record<UserChallenges, string> = {
  [UserChallenges.SetOrg]: "USER-713-set-org-challenge",
  [UserChallenges.ConfirmPhone]: "USER-740-confirm-phone-challenge",
  [UserChallenges.ResetPassword]: "USER-762-reset-password-challenge",
};

export const challengeOn = (
  name: UserChallenges
): ReturnType<typeof store.getters.featureOn> =>
  store.getters.featureOn(FEATURE_FLAGS[name]);
