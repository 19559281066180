import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "text-sm text-gray-800 py-3"
}
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "font-semibold" }
const _hoisted_4 = {
  key: 2,
  class: "py-3 text-center"
}
const _hoisted_5 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputField = _resolveComponent("InputField")!
  const _component_Loading = _resolveComponent("Loading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _createVNode(_component_InputField, {
        class: "inline-block w-full",
        icon: "search-solid",
        "icon-position": "left",
        placeholder: "Search orgs by name...",
        modelValue: _ctx.search,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event))
      }, null, 8, ["modelValue"]),
      (_ctx.$apollo.queries.orgs.loading)
        ? (_openBlock(), _createBlock(_component_Loading, {
            key: 0,
            class: "w-10 h-10 mx-auto my-10"
          }))
        : _createCommentVNode("", true),
      (!_ctx.$apollo.queries.orgs.loading && _ctx.orgs?.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.orgs, (org) => {
              return (_openBlock(), _createElementBlock("div", {
                key: org.id,
                class: "cursor-pointer px-4 py-2 hover:bg-gray-200",
                onClick: ($event: any) => (_ctx.$emit('select', org))
              }, [
                _createElementVNode("div", _hoisted_3, _toDisplayString(org.name), 1),
                _createElementVNode("div", null, _toDisplayString(_ctx.orgAddress(org)), 1)
              ], 8, _hoisted_2))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      (!_ctx.$apollo.queries.orgs.loading && _ctx.orgs?.length === 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, " Not found. "))
        : _createCommentVNode("", true)
    ]),
    (_ctx.canAddOrg)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5))
      : _createCommentVNode("", true)
  ], 64))
}