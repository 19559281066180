<template>
  <Dialog
    ref="selfIdentifyDialog"
    :is-back="isBack"
    :is-modal="false"
    @close="onClose"
    @back="onBack"
  >
    <section
      v-show="state.is('NORMAL') || state.is('SUBMITTING')"
      class="h-full md:h-96"
    >
      <div class="text-center">
        <h1 class="text-xl font-semibold">Welcome Back!</h1>
        <h2 class="font-medium">Tell Us About Yourself</h2>
        <p class="mt-4">
          We’re collecting additional information to offer you the best
          experience while using Snap! Mobile
        </p>
      </div>

      <form autocomplete="off" @submit.prevent="">
        <RolesSelect
          v-model="roleId"
          label="Your Role"
          :validation="v$.roleId"
          class="mb-4"
        ></RolesSelect>

        <div>
          <div class="text-sm font-medium text-gray-800 mb-1">
            Your Organization
          </div>

          <snap-link
            v-if="!org"
            @click="state.set('ORGS_SEARCH')"
            size="sm"
            icon="chevron-right-solid"
            icon-position="right"
            >Search For My Organization
          </snap-link>

          <div class="text-sm text-gray-800" v-if="org">
            <div class="font-semibold">{{ org.name }}</div>
            <div>{{ orgAddress(org) }}</div>
          </div>

          <snap-link
            v-if="org"
            @click="state.set('ORGS_SEARCH')"
            size="sm"
            class="mt-3"
            >Oops, this is not my organization.
          </snap-link>
        </div>
      </form>
    </section>

    <section v-show="state.is('ORGS_SEARCH')" class="h-full md:h-96">
      <OrgsSearchList @select="onOrgSelect"></OrgsSearchList>
    </section>

    <!--    <template v-slot:actions="{ close }">
      <snap-button
        button-type="button"
        size="sm"
        variant="primary"
        @click="close"
        full-width
        >Cancel
      </snap-button>
    </template>-->
  </Dialog>
</template>

<script lang="ts">
import { Options } from "vue-class-component";
import { mapGetters } from "vuex";

import { Form, Model } from "@/core/Form";

import Dialog from "@/components/ui/Dialog.vue";
import OrgsSearchList from "@/components/internal/common/OrgsSearchList.vue";
import RolesSelect from "@/components/internal/common/RolesSelect.vue";

import { Org, Product, Scalars, User, UserChallenge } from "@/types/graphql";
import { RequiredRule } from "@/util/validator";
import { useFiniteStateMachine } from "@/core/FiniteStateMachine";
import { orgAddress } from "@/util/orgs";
import { challengeOn } from "@/util/challenges";
import { UserChallenges } from "@/types/challenges";

const SelfIdentifyDialogState = [
  "NORMAL",
  "SUBMITTING",
  "ORGS_SEARCH",
  "ORGS_ADDING",
];

const CURRENT_CHALLENGE_NAME = UserChallenges.SetOrg;

@Options({
  computed: {
    ...mapGetters(["me", "nextChallenge"]),
  },
  components: { OrgsSearchList, Dialog, RolesSelect },
})
export default class SelfIdentifyDialog extends Form {
  me?: User;
  nextChallenge?: UserChallenge;

  @Model({ required: RequiredRule("Organization is required.") })
  org: Org | null = null;

  @Model({ required: RequiredRule("Product is required.") })
  product: Product | null = null;

  @Model({ required: RequiredRule("Role is required.") })
  roleId: Scalars["ID"]["input"] = "";

  state = useFiniteStateMachine<(typeof SelfIdentifyDialogState)[number]>(
    SelfIdentifyDialogState
  );

  orgAddress = orgAddress;

  async beforeMount() {
    await this.$store.dispatch("useTreatments");
  }

  async mounted() {
    if (
      challengeOn(CURRENT_CHALLENGE_NAME) &&
      this.nextChallenge?.name === CURRENT_CHALLENGE_NAME
    ) {
      (this.$refs.selfIdentifyDialog as Dialog).open(false);
    }
  }

  get isBack() {
    return this.state.is("ORGS_SEARCH") || this.state.is("ORGS_ADDING");
  }

  onBack() {
    if (this.state.is("ORGS_SEARCH")) {
      this.state.set("NORMAL");
    }
  }

  async onClose() {
    await this.$store.dispatch("completeChallenge", CURRENT_CHALLENGE_NAME);
  }

  onOrgSelect(org: Org) {
    this.state.set("NORMAL");
    this.org = org;
  }
}
</script>
