import { NavigationGuard } from "vue-router";

import store from "@/store";

export const UiGuard: NavigationGuard = async (to, from, next) => {
  if (to.meta?.title) document.title = `Snap! Mobile • ${to.meta?.title}`;

  store.commit("status", "ready");

  return next();
};
