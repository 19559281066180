<template>
  <div class="login-form">
    <FormError
      :error="error"
      class="mb-6 absolute top-8 inset-x-8 z-20"
      v-if="!featureOn?.('USER-888-new-sign-up-view')"
    ></FormError>

    <FormErrorInline
      :error="error"
      class="mb-4"
      v-if="featureOn?.('USER-888-new-sign-up-view')"
    ></FormErrorInline>

    <form @submit.prevent="login()" v-keyboard-submit>
      <InputField
        class="mb-4"
        label="Email"
        type="email"
        v-model="email"
        :validation="v$.email"
        @input="checkAuthFlow"
        v-autofocus
        data-testid="email"
      ></InputField>

      <InputField
        class="mb-4"
        label="Password"
        type="password"
        v-model="password"
        :validation="v$.password"
        v-show="flow.is('FLOW_PASSWORD')"
        data-testid="password"
      >
        <snap-link
          slot="code"
          @click="$emit('reset-password')"
          size="sm"
          data-testid="forgot_password"
          >Forgot Password?
        </snap-link>
      </InputField>

      <div class="text-right">
        <snap-button
          button-type="submit"
          shape="circular"
          size="lg"
          variant="primary"
          :disabled="state$.is('SUBMITTING')"
          data-testid="sign_in"
          >Sign In
        </snap-button>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import { watch } from "vue";
import { Options } from "vue-class-component";
import { mapGetters } from "vuex";

import type { User } from "@/types/graphql";

import { Form, Model, Submit } from "@/core/Form";

import Alert from "@/components/snap-ui/Alert.vue";
import FormError from "@/components/ui/FormError.vue";
import FormErrorInline from "@/components/ui/FormErrorInline.vue";
import InputField from "@/components/snap-ui/InputField.vue";

import { useFiniteStateMachine } from "@/core/FiniteStateMachine";
import { authenticationFlow } from "@/util/provider";
import { RequiredRule, EmailRule } from "@/util/validator";
import { getConsumerOnLogin } from "@/util/consumer";
import { FeatureToggle } from "@/store/treatments.module";

import { isURL } from "@/util/helper";
import * as FullStory from "@/util/fullStory";

@Options({
  props: {
    prefill: Object,
  },
  components: { FormError, FormErrorInline, InputField, Alert },

  emits: ["reset-password"],

  computed: {
    ...mapGetters(["consumer", "me", "featureOn", "skipConsumerPassing"]),
  },
})
export default class LoginForm extends Form {
  consumer?: string;
  me?: User;
  featureOn?: FeatureToggle;
  skipConsumerPassing?: boolean;
  flow = useFiniteStateMachine<string>(["FLOW_PASSWORD", "FLOW_REDIRECT"]);
  prefill: { email?: string } = {};

  @Model({
    required: RequiredRule("Please enter a valid email."),
    email: EmailRule("Please enter a valid email."),
  })
  email = "";
  redirectUrl = "";

  @Model({ required: RequiredRule("Please enter a password.") }) password = "";

  async beforeMount() {
    await this.$store.dispatch("useTreatments");

    watch(
      () => this.prefill,
      (update) => {
        if (update?.email && this.email != update?.email) {
          this.email = update.email;
          this.checkAuthFlow();
        }
      },
      { immediate: true }
    );
  }

  @Submit()
  async login(): Promise<void> {
    // FLOW_PASSWORD
    if (
      this.flow.is("FLOW_PASSWORD") &&
      (await this.isPartialValid(["email", "password"]))
    ) {
      await this.$store.dispatch("login", {
        email: this.email,
        password: this.password,

        ...(this.skipConsumerPassing ? {} : { consumer: this.consumer }),
      });

      FullStory.event("Successful Login", { user_email: this.email });

      await this.$store.dispatch("setConsumeData", await getConsumerOnLogin());

      if (this.$route.query.to) {
        await this.$router.push(this.$route.query.to as string);
      } else {
        await this.$router.push({ name: "home" });
      }
    }

    // FLOW_REDIRECT
    if (
      this.flow.is("FLOW_REDIRECT") &&
      (await this.isPartialValid(["email"])) &&
      this.redirectUrl
    ) {
      const url = new URL(this.redirectUrl);

      if (this.$store.getters.redirectTo)
        url.searchParams.append(
          "redirectTo",
          btoa(this.$store.getters.redirectTo)
        );

      this.$store.commit("status", "redirecting");

      location.href = url.toString();
    }
  }

  async checkAuthFlow(): Promise<void> {
    if (this.email !== "") {
      const flowResponse = authenticationFlow(this.email);

      if (isURL(flowResponse.redirectUrl)) {
        this.flow.set("FLOW_REDIRECT");
        this.redirectUrl = flowResponse.redirectUrl as string;
      } else if (flowResponse.name === "password") {
        this.flow.set("FLOW_PASSWORD");
      }
    } else {
      this.flow.set("FLOW_PASSWORD");
    }
  }

  reset(): void {
    this.flow.set("FLOW_PASSWORD");

    this.email = "";
    this.password = "";

    this.resetValidation(["email", "password"]);
  }
}
</script>
