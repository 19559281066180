<template>
  <snap-input
    class="block bg-transparent"
    v-bind="attrs"
    :value="modelValue"
    @input.prevent="onInput($event)"
    @snap-input-focus="$emit('focus', $event)"
    @snap-input-blur="$emit('blur', $event)"
  >
    <slot />
  </snap-input>
</template>

<script lang="ts">
import { PropType } from "vue";
import { Options } from "vue-class-component";

import { Validation } from "@vuelidate/core";
import Field from "@/core/Field";

type AdditionalInputAttrs = {
  _type?: string;
  autocomplete?: string;
  error?: boolean;
  errorDescription?: string;
  helpText?: string;
  icon?: string;
  iconPosition?: string;
  mask?: string;
  required?: boolean;
  showPasswordBtn?: string;
  success?: string;
  testId?: string;
};

@Options<InputField>({
  props: {
    validation: Object as PropType<Validation>,
    helpText: String,
    dataTestid: String,
  },
})
export default class InputField extends Field {
  validation?: Validation;
  helpText?: string;
  dataTestid?: string;

  get attrs() {
    const attrs: AdditionalInputAttrs = {
      _type: this.$attrs.type ? (this.$attrs.type as string) : "text",
      autocomplete: this.$attrs.autocomplete
        ? (this.$attrs.autocomplete as string)
        : "off",
    };

    if (this.validation?.$invalid) {
      attrs.icon = "exclamation-circle-solid";
      attrs.error = true;
      attrs.errorDescription = this.validation?.$silentErrors[0]
        ?.$message as string;
      attrs.helpText = this.validation?.$silentErrors[0]?.$message as string;
    } else {
      attrs.error = false;
      attrs.helpText = this.helpText;
    }

    if (attrs["_type"] === "password") {
      attrs.showPasswordBtn = "true";
    }

    if (this.dataTestid) {
      attrs.testId = this.dataTestid;
    }

    if (this.$attrs.mask) {
      attrs.mask = this.$attrs.mask as string;
    }

    if (this.$attrs.required) {
      attrs.required = !!this.$attrs.required;
    }

    return attrs;
  }
}
</script>
