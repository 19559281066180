<template>
  <snap-auth-input
    v-bind="attrs"
    :value="modelValue"
    @snap-auth-input-update="onInput"
  >
    <slot />
  </snap-auth-input>
</template>

<script lang="ts">
import { Options } from "vue-class-component";
import { Validation } from "@vuelidate/core";

import Field from "@/core/Field";

type AdditionalInputAttrs = {
  label?: string;
  labelCorner?: string;
  labelHelperText?: string;
  error?: string;
};

@Options<InputCodeField>({
  props: ["modelValue", "validation", "label", "labelCorner", "helpText"],
  emits: ["update:modelValue"],
})
export default class InputCodeField extends Field {
  label?: string;
  validation?: Validation;
  labelCorner?: string;
  helpText?: string;

  onInput(event: CustomEvent): void {
    this.validation?.$reset();
    this.$emit("update:modelValue", event.detail);
  }

  get attrs(): AdditionalInputAttrs {
    const attrs: AdditionalInputAttrs = {};

    attrs["label"] = this.label || "";
    attrs["labelCorner"] = this.labelCorner || "";
    attrs["labelHelperText"] = this.helpText || "";

    if (this.validation?.$invalid) {
      attrs["error"] = "true";
      attrs["labelHelperText"] = this.validation?.$silentErrors[0]
        ?.$message as string;
    }

    return attrs;
  }
}
</script>
