import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OrgsSelect = _resolveComponent("OrgsSelect")!
  const _component_RolesSelect = _resolveComponent("RolesSelect")!
  const _component_Select = _resolveComponent("Select")!

  return (_openBlock(), _createElementBlock("form", {
    autocomplete: "off",
    onSubmit: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.create()), ["prevent"]))
  }, [
    _createVNode(_component_OrgsSelect, {
      modelValue: _ctx.orgId,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.orgId) = $event)),
      label: "Organization",
      validation: _ctx.v$.orgId,
      class: "mb-4"
    }, null, 8, ["modelValue", "validation"]),
    _createVNode(_component_RolesSelect, {
      modelValue: _ctx.roleId,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.roleId) = $event)),
      label: "Role",
      validation: _ctx.v$.roleId,
      class: "mb-4"
    }, null, 8, ["modelValue", "validation"]),
    _createVNode(_component_Select, {
      modelValue: _ctx.product,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.product) = $event)),
      label: "Product",
      options: _ctx.productOptions,
      placeholder: "Select Product",
      modalTitle: "Select Product",
      validation: _ctx.v$.product
    }, null, 8, ["modelValue", "options", "validation"])
  ], 32))
}