<template>
  <snap-select-menu
    v-bind="attrs"
    :selectMenuOptions.prop="selectMenuOptions"
    @snap-select-menu-updated="onSelectMenuUpdated"
  ></snap-select-menu>
</template>

<script lang="ts">
import { PropType } from "vue";
import { Options } from "vue-class-component";
import { Validation } from "@vuelidate/core";

import { SelectOptions } from "@/types/snap-ui";

import Field from "@/core/Field";

type AdditionalSelectAttrs = {
  error?: boolean;
  errorDescription?: string;
  helpText?: string;
  multi?: boolean;
};

const isEqual = (a: unknown, b: unknown) => {
  return JSON.stringify(a) === JSON.stringify(b);
};

const isSelected = (
  modelValue: unknown | Array<unknown>,
  value: unknown
): boolean => {
  return Array.isArray(modelValue)
    ? modelValue.find((v) => isEqual(v, value))
    : isEqual(modelValue, value);
};

@Options({
  props: {
    modelValue: [String, Number, Object, Array],
    validation: Object as PropType<Validation>,
    options: Array,
  },
})
export default class Select extends Field {
  validation?: Validation;
  options?: SelectOptions;

  get attrs() {
    const attrs: AdditionalSelectAttrs = {};

    if (this.validation?.$invalid) {
      attrs.error = true;
      attrs.errorDescription = this.validation?.$silentErrors[0]
        ?.$message as string;
    } else {
      attrs.error = false;
    }

    attrs.helpText = this.$attrs.helpText as string | undefined;
    attrs.multi = !!Array.isArray(this.modelValue);

    return attrs;
  }

  get selectMenuOptions(): SelectOptions {
    return (
      this.options?.map((option) => ({
        ...option,
        selected: isSelected(this.modelValue, option.value),
      })) || []
    );
  }

  onSelectMenuUpdated(event: CustomEvent<SelectOptions>) {
    let value: undefined | unknown | Array<unknown>;
    const updated = event.detail;

    if (Array.isArray(this.modelValue)) {
      value = updated
        .filter((option) => !!option.selected)
        .map((option) => option.value);
    } else {
      const selected = updated.find((option) => !!option.selected);
      value = selected?.value;
    }

    this.$emit("update:modelValue", value);
  }
}
</script>
