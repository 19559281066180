import { RouteRecordRaw } from "vue-router";

import Login from "@/views/Login.vue";
import ExternalPage from "@/layouts/ExternalPage.vue";

export const ExternalRoutes: Array<RouteRecordRaw> = [
  {
    path: "/login",
    name: "login",
    component: Login,

    meta: {
      title: "Sign in",
      requiresAuth: false,
      layout: ExternalPage,
    },
  },
  {
    path: "/signup",
    name: "signup",

    component: () =>
      import(/* webpackChunkName: "external" */ "@/views/Registration.vue"),

    meta: {
      title: "Sign Up",
      requiresAuth: false,
      layout: ExternalPage,
    },
  },
  {
    path: "/verify",
    name: "verify",

    component: () =>
      import(/* webpackChunkName: "external" */ "@/views/Verify.vue"),

    meta: {
      title: "Confirm your account",
      requiresAuth: false,
      layout: ExternalPage,
    },
  },
  {
    path: "/reset-password",
    name: "reset-password",

    component: () =>
      import(/* webpackChunkName: "external" */ "@/views/ResetPassword.vue"),

    meta: {
      title: "Reset your password",
      requiresAuth: false,
      layout: ExternalPage,
    },
  },
];
