import { apolloClient } from "@/providers/apollo.provider";

import type {
  ApiKey,
  Auth,
  AuthChallenge,
  MutationConfirmPasswordArgs,
  MutationUserApiKeyCreateArgs,
  MutationUserApiKeyUpdateArgs,
  MutationUserChildUpdateArgs,
  MutationUserCreateArgs,
  MutationUserResetPasswordArgs,
  MutationUserSignupArgs,
  MutationUserSignupConfirmArgs,
  MutationUserUpdateArgs,
  PhoneNumberChallengeConfirmation,
  SignUpResponseWithAvailableTransport,
  TransportEnum,
  TransportType,
  User,
  UserChallenge,
  UserChallengeStatus,
  UserResetPasswordByAdminResponse,
  UserResetPasswordResponseWithAvailableTransport,
  UserWithPermissions,
} from "@/types/graphql";

import ConfirmPasswordMutation from "@/graphql/confirmPassword.mutation.graphql";

import UserApiKeyCreateMutation from "@/graphql/userApiKeyCreate.mutation.graphql";
import UserApiKeyDeleteMutation from "@/graphql/userApiKeyDelete.mutation.graphql";
import UserApiKeyUpdateMutation from "@/graphql/userApiKeyUpdate.mutation.graphql";
import UserChallengeUpdateMutation from "@/graphql/userChallengeUpdate.mutation.graphql";
import UserChildCreateMutation from "@/graphql/userChildCreate.mutation.graphql";
import UserChildUnassignMutation from "@/graphql/userChildUnassign.mutation.graphql";
import UserChildUpdateMutation from "@/graphql/userChildUpdate.mutation.graphql";
import UserCreateMutation from "@/graphql/userCreate.mutation.graphql";
import UserEmailConfirmMutation from "@/graphql/userEmailConfirm.mutation.graphql";
import UserImpersonateLogoutMutation from "@/graphql/userImpersonateLogout.mutation.graphql";
import UserImpersonateMutation from "@/graphql/userImpersonate.mutation.graphql";
import UserPhoneNumberChallengeConfirm from "@/graphql/userPhoneNumberChallengeConfirm.mutation.graphql";
import UserPhoneNumberConfirmMutation from "@/graphql/userPhoneNumberConfirm.mutation.graphql";
import UserQuery from "@/graphql/user.query.graphql";
import UserRefreshSessionMutation from "@/graphql/userRefreshSession.mutation.graphql";
import UserResetPasswordByAdminMutation from "@/graphql/userResetPasswordByAdmin.mutation.graphql";
import UserResetPasswordMutation from "@/graphql/userResetPassword.mutation.graphql";
import UserSignupConfirmMutation from "@/graphql/userSignupConfirm.mutation.graphql";
import UserSignupConfirmationResendMutation from "@/graphql/userSignupConfirmationResend.mutation.graphql";
import UserSignupMutation from "@/graphql/userSignup.mutation.graphql";
import UserUpdateMutation from "@/graphql/userUpdate.mutation.graphql";
import UserUpdatePasswordMutation from "@/graphql/userUpdatePassword.mutation.graphql";

import MeQuery from "@/graphql/me.query.graphql";
import LoginMutation from "@/graphql/login.mutation.graphql";
import LogoutMutation from "@/graphql/logout.mutation.graphql";

export const login = async (
  email: string,
  password: string,
  consumer: string
): Promise<AuthChallenge> => {
  return apolloClient
    .mutate({
      mutation: LoginMutation,
      variables: { email, password, consumer },
      // awaitRefetchQueries: true,
      // refetchQueries: [{ query: MeQuery }],
    })
    .then(({ data }) => data.login);
};

export const logout = async (): Promise<boolean> => {
  return apolloClient
    .mutate({ mutation: LogoutMutation })
    .then(({ data }) => data.logoutUser)
    .finally(() => {
      apolloClient.clearStore();
    });
};

export const userRefreshSession = async (): Promise<Auth> => {
  return apolloClient
    .mutate({
      mutation: UserRefreshSessionMutation,
    })
    .then(({ data }) => data.userRefreshSession);
};

export const me = async (): Promise<UserWithPermissions> => {
  return apolloClient
    .query({ query: MeQuery, errorPolicy: "all", fetchPolicy: "network-only" })
    .then(({ data }) => data.me || {})
    .catch((e) => console.error(e.message));
};

export const userRefresh = async (): Promise<UserWithPermissions> => {
  return apolloClient
    .query({ query: MeQuery, errorPolicy: "all", fetchPolicy: "network-only" })
    .then(({ data }) => data.me || {})
    .catch((e) => console.error(e.message));
};
export const userCreate = async (
  user: MutationUserCreateArgs
): Promise<User> => {
  return apolloClient
    .mutate({
      mutation: UserCreateMutation,
      variables: user,
      // awaitRefetchQueries: true,
      // refetchQueries: [{ query: UserQuery, variables: { email: user.email } }],
    })
    .then(({ data }) => data.userCreate);
};

export const userSignup = async (
  user: MutationUserSignupArgs
): Promise<SignUpResponseWithAvailableTransport> => {
  return apolloClient
    .mutate({
      mutation: UserSignupMutation,
      variables: user,
    })
    .then(({ data }) => data.userSignup);
};

export const userSignupConfirmationResend = async (
  email: string,
  transport?: TransportEnum
): Promise<Array<TransportType>> => {
  return apolloClient
    .mutate({
      mutation: UserSignupConfirmationResendMutation,
      variables: { email, transport },
    })
    .then(({ data }) => data.userSignupConfirmationResend);
};

export const userUpdatePassword = async (
  email: string,
  oldPassword: string,
  newPassword: string
): Promise<string | void> => {
  return apolloClient
    .mutate({
      mutation: UserUpdatePasswordMutation,
      variables: { email, oldPassword, newPassword },
    })
    .then(({ data }) => {
      data.userUpdatePassword.id;
    });
};

export const userResetPassword = async ({
  email,
  id,
  transport,
  withLink = false,
}: MutationUserResetPasswordArgs): Promise<UserResetPasswordResponseWithAvailableTransport> => {
  return apolloClient
    .mutate({
      mutation: UserResetPasswordMutation,
      variables: { email, id, transport, withLink },
    })
    .then(({ data }) => data.userResetPassword);
};

export const confirmPassword = async ({
  email,
  verificationCode,
  newPassword,
}: MutationConfirmPasswordArgs): Promise<string> => {
  return apolloClient
    .mutate({
      mutation: ConfirmPasswordMutation,
      variables: { email, verificationCode, newPassword },
    })
    .then(({ data }) => data.confirmPassword);
};

export const userEmailConfirm = async (code: string): Promise<void> => {
  return apolloClient
    .mutate({ mutation: UserEmailConfirmMutation, variables: { code } })
    .then(({ data }) => data.userEmailConfirm);
};

export const userPhoneNumberConfirm = async (code: string): Promise<void> => {
  return apolloClient
    .mutate({ mutation: UserPhoneNumberConfirmMutation, variables: { code } })
    .then(({ data }) => data.userPhoneNumberConfirm);
};

export const userSignupConfirm = async (
  variables: MutationUserSignupConfirmArgs
): Promise<Auth> => {
  return apolloClient
    .mutate({
      mutation: UserSignupConfirmMutation,
      variables,
      awaitRefetchQueries: true,
      refetchQueries: [{ query: MeQuery }],
    })
    .then(({ data }) => data.userSignupConfirm);
};

export const userUpdate = async (
  user: MutationUserUpdateArgs
): Promise<User> => {
  return apolloClient
    .mutate({
      mutation: UserUpdateMutation,
      variables: user,
      // waitRefetchQueries: true,
      // refetchQueries: [{ query: UserQuery, variables: { id: user.id } }],
    })
    .then(({ data }) => {
      return data.userUpdate;
    });
};

export const user = async (id?: string, email?: string): Promise<User> => {
  return apolloClient
    .query({ query: UserQuery, variables: { id, email } })
    .then(({ data }) => data.user);
};

export const userImpersonate = async (userId: string): Promise<Auth> => {
  return apolloClient
    .mutate({
      mutation: UserImpersonateMutation,
      variables: { userId },
      awaitRefetchQueries: true,
      refetchQueries: [{ query: MeQuery }],
    })
    .then(({ data }) => data.userImpersonate);
};

export const userImpersonateLogout = async (): Promise<Auth> => {
  return apolloClient
    .mutate({
      mutation: UserImpersonateLogoutMutation,
      awaitRefetchQueries: true,
      refetchQueries: [{ query: MeQuery }],
    })
    .then(({ data }) => data.userImpersonateLogout);
};

export const userChallengeChange = async (
  id: string,
  status: UserChallengeStatus
): Promise<UserChallenge> => {
  return apolloClient
    .mutate({
      mutation: UserChallengeUpdateMutation,
      variables: { id, status },
    })
    .then(({ data }) => data.userChallengeUpdate);
};

export const userPhoneNumberChallengeConfirm = async (
  challengeId: string,
  code: string
): Promise<PhoneNumberChallengeConfirmation> => {
  return apolloClient
    .mutate({
      mutation: UserPhoneNumberChallengeConfirm,
      variables: { challengeId, code },
    })
    .then(({ data }) => data.userPhoneNumberChallengeConfirm);
};

export const userResetPasswordByAdmin = async (
  id: string,
  password: string,
  forcePasswordResetChallenge = true
): Promise<UserResetPasswordByAdminResponse> => {
  return apolloClient
    .mutate({
      mutation: UserResetPasswordByAdminMutation,
      variables: { userId: id, password, forcePasswordResetChallenge },
    })
    .then(({ data }) => data.userPasswordSetByAdmin);
};

export const userApiKeyCreate = async (
  variables: MutationUserApiKeyCreateArgs
): Promise<ApiKey> => {
  return apolloClient
    .mutate({ mutation: UserApiKeyCreateMutation, variables })
    .then(({ data }) => data.userApiKeyCreate);
};

export const userApiKeyUpdate = async (
  variables: MutationUserApiKeyUpdateArgs
): Promise<ApiKey> => {
  return apolloClient
    .mutate({ mutation: UserApiKeyUpdateMutation, variables })
    .then(({ data }) => data.userApiKeyUpdate);
};

export const userApiKeyDelete = async (id: string): Promise<void> => {
  await apolloClient.mutate({
    mutation: UserApiKeyDeleteMutation,
    variables: { id },
  });
};

export const userChildCreate = async (
  firstName: string,
  lastName: string
): Promise<User> => {
  return apolloClient
    .mutate({
      mutation: UserChildCreateMutation,
      variables: { firstName, lastName },
    })
    .then(({ data }) => data.userChildCreate);
};

export const userChildUpdate = async (
  id: string,
  update: Omit<MutationUserChildUpdateArgs, "id">
): Promise<User> => {
  return apolloClient
    .mutate({
      mutation: UserChildUpdateMutation,
      variables: { id, ...update },
    })
    .then(({ data }) => data.userChildUpdate);
};

export const userChildUnassign = async (id: string): Promise<void> => {
  await apolloClient.mutate({
    mutation: UserChildUnassignMutation,
    variables: { id },
  });
};
