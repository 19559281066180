export function getCookie(name: string): string | undefined {
  const cookies = document.cookie.split(";").reduce(
    (acc, cookieString) => {
      const [key, value] = cookieString.split("=").map((s) => s.trim());
      if (key && value) {
        acc[key] = decodeURIComponent(value);
      }
      return acc;
    },
    {} as Record<string, string>
  );
  return name ? cookies[name] || "" : undefined;
}

// supports any cookie option like path, expires, maxAge and domain.
// [MDN Cookie Reference](https://developer.mozilla.org/en-US/docs/Web/API/Document/cookie)
export function setCookie(
  name: string,
  value: string,
  options: Record<string, string> = {}
): void {
  document.cookie = `${name}=${encodeURIComponent(value)}${Object.keys(
    options
  ).reduce((acc, key) => {
    return `${acc};${key.replace(/([A-Z])/g, ($1) => `-${$1.toLowerCase()}`)}=${
      options[key]
    }`;
  }, "")}`;
}

export function deleteCookie(name: string): void {
  document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
}
