<template>
  <form autocomplete="off" @submit.prevent="create()">
    <OrgsSelect
      v-model="orgId"
      label="Organization"
      :validation="v$.orgId"
      class="mb-4"
    ></OrgsSelect>

    <RolesSelect
      v-model="roleId"
      label="Role"
      :validation="v$.roleId"
      class="mb-4"
    ></RolesSelect>

    <Select
      v-model="product"
      label="Product"
      :options="productOptions"
      placeholder="Select Product"
      modalTitle="Select Product"
      :validation="v$.product"
    ></Select>
  </form>
</template>

<script lang="ts">
import { Options } from "vue-class-component";

import OrgsSelect from "@/components/internal/common/OrgsSelect.vue";
import RolesSelect from "@/components/internal/common/RolesSelect.vue";
import Select from "@/components/snap-ui/Select.vue";

import { Form, FormSubmitError, Model, Submit } from "@/core/Form";
import { Consumer, Product, Role, Scalars } from "@/types/graphql";
import { SelectOptions } from "@/types/snap-ui";
import { getRolesList } from "@/services/roles.service";
import { consumersList } from "@/services/consumers.service";
import { RequiredRule } from "@/util/validator";
import { userAssociationCreate } from "@/services/orgs.service";
import { $notify } from "@/services/notification";
import { roleTitle } from "@/util/roles";

@Options({
  props: {
    userId: String,
  },
  components: { Select, RolesSelect, OrgsSelect },
  watch: {
    error(value: FormSubmitError) {
      if (value)
        $notify({
          title: value.message,
          description: value.description,
          type: "danger",
        });
    },
  },
})
export default class UserAssociationCreateForm extends Form {
  userId!: string;

  @Model({ required: RequiredRule("Organization is required.") })
  orgId: Scalars["ID"]["input"] = "";

  @Model({ required: RequiredRule("Product is required.") })
  product: Product | null = null;

  @Model({ required: RequiredRule("Role is required.") })
  roleId: Scalars["ID"]["input"] = "";

  roles: Array<Role> = [];
  consumers: Array<Consumer> = [];

  get productOptions(): SelectOptions {
    return this.consumers
      .map((consumer) => ({ ...consumer, id: consumer.id.toUpperCase() }))
      .filter((consumer) =>
        Object.values(Product).includes(consumer.id as Product)
      )
      .map((consumer) => ({ value: consumer.id, name: consumer.title }));
  }

  get roleOptions(): SelectOptions {
    return this.roles.map((role) => ({
      name: roleTitle(role),
      value: role.id as string,
    }));
  }

  async beforeMount() {
    this.roles = [...(await getRolesList())].sort((a, b) =>
      (a?.title as string) > (b?.title as string) ? 1 : -1
    );

    this.consumers = [...(await consumersList())].filter((a) => a.inApps);
  }

  @Submit()
  async create(): Promise<void | boolean> {
    if (this.isFormValid()) {
      const response = await userAssociationCreate({
        userId: this.userId,
        orgId: this.orgId,
        product: this.product as Product,
        roleId: this.roleId,
      });

      if (!response.success && Array.isArray(response.errors)) {
        throw new Error(response.errors[0]?.message);
      }
    } else {
      return false;
    }
  }

  reset() {
    this.orgId = "";
    this.product = null;
    this.roleId = "";

    this.resetValidation(["orgId", "product", "roleId"]);
  }
}
</script>
