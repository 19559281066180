<template>
  <div>
    <div v-if="!inline">
      <div class="read-only__label">
        {{ label }}
        <span class="font-medium text-sm text-red-500" v-if="required">*</span>
      </div>
      <div class="text-gray-400" v-if="value">{{ value }}</div>
      <div class="text-gray-400" v-if="!value">
        <slot></slot>
      </div>
    </div>
    <div v-if="inline" class="text-sm py-1 flex gap-2">
      <span class="inline-block text-gray-500" :class="grid ? ['w-24'] : ['']">
        {{ label }}
      </span>
      <span class="inline-block font-medium flex-1" v-if="value">
        {{ value }}
      </span>
      <span class="inline-block font-medium flex-1" v-if="!value">
        <slot></slot>
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    label: [String, null],
    value: [String, null],
    inline: Boolean,
    grid: Boolean,
    required: Boolean,
  },
})
export default class InputReadOnly extends Vue {
  label?: string;
  value?: string;
  inline = false;
  grid = false;
  required = false;
}
</script>

<style scoped lang="scss">
.read-only__label {
  color: #334155;
  font-weight: 500;
  line-height: 1.25rem;
  margin-bottom: 0.25rem;
}
</style>
