import { ObjectDirective } from "vue";

const KeyboardSubmit: ObjectDirective = {
  mounted(el: HTMLElement) {
    const button = document.createElement("button");
    button.type = "submit";
    button.className = "hidden";
    el.appendChild(button);
  },
};

export default KeyboardSubmit;
