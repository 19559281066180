import { RouteRecordRaw } from "vue-router";

import ParticipantPage from "@/layouts/ParticipantPage.vue";

export const ParticipantsRoutes: Array<RouteRecordRaw> = [
  {
    path: "/participants/signup",
    name: "participants-signup",

    component: () =>
      import(
        /* webpackChunkName: "participants" */ "@/views/participants/ParticipantSignUp.vue"
      ),

    meta: {
      title: "Participant Sign Up",
      requiresAuth: false,
      layout: ParticipantPage,
    },
  },

  {
    path: "/participants/login",
    name: "participants-login",

    component: () =>
      import(
        /* webpackChunkName: "participants" */ "@/views/participants/ParticipantLogin.vue"
      ),

    meta: {
      title: "Participant Login",
      requiresAuth: false,
      layout: ParticipantPage,
    },
  },

  {
    path: "/participants/reset-password",
    name: "participants-reset-password",

    component: () =>
      import(
        /* webpackChunkName: "participants" */ "@/views/participants/ParticipantResetPassword.vue"
      ),

    meta: {
      title: "Participant Reset Password",
      requiresAuth: false,
      layout: ParticipantPage,
    },
  },

  {
    path: "/participants/verification",
    name: "participants-verification",

    component: () =>
      import(
        /* webpackChunkName: "participants" */ "@/views/participants/ParticipantVerifyCode.vue"
      ),

    meta: {
      title: "Participant Verification Code",
      requiresAuth: false,
      layout: ParticipantPage,
    },
  },
];
