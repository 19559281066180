import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["type"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createElementBlock("snap-alert", {
    type: _ctx.type,
    class: "mb-2",
    border: "true",
    close: "true",
    onSnapAlertClosed: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
  }, [
    _createElementVNode("snap-alert-title", null, [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _createElementVNode("snap-alert-description", null, [
      _renderSlot(_ctx.$slots, "description")
    ])
  ], 40, _hoisted_1)), [
    [_vShow, _ctx.isOpened]
  ])
}