<template>
  <!--
  <div
    :class="alertClasses"
    class="border-l-4 flex items-start px-4 py-3 mb-2"
    v-show="isOpened"
  >
    <snap-icon :icon="alertIconType" :class="alertIconClasses"></snap-icon>
    <p class="flex-1 text-xs my-1 px-3">
      <slot />
    </p>
    <snap-icon
      icon="x-line"
      :class="alertIconClasses"
      class="cursor-pointer"
      @click="close"
    ></snap-icon>
  </div>
-->

  <snap-alert
    :type="type"
    class="mb-2"
    border="true"
    close="true"
    v-show="isOpened"
    @snap-alert-closed="close"
  >
    <snap-alert-title>
      <slot />
    </snap-alert-title>
    <snap-alert-description>
      <slot name="description"></slot>
    </snap-alert-description>
  </snap-alert>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

type AlertType = "danger" | "warning" | "success" | "info";
//
// type AlertAttrs = {
//   borderClass: string;
//   textClass: string;
//   bgClass: string;
//   iconClass: string;
//   iconType: string;
// };
//
// const ALERT_TYPES: Record<AlertType, AlertAttrs> = {
//   danger: {
//     borderClass: "border-red-400",
//     textClass: "text-red-800",
//     bgClass: "bg-red-50",
//     iconClass: "text-red-400",
//     iconType: "x-circle-solid",
//   },
//   warning: {
//     borderClass: "border-yellow-400",
//     textClass: "text-yellow-800",
//     bgClass: "bg-yellow-50",
//     iconClass: "text-yellow-400",
//     iconType: "exclamation-solid",
//   },
//   success: {
//     borderClass: "border-green-400",
//     textClass: "text-green-800",
//     bgClass: "bg-green-50",
//     iconClass: "text-green-400",
//     iconType: "badge-check-solid",
//   },
//   info: {
//     borderClass: "border-blue-400",
//     textClass: "text-blue-800",
//     bgClass: "bg-blue-50",
//     iconClass: "text-blue-400",
//     iconType: "information-circle-solid",
//   },
// };

@Options({
  props: ["type"],
})
export default class Alert extends Vue {
  type: AlertType = "info";
  isOpened = true;

  close() {
    this.isOpened = false;
  }

  // get alertClasses(): Array<string> {
  //   const { borderClass, textClass, bgClass } = ALERT_TYPES[this.type];
  //   return [borderClass, textClass, bgClass];
  // }
  //
  // get alertIconClasses(): Array<string> {
  //   const { iconClass } = ALERT_TYPES[this.type];
  //   return [iconClass];
  // }
  //
  // get alertIconType(): string {
  //   return ALERT_TYPES[this.type].iconType;
  // }
}
</script>
