<template>
  <Select
    v-model="selected"
    :label="label"
    :options="options"
    :validation="validation"
    placeholder="Select Organization"
    modalTitle="Select Organization"
    :corner-hint="loading ? 'Loading...' : ''"
    search="true"
    search-method="contain"
    @update:modelValue="$emit('update:modelValue', $event)"
    @snap-select-menu-search-updated="onSearch($event)"
  ></Select>
</template>

<script lang="ts">
import { watch, PropType } from "vue";
import { Options, Vue } from "vue-class-component";

import { Validation } from "@vuelidate/core";

import OrgSearchQuery from "@/graphql/orgSearch.query.graphql";
import OrgQuery from "@/graphql/org.query.graphql";

import { SelectOptions } from "@/types/snap-ui";
import { Org, QueryOrgArgs, QueryOrgSearchArgs } from "@/types/graphql";
import { orgAddress } from "@/util/orgs";

import Select from "@/components/snap-ui/Select.vue";

@Options<OrgsSelect>({
  props: {
    label: String,
    modelValue: String,
    validation: Object as PropType<Validation>,
  },
  components: { Select },
  apollo: {
    orgSearch: {
      query: OrgSearchQuery,
      variables(): QueryOrgSearchArgs {
        return { name: this.search };
      },
      skip() {
        return !this.isSearchValid;
      },
      context: {
        debounceKey: "orgSearch",
        debounceTimeout: 1000,
      },
    },

    org: {
      query: OrgQuery,
      variables(): QueryOrgArgs {
        return { id: this.selected };
      },
      skip() {
        return !this.selected || this.selected === "";
      },
      context: {
        debounceKey: "org",
        debounceTimeout: 250,
      },
    },
  },
})
export default class OrgsSelect extends Vue {
  label?: string = "";
  modelValue = "";
  validation?: Validation | null = null;

  selected = "";
  search = "";

  orgSearch?: Array<Org>;
  org?: Array<Org>;

  async beforeMount() {
    this.selected = this.modelValue;

    watch<string>(
      () => this.modelValue,
      (value) => {
        if (value && this.selected !== value) {
          this.selected = value;
          this.$apollo.queries.org.refetch();
          this.search = "";
        }
      }
    );
  }

  get isSearchValid(): boolean {
    return this.search.length > 3;
  }

  get options(): SelectOptions {
    const options = this.isSearchValid ? this.orgSearch : this.org;
    return (options || []).map((org) => ({
      value: org.id,
      name: org.name as string,
      label: this.listItemName(org),
    }));
  }

  get loading(): boolean {
    return this.$apollo.queries.orgSearch.loading;
  }

  onSearch(event: CustomEvent) {
    this.search = event.detail;
  }

  listItemName(org: Org): string {
    const { name, type } = org;
    const address = orgAddress(org);
    return `<span style="font-weight: 600;">${name}</span> (${type})<br><span style="font-size: 14px;">${address}</span>`;
  }
}
</script>
