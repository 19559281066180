import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ae7fe7ba"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "read-only__label" }
const _hoisted_3 = {
  key: 0,
  class: "font-medium text-sm text-red-500"
}
const _hoisted_4 = {
  key: 0,
  class: "text-gray-400"
}
const _hoisted_5 = {
  key: 1,
  class: "text-gray-400"
}
const _hoisted_6 = {
  key: 1,
  class: "text-sm py-1 flex gap-2"
}
const _hoisted_7 = {
  key: 0,
  class: "inline-block font-medium flex-1"
}
const _hoisted_8 = {
  key: 1,
  class: "inline-block font-medium flex-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.inline)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
            (_ctx.required)
              ? (_openBlock(), _createElementBlock("span", _hoisted_3, "*"))
              : _createCommentVNode("", true)
          ]),
          (_ctx.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.value), 1))
            : _createCommentVNode("", true),
          (!_ctx.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _renderSlot(_ctx.$slots, "default", {}, undefined, true)
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.inline)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createElementVNode("span", {
            class: _normalizeClass(["inline-block text-gray-500", _ctx.grid ? ['w-24'] : ['']])
          }, _toDisplayString(_ctx.label), 3),
          (_ctx.value)
            ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.value), 1))
            : _createCommentVNode("", true),
          (!_ctx.value)
            ? (_openBlock(), _createElementBlock("span", _hoisted_8, [
                _renderSlot(_ctx.$slots, "default", {}, undefined, true)
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}