import { $on, $emit } from "@/services/event";
import { $id } from "@/util/helper";

export type NotificationType = "danger" | "warning" | "success" | "info";

export type NotificationPayload = {
  id?: string;
  title?: string;
  description?: string | Array<string>;
  type?: NotificationType;
  result?: boolean;
};

export const $alert = async (data: NotificationPayload): Promise<void> => {
  return new Promise((resolve) => {
    const id = $id();
    $emit("notification:alert.open", { ...data, id });
    $on("notification:alert.close", (payload: NotificationPayload) => {
      if (payload.id === id) resolve();
    });
  });
};

export const $notify = (data: NotificationPayload): void => {
  const id = $id();
  $emit("notification:alert.open", { ...data, id });
};

export const $confirm = async (data: NotificationPayload) => {
  return new Promise((resolve) => {
    const id = $id();
    $emit("notification:confirm.open", { ...data, id });
    $on("notification:confirm.close", (payload: NotificationPayload) => {
      if (payload.id === id) resolve(!!payload.result);
    });
  });
};
