<template>
  <div
    class="min-h-screen flex justify-center items-center participants-background"
    :style="{
      'background-color': backgroundColor,
    }"
  >
    <div
      class="absolute w-full md:w-auto content-position min-h-screen md:min-h-0 flex flex-col md:block md:mb-6"
    >
      <snap-logo
        class="logo-size"
        product="snap-logo-raise"
        :white="white"
        variant="1line"
      ></snap-logo>
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

import PublicFundraiserDataQuery from "@/graphql/publicFundraiserData.query.graphql";
import { PublicFundraiserData } from "@/types/graphql";
import { nearestColor } from "@/util/color";

const USE_WHITE_LOGO: Record<string, boolean> = {
  "#7A000A": true,
  "#8C4315": true,
  "#CC780A": false,
  "#948316": false,
  "#4A7806": true,
  "#00470C": true,
  "#00806C": true,
  "#005E7A": true,
  "#005EA6": true,
  "#000E54": true,
  "#40227A": true,
  "#550066": true,
  "#D41594": true,
  "#242526": true,

  "#BF2237": true,
  "#D98357": false,
  "#DEAC21": false,
  "#CFBB57": false,
  "#88BE37": false,
  "#3ABA53": false,
  "#2ECCAB": false,
  "#21C3D9": false,
  "#259EF5": false,
  "#000C94": true,
  "#6D36D9": true,
  "#9623AD": true,
  "#FA96E1": false,
  "#57595C": true,

  "#FC0015": false,
  "#FF7E2E": false,
  "#FFD500": false,
  "#FFEA30": false,
  "#A5FA25": false,
  "#18F571": false,
  "#31F7D0": false,
  "#7AE7F5": false,
  "#A6DEFF": false,
  "#0026D6": true,
  "#A173FF": false,
  "#D969FF": false,
  "#83858A": false,

  "#DE5100": false,
  "#FAF8E3": false,
  "#7E98E5": false,
  "#9BA6C2": false,
  "#D3D6DE": false,
  "#FFFFFF": false,
};

@Options({
  apollo: {
    publicFundraiserData: {
      query: PublicFundraiserDataQuery,
      variables() {
        const identifier = this.$route.query.join || "";
        return {
          identifier,
        };
      },

      skip() {
        return !this.$route.query.join;
      },
    },
  },
})
export default class ParticipantPage extends Vue {
  publicFundraiserData?: PublicFundraiserData;

  get backgroundColor(): string {
    return (this.publicFundraiserData?.primaryColor || "#1f76cd").toUpperCase();
  }

  get white(): boolean {
    const colors = Object.keys(USE_WHITE_LOGO).map((color) =>
      color.toUpperCase()
    );

    if (colors.includes(this.backgroundColor)) {
      // console.log(`Found color ${this.backgroundColor} in palette`);
      return USE_WHITE_LOGO[this.backgroundColor];
    }

    const nearest = nearestColor(this.backgroundColor, colors);
    if (nearest) {
      // console.log(`Nearest color to ${this.backgroundColor} is ${nearest}`);
      return USE_WHITE_LOGO[nearest];
    }

    return true;
  }
}
</script>

<style scoped lang="scss">
.logo-size {
  width: 180px;
  height: 25px;
  margin: 24.5px auto;

  @media (max-width: 768px) {
    margin: 16px auto;
  }
}

.content-position {
  @media (max-width: 768px) {
    top: 0;
  }
}

.participants-background::before {
  content: "";
  background-image: url(../assets/Duotone_Revised.svg);
  background-size: cover;
  opacity: 0.3;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
</style>
