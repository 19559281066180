import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Select = _resolveComponent("Select")!

  return (_openBlock(), _createBlock(_component_Select, {
    modelValue: _ctx.selected,
    "onUpdate:modelValue": [
      _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selected) = $event)),
      _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:modelValue', $event)))
    ],
    label: _ctx.label,
    options: _ctx.options,
    validation: _ctx.validation,
    placeholder: _ctx.multi ? 'Select Roles' : 'Select Role',
    modalTitle: _ctx.multi ? 'Select Roles' : 'Select Role',
    multi: _ctx.multi,
    search: "true",
    "search-method": "contain"
  }, null, 8, ["modelValue", "label", "options", "validation", "placeholder", "modalTitle", "multi"]))
}