import mitt /*, { Emitter }*/ from "mitt";

// type CustomEvent = {
//   foo?: string;
// };
// type MittEvents = CustomEvent;
//
// const emitter: Emitter<MittEvents> = mitt<MittEvents>();
const emitter = mitt();

export const $on = (type: string, handler: any) => emitter.on(type, handler);
export const $off = (type: string, handler: any) => emitter.off(type, handler);

export const $emit = (type: string, payload: any) =>
  emitter.emit(type, payload);
