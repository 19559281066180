import { Module, GetterTree, ActionTree, MutationTree } from "vuex";

import type { RootState } from "./index";
import { TransportEnum, TransportType } from "@/types/graphql";

export type ResetPasswordData = {
  attempts?: number;
  availableTransports?: Array<TransportEnum>;
  email?: string;
  notMigrated?: boolean;
  usedTransports?: Array<TransportType>;
};

const state = {};

const getters: GetterTree<ResetPasswordData, RootState> = {
  notMigrated: (state) => !!state.notMigrated,
  resetAvailableTransports: (state) => state.availableTransports,
  resetPasswordAttempts: (state) => state.attempts,
  resetPasswordEmail: (state) => state.email,
  resetUsedTransports: (state) => state.usedTransports,
};

const mutations: MutationTree<ResetPasswordData> = {
  updateResetPasswordState(state, payload: ResetPasswordData) {
    if (payload.email !== undefined) {
      state.email = payload.email;
    }

    if (Array.isArray(payload.availableTransports)) {
      state.availableTransports = [...payload.availableTransports];
    }

    if (Array.isArray(payload.usedTransports)) {
      state.usedTransports = [...payload.usedTransports];
    }

    if (payload.notMigrated !== undefined) {
      state.notMigrated = payload.notMigrated;
    } else {
      state.notMigrated = false;
    }

    if (state.attempts === undefined) {
      state.attempts = 1;
    } else {
      state.attempts = state.attempts + 1;
    }
  },

  clearResetPasswordEmail(state) {
    delete state.attempts;
    delete state.availableTransports;
    delete state.email;
    delete state.notMigrated;
    delete state.usedTransports;
  },
};

const actions: ActionTree<ResetPasswordData, RootState> = {};

export const reset: Module<ResetPasswordData, RootState> = {
  state,
  getters,
  mutations,
  actions,
};
