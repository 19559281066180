import { config } from "@/services/config";

export const clientUrl = (path: string): string => {
  const uri = path.replace(/^\/+/, "");
  return `${location.origin}/${uri}`;
};
export const serverApiUrl = (path: string): string => {
  const host = config.GRAPHQL_URI?.replace(/\/+$/, "");
  const uri = path.replace(/^\/+/, "");
  return `${host}/${uri}`;
};

export const subgraphUrl = (subgraph: string): string => {
  return String(config.GRAPHQL_URI).replace("api.", `${subgraph}.`);
};

export const appendSearchParams = (
  uri: string,
  query: Record<string, string>
): string => {
  const url = new URL(uri);

  Object.entries(query).forEach(([key, value]) => {
    url.searchParams.set(key, value);
  });

  return url.toString();
};
