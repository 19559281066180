import { SplitFactory } from "@splitsoftware/splitio";
import {
  ISDK,
  IBrowserClient,
  Attributes,
  TreatmentsWithConfig,
} from "@splitsoftware/splitio/types/splitio";

import { getCookie, setCookie } from "@/util/cookies";
import { $id } from "@/util/helper";
import { config } from "@/services/config";
import { TreatmentsDefaults } from "@/store/treatments.module";

const SPLITIO_KEY_COOKIE_NAME = "snap_splitio_key";

const getAnonymousKey = (): string => {
  let key: string | undefined = getCookie(SPLITIO_KEY_COOKIE_NAME);

  if (!key) {
    key = $id("", 3);

    const cookieDate = new Date();
    cookieDate.setFullYear(cookieDate.getFullYear() + 1);

    setCookie(SPLITIO_KEY_COOKIE_NAME, key, {
      path: "/",
      expires: cookieDate.toString(),
    });
  }

  return key as string;
};

let factory: ISDK | undefined;
let client: IBrowserClient | undefined;

export const getClient = (
  userId: string | undefined,
  attributes?: Attributes
): { client?: IBrowserClient; key: string } => {
  const key = userId ? userId : getAnonymousKey();

  if (!factory) {
    factory = SplitFactory({
      core: {
        authorizationKey: config.SPLIT_IO_API_KEY || "localhost",
        key,
        trafficType: userId ? "user" : "anonymous",
      },
      features: TreatmentsDefaults,
      debug: false, //!config.IS_PRODUCTION,
    });
  }

  if (factory && !client) {
    client = factory.client() as IBrowserClient;

    if (attributes) client.setAttributes(attributes);
  }

  return { client, key };
};

export const destroyClient = async (): Promise<void> => {
  if (client) {
    await client.destroy();
    client = undefined;
  }

  if (factory) {
    factory = undefined;
  }
};

export const fetchTreatments = async (
  keys: Array<string>,
  attributes?: Attributes
): Promise<TreatmentsWithConfig | undefined> => {
  const timeout = new Promise((resolve, reject) => setTimeout(reject, 5000));

  try {
    await Promise.race([client?.ready(), timeout]);
    return client?.getTreatmentsWithConfig(keys, attributes);
  } catch (err) {
    return undefined;
  }
};
