import { reactive } from "vue";

export type Modes = Record<string, string>;
export type ModesState = Record<string, boolean>;

export const mediaQueryListener = (modes: Modes) => {
  const observable = reactive<ModesState>(
    Object.fromEntries(Object.keys(modes).map((key) => [key, false]))
  );

  if (window.matchMedia) {
    for (const [name, query] of Object.entries(modes)) {
      const mql = window.matchMedia(String(query));

      observable[name] = mql.matches;

      mql.addListener((e) => {
        observable[name] = e.matches;
      });
    }
  }

  return observable;
};

export const mediaQueryState = mediaQueryListener({
  mobile: "(max-width: 768px)",
  desktop: "(min-width: 769px)",
});
