<template>
  <component
    :is="wrapper"
    v-show="isAuthorized"
    class="md:h-screen"
    @snap-impersonation-mode-changed="onImpersonationModeChange"
  >
    <snap-global-header :theme="theme" :impersonationMode="isImpersonated">
      <snap-logo
        product="snap-logo-mobile"
        white="true"
        class="cursor-pointer"
        @click.prevent="$router.push({ name: 'home' })"
      ></snap-logo>
      <snap-logo-header-end>
        <PageHeaderNav color="#fff"></PageHeaderNav>
      </snap-logo-header-end>
    </snap-global-header>

    <!-- INFO: remove class if need to scroll whole page -->
    <main class="md:h-full md:overflow-hidden">
      <slot></slot>
    </main>

    <snap-main-navigation
      :theme="theme"
      :navigationOptionsInput.prop="sidebar"
      :impersonationMode="isImpersonated"
      @navigationupdate="navigate"
      @snap-tool-switcher-update="switchContext"
    />
  </component>

  <SelfIdentifyDialog></SelfIdentifyDialog>
  <ZendeskHelpWidget></ZendeskHelpWidget>
</template>

<script lang="ts">
import { mapGetters } from "vuex";
import { Options, Vue } from "vue-class-component";
import {
  MainNavigationOption,
  MainNavigationUpdatedEvent,
} from "@snap-mobile/snap-ui/dist/types/utils";

import PageHeaderNav from "@/components/internal/common/PageHeaderNav.vue";
import SelfIdentifyDialog from "@/components/internal/challenges/SelfIdentifyDialog.vue";
import ZendeskHelpWidget from "@/components/ZendeskHelpWidget.vue";

import type { MainNavigationPermissions } from "@/types/snap-ui";
import { FeatureToggle } from "@/store/treatments.module";
import { PageContext } from "@/types/ui";
import { MultiplePermissionsChecker } from "@/store/user.module";

@Options({
  components: { ZendeskHelpWidget, SelfIdentifyDialog, PageHeaderNav },
  computed: {
    ...mapGetters([
      "theme",
      "isAuthorized",
      "isSpoofed",
      "featureOn",
      "hasEveryPermissions",
    ]),
  },
})
export default class InternalPage extends Vue {
  theme?: string;
  isAuthorized?: boolean;
  isSpoofed!: boolean;
  featureOn?: FeatureToggle;
  hasEveryPermissions?: MultiplePermissionsChecker;

  get wrapper(): string {
    return this.isImpersonated ? "snap-impersonation-mode" : "div";
  }

  get isImpersonated(): boolean {
    return this.featureOn?.("USER-801-use-snap-impersonation-mode")
      ? this.isSpoofed
      : false;
  }

  get sidebar(): Array<MainNavigationOption & MainNavigationPermissions> {
    return [
      {
        id: "home",
        text: "My Profile",
        active: true,
        icon: "user-solid",
        context: PageContext.User,
      },
      {
        id: "family",
        text: "Guardian Portal",
        active: false,
        icon: "user-group-solid",
        hasAnyPermissions: this.$store.getters.featureOn(
          "USER-799-allow-guardian-portal-for-parents"
        )
          ? ["computed:has_family", "computed:parent"]
          : ["computed:has_family"],
        context: PageContext.User,
      },
      {
        id: "orgs/list",
        text: "Messaging Admin",
        active: this.$route.path.startsWith("/orgs"),
        icon: "office-building-settings-solid",
        hasEveryPermissions: ["computed:show_orgs_admin"],
        toggle: "USER-934-show-staff-roster-page",
        context: PageContext.OrgAdmin,
      },
      {
        id: "admin/users",
        text: "Users",
        active: false,
        icon: "users-solid",
        hasEveryPermissions: ["user:read"],
        context: PageContext.Admin,
      },
      {
        id: "admin/invites",
        text: "Invites",
        active: false,
        icon: "user-add-solid",
        hasEveryPermissions: ["invite:list"],
        context: PageContext.Admin,
      },
      {
        id: "admin/roles",
        text: "Roles",
        active: false,
        icon: "identification-solid",
        hasEveryPermissions: ["role:read"],
        context: PageContext.Admin,
      },
      {
        id: "security/keys",
        text: "API Keys",
        active: false,
        icon: "key-solid",
        hasEveryPermissions: ["user_keys:access", "permission:read"],
        toggle: "USER-796-access-to-api-keys",
        context: PageContext.Admin,
      },
    ]
      .map((option) => ({
        ...option,
        active:
          option.id === "orgs/list"
            ? option.active
            : option.id === this.$route.name,
      }))
      .filter((option) => {
        if (option.hasAnyPermissions)
          return this.$store.getters.hasAnyPermissions(
            option.hasAnyPermissions
          );

        if (option.hasEveryPermissions)
          return this.$store.getters.hasEveryPermissions(
            option.hasEveryPermissions
          );

        return true;
      })
      .filter(
        (option) =>
          !option.toggle || this.$store.getters.featureOn(option.toggle)
      );
  }

  async beforeMount() {
    await this.$store.dispatch("useTreatments");
  }

  navigate(ev: CustomEvent<MainNavigationUpdatedEvent>) {
    if (ev.detail.id) {
      this.$router.push({ name: ev.detail.id });
    }
  }

  switchContext(ev: CustomEvent) {
    if (ev.detail.id) {
      this.$router.push({ name: ev.detail.id });
    }
  }

  onImpersonationModeChange(event: CustomEvent) {
    if (event.detail !== this.isSpoofed) location.reload();
  }
}
</script>

<style scoped lang="scss">
:deep(.snap-impersonation-mode) {
  @media (max-width: 768px) {
    overflow-x: hidden;
    overflow-y: auto;
  }
}

main {
  @media (max-width: 768px) {
    padding-top: 48px;
    padding-bottom: 61px;
  }

  @media (min-width: 768px) and (max-width: 992px) {
    padding-top: 56px;
    padding-left: 92px;
  }

  @media (min-width: 992px) {
    padding-top: 56px;
    padding-left: 286px;
  }
}
</style>
