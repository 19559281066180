export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  BigInt: { input: any; output: any; }
  Date: { input: any; output: any; }
  DateTime: { input: any; output: any; }
  JSON: { input: any; output: any; }
  JSONObject: { input: any; output: any; }
  Upload: { input: any; output: any; }
  Void: { input: any; output: any; }
};

/** Account balance information */
export type AccountBalanceReturn = {
  __typename?: 'AccountBalanceReturn';
  /** Available balance in cents */
  available?: Maybe<Scalars['Int']['output']>;
  /** Pending balance in cents */
  pending?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type AccountManager = DriveUser & {
  __typename?: 'AccountManager';
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
};

/** See CampaignMembership for details */
export type AdminCampaign = {
  __typename?: 'AdminCampaign';
  id: Scalars['ID']['output'];
};

export enum Affiliate {
  Booster = 'BOOSTER',
  Foundation = 'FOUNDATION',
  Other = 'OTHER',
  ParentAssociation = 'PARENT_ASSOCIATION',
  ParentOrganization = 'PARENT_ORGANIZATION',
  ParentTeacherAssociation = 'PARENT_TEACHER_ASSOCIATION',
  ParentTeacherOrganization = 'PARENT_TEACHER_ORGANIZATION',
  ParentTeacherStudentAssociation = 'PARENT_TEACHER_STUDENT_ASSOCIATION',
  ParentTeacherStudentOrganization = 'PARENT_TEACHER_STUDENT_ORGANIZATION'
}

export enum AffiliationTitle {
  ActivitiesDirector = 'ACTIVITIES_DIRECTOR',
  AdministrativeAssistant = 'ADMINISTRATIVE_ASSISTANT',
  AssistantCoach = 'ASSISTANT_COACH',
  AthleticDirector = 'ATHLETIC_DIRECTOR',
  BoosterClubLeader = 'BOOSTER_CLUB_LEADER',
  BoosterClubMember = 'BOOSTER_CLUB_MEMBER',
  BusinessOwner = 'BUSINESS_OWNER',
  ClubSportsAdministrator = 'CLUB_SPORTS_ADMINISTRATOR',
  ClubSportsDirector = 'CLUB_SPORTS_DIRECTOR',
  ClubSportEmployee = 'CLUB_SPORT_EMPLOYEE',
  Coach = 'COACH',
  DistrictAdministrator = 'DISTRICT_ADMINISTRATOR',
  FinancialAdministrator = 'FINANCIAL_ADMINISTRATOR',
  FineArtsDirector = 'FINE_ARTS_DIRECTOR',
  Other = 'OTHER',
  Parent = 'PARENT',
  President = 'PRESIDENT',
  SchoolAdministrator = 'SCHOOL_ADMINISTRATOR',
  SchoolClubAdvisor = 'SCHOOL_CLUB_ADVISOR',
  Sponsor = 'SPONSOR',
  StateAdministrator = 'STATE_ADMINISTRATOR',
  Student = 'STUDENT',
  TeacherInstructor = 'TEACHER_INSTRUCTOR',
  Volunteer = 'VOLUNTEER'
}

export type ApiKey = {
  __typename?: 'ApiKey';
  createdAt: Scalars['Date']['output'];
  expiresAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['String']['output'];
  label: Scalars['String']['output'];
  maxRps: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  permissions: Array<ApiKeyPermission>;
  secret: Scalars['String']['output'];
  user: User;
};

export type ApiKeyPermission = {
  __typename?: 'ApiKeyPermission';
  permission: Permission;
};

export type AssignmentInput = {
  id: Scalars['String']['input'];
  type: ChannelTypesEnum;
};

export type Auth = Tokens & {
  __typename?: 'Auth';
  accessToken?: Maybe<Scalars['String']['output']>;
  refreshToken?: Maybe<Scalars['String']['output']>;
};

export type AuthChallenge = Tokens & {
  __typename?: 'AuthChallenge';
  accessToken?: Maybe<Scalars['String']['output']>;
  challenges?: Maybe<Array<Maybe<UserChallenge>>>;
  refreshToken?: Maybe<Scalars['String']['output']>;
};

export type AuthenticationFlow = {
  __typename?: 'AuthenticationFlow';
  name?: Maybe<Scalars['String']['output']>;
  redirectUrl?: Maybe<Scalars['String']['output']>;
};

export enum BasicCampaignStatus {
  Active = 'ACTIVE',
  Closed = 'CLOSED',
  Upcoming = 'UPCOMING'
}

export type BulkRewards = {
  __typename?: 'BulkRewards';
  id: Scalars['Int']['output'];
  incentiveId?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['String']['output']>;
  tier?: Maybe<Scalars['Int']['output']>;
};

/**
 *  @deprecated: will be merged with DriveCampaign instead
 * This type is for Drive's Campaign Management.
 * Users must have the Drive's permission to access any data.
 * We are working on migrating this type to DriveCampaign.
 */
export type Campaign = {
  __typename?: 'Campaign';
  /** Account Manager of Campaign */
  accountManager?: Maybe<AccountManager>;
  /** Activity type of campaign. e.g. 'football', 'baseball', etc. */
  activityType?: Maybe<Scalars['String']['output']>;
  approvedFunds?: Maybe<ApprovedFunds>;
  /** URL for Generated Report of (Active) Campaign */
  campaignActiveReportUrl?: Maybe<Scalars['String']['output']>;
  /** City + State string of campaign. e.g. 'Dallas, TX' */
  campaignTerritory?: Maybe<Scalars['String']['output']>;
  coachJoinCode?: Maybe<Scalars['String']['output']>;
  /** Donations statistics of campaign. All values will be 0 if campaign is 'upcoming' */
  donations?: Maybe<Donations>;
  /** Participant email delivery info of campaigns */
  emails?: Maybe<EmailsInfo>;
  /** End date of fundraiser */
  endDate?: Maybe<Scalars['DateTime']['output']>;
  /** Entity ID of campaign. This ID used to obtain financial/payment info */
  entityId?: Maybe<Scalars['Int']['output']>;
  /** Date when campaign is finalized */
  finalizedDate?: Maybe<Scalars['DateTime']['output']>;
  /**
   * Shipping status for gear items.
   * Values are 'delivered','pre_transit','in_transit','failure','available_for_pickup','unknown','out_for_delivery',
   *   'return_to_sender','cancelled','error'
   */
  gearStatus?: Maybe<Scalars['String']['output']>;
  /** Group Leader */
  groupLeader?: Maybe<GroupLeader>;
  /** Deal ID of campaign in Hubspot. */
  hubspotId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  /** Cover image of campaign */
  image?: Maybe<Scalars['String']['output']>;
  /** Initial goal amount to fundraise in cents */
  initialGoalCents?: Maybe<Scalars['Int']['output']>;
  /** Link to invite participants to the campaign */
  inviteParticipantUrl?: Maybe<Scalars['String']['output']>;
  /** Unique join-code of campaign */
  joinCode?: Maybe<Scalars['Int']['output']>;
  /** URL for the fundraiser KYC Form. */
  kycFormUrl?: Maybe<Scalars['String']['output']>;
  /** Campaign kyc status */
  kycStatus?: Maybe<CampaignKycStatusResult>;
  /** Mailing address of campaign. */
  mailingAddress?: Maybe<Scalars['String']['output']>;
  /** MDM Contact of Campaign */
  mdm?: Maybe<MdmDetails>;
  name?: Maybe<Scalars['String']['output']>;
  /** Statistics info of campaign participants */
  participants?: Maybe<ParticipantsInfo>;
  /**
   * Last withdrawal payment status.
   * Values are 'Initializing', 'Processing', 'Transferred', 'Cut', 'Deposited', 'Expired' or 'Failed'
   */
  paymentStatus?: Maybe<Scalars['String']['output']>;
  /** Payment type when campaign finalized. Values are 'Check to Office', 'Check to Salesrep', 'Check to Fundraiser', or 'Direct Deposit'. */
  paymentType?: Maybe<Scalars['String']['output']>;
  /** Base64 generated CSV file for preload emails report of event */
  preloadReport?: Maybe<Scalars['String']['output']>;
  /** Download URL for Final Receipt (PDF) of settled campaigns */
  receiptDownloadUrl?: Maybe<Scalars['String']['output']>;
  /** Settlement Details for Closed Campaigns */
  settlementDetails?: Maybe<SettlementDetails>;
  /** Settlement Confirmation Status for Closed Campaigns. Values are 'UNSUBMITTED', 'IN_REVIEW' and 'VERIFIED' */
  settlementStatus?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  /** Start date of fundraiser */
  startDate?: Maybe<Scalars['DateTime']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  /** Size of team/group of campaign */
  teamSize?: Maybe<Scalars['Int']['output']>;
  /** Not in-use. Deprecated */
  testEmails?: Maybe<Scalars['String']['output']>;
  /** Total amount raised in cents */
  totalRaisedCents?: Maybe<Scalars['Int']['output']>;
  /** The total raised combined this organization has previously ran campaigns */
  totalRaisedHistory?: Maybe<Scalars['Int']['output']>;
  /** Tracking link for shipping gear items. */
  trackingLink?: Maybe<Scalars['String']['output']>;
};

export type CampaignDates = {
  __typename?: 'CampaignDates';
  endDateTime: Scalars['String']['output'];
  isAllDay?: Maybe<Scalars['Boolean']['output']>;
  startDateTime: Scalars['String']['output'];
};

/** Resigned campaigns */
export type CampaignHistoryList = {
  __typename?: 'CampaignHistoryList';
  CampaignHistoryEndDate?: Maybe<Scalars['String']['output']>;
  CampaignHistorySlug?: Maybe<Scalars['String']['output']>;
  CampaignHistoryStartDate?: Maybe<Scalars['String']['output']>;
  campaignName?: Maybe<Scalars['String']['output']>;
  campaignTotalRaised?: Maybe<Scalars['Int']['output']>;
};

export type CampaignKyc = {
  __typename?: 'CampaignKyc';
  status?: Maybe<CampaignKycStatusEnum>;
};

/** Fixed statuses for a campaigns kyc */
export enum CampaignKycStatus {
  ActionRequired = 'action_required',
  InReview = 'in_review',
  RequireDocs = 'require_docs',
  Unsubmitted = 'unsubmitted',
  Unverified = 'unverified',
  Verified = 'verified'
}

export enum CampaignKycStatusEnum {
  ActionNeeded = 'ACTION_NEEDED',
  Pending = 'PENDING',
  Unsubmitted = 'UNSUBMITTED',
  Verified = 'VERIFIED'
}

export type CampaignKycStatusResult = {
  __typename?: 'CampaignKycStatusResult';
  data?: Maybe<CampaignKycStatus>;
  type?: Maybe<CampaignKycType>;
};

/** Fixed type for a campaigns kyc */
export enum CampaignKycType {
  Stripe = 'stripe',
  Wepay = 'wepay'
}

export type CampaignList = {
  __typename?: 'CampaignList';
  count?: Maybe<Scalars['Int']['output']>;
  list: Array<Maybe<Campaign>>;
  offset?: Maybe<Scalars['Int']['output']>;
};

export enum CampaignMemberAssociation {
  Admin = 'ADMIN',
  Coach = 'COACH',
  Cocoach = 'COCOACH',
  Coparticipant = 'COPARTICIPANT',
  Participant = 'PARTICIPANT',
  Supporter = 'SUPPORTER'
}

/**
 * CampaignMembership is our way of securing information based on a users membership type.
 * When a user queries campaignMemberships they can get information on their memberships based on who they are as defined in the JWT.
 * Example of how to use:https://www.notion.so/snap-mobile/Campaign-1b4c4a055bc84aaf8290f078f57a5193
 */
export type CampaignMembership = AdminCampaign | GroupLeaderCampaign | ParticipantCampaign | SupporterCampaign;

export type CampaignPersonList = {
  __typename?: 'CampaignPersonList';
  id: Scalars['Int']['output'];
};

export type CampaignPersonListData = {
  __typename?: 'CampaignPersonListData';
  email: Scalars['String']['output'];
  id?: Maybe<Scalars['Int']['output']>;
};

export type CampaignPersonListEntries = {
  __typename?: 'CampaignPersonListEntries';
  invalidEntries?: Maybe<Array<Maybe<InvalidCampaignPersonListData>>>;
  validEntries?: Maybe<Array<Maybe<CampaignPersonListData>>>;
};

export type CampaignPersonListEntryDelete = {
  __typename?: 'CampaignPersonListEntryDelete';
  id: Scalars['Int']['output'];
};

export type CampaignSearchFilter = {
  __typename?: 'CampaignSearchFilter';
  dateCreated?: Maybe<Scalars['DateTime']['output']>;
  dateUpdated?: Maybe<Scalars['DateTime']['output']>;
  /** Collection (key/values) of saved search criteria.  */
  filterCriteria?: Maybe<Scalars['JSON']['output']>;
  /** Name of the saved search filter. */
  filterName?: Maybe<Scalars['String']['output']>;
  /** ID of saved search filters. */
  id?: Maybe<Scalars['Int']['output']>;
};

export type CampaignSmsDataResponse = {
  __typename?: 'CampaignSmsDataResponse';
  invalidEntries: Array<InvalidCampaignSmsInviteData>;
  status?: Maybe<CampaignSmsInviteStatus>;
  validEntries: Array<CampaignSmsInviteData>;
};

export type CampaignSmsInviteData = {
  __typename?: 'CampaignSmsInviteData';
  id: Scalars['Int']['output'];
  phoneNumber: Scalars['String']['output'];
};

export type CampaignSmsInviteDeleteResponse = {
  __typename?: 'CampaignSmsInviteDeleteResponse';
  status: Scalars['String']['output'];
};

export enum CampaignSmsInviteStatus {
  Preloaded = 'PRELOADED',
  PreloadQueued = 'PRELOAD_QUEUED',
  Queued = 'QUEUED'
}

export enum CampaignStatus {
  Active = 'ACTIVE',
  Approved = 'APPROVED',
  Archive = 'ARCHIVE',
  Closed = 'CLOSED',
  Deleted = 'DELETED',
  Final = 'FINAL',
  PendingApproval = 'PENDING_APPROVAL',
  Settled = 'SETTLED'
}

export type CarouselItem = {
  __typename?: 'CarouselItem';
  id?: Maybe<Scalars['Int']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  media_type?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type Channel = {
  __typename?: 'Channel';
  chatProviderId: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isReadOnly?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  status: ChannelStatusEnum;
  chatType: ChannelTypesEnum;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type ChannelAddModeratorsError = Error & {
  __typename?: 'ChannelAddModeratorsError';
  message: Scalars['String']['output'];
};

export type ChannelAddUsersError = Error & {
  __typename?: 'ChannelAddUsersError';
  message: Scalars['String']['output'];
};

export type ChannelCreateError = Error & {
  __typename?: 'ChannelCreateError';
  message: Scalars['String']['output'];
};

export type ChannelInvite = {
  __typename?: 'ChannelInvite';
  contact: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  method: ContactMethodEnum;
  role: ChannelRolesEnum;
  status?: Maybe<ChannelInviteStatusEnum>;
};

export type ChannelInviteAcceptedContacts = {
  __typename?: 'ChannelInviteAcceptedContacts';
  contact: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  method: ContactMethodEnum;
  role: ChannelRolesEnum;
};

export type ChannelInviteFailedContacts = {
  __typename?: 'ChannelInviteFailedContacts';
  contact: Scalars['String']['output'];
  error?: Maybe<Scalars['String']['output']>;
  method: ContactMethodEnum;
  role: ChannelRolesEnum;
};

export type ChannelInviteInput = {
  channelId: Scalars['String']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type ChannelInviteMembers = {
  __typename?: 'ChannelInviteMembers';
  acceptedContacts: Array<ChannelInviteAcceptedContacts>;
  failedContacts: Array<ChannelInviteFailedContacts>;
};

export type ChannelInviteMembersError = Error & {
  __typename?: 'ChannelInviteMembersError';
  message: Scalars['String']['output'];
};

export enum ChannelInviteStatusEnum {
  Accepted = 'ACCEPTED',
  Pending = 'PENDING'
}

export type ChannelInvites = {
  __typename?: 'ChannelInvites';
  count?: Maybe<Scalars['Int']['output']>;
  invites?: Maybe<Array<Maybe<ChannelInvite>>>;
};

export type ChannelMembers = {
  __typename?: 'ChannelMembers';
  members: Array<UserPublic>;
};

export type ChannelNotFound = Error & {
  __typename?: 'ChannelNotFound';
  message: Scalars['String']['output'];
};

export type ChannelRemoveMembersError = Error & {
  __typename?: 'ChannelRemoveMembersError';
  message: Scalars['String']['output'];
};

export enum ChannelRolesEnum {
  ChannelMember = 'channel_member',
  ChannelModerator = 'channel_moderator'
}

export enum ChannelStatusEnum {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
  Disabled = 'DISABLED',
  Frozen = 'FROZEN'
}

export enum ChannelTypesEnum {
  Fundraiser = 'fundraiser',
  RosterMessaging = 'roster_messaging'
}

export type ChannelUpdateError = Error & {
  __typename?: 'ChannelUpdateError';
  message: Scalars['String']['output'];
};

export type ChannelUpdateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  isReadOnly?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ChannelStatusEnum>;
};

export type ChannelUpdateMembersError = Error & {
  __typename?: 'ChannelUpdateMembersError';
  message: Scalars['String']['output'];
};

export type ChannelUpsertUsersError = Error & {
  __typename?: 'ChannelUpsertUsersError';
  message: Scalars['String']['output'];
};

export type Channels = {
  __typename?: 'Channels';
  channels: Array<Channel>;
};

export type ChatChannelAddModeratorsResult = Channel | ChannelAddModeratorsError | ChannelNotFound | NotAuthorized;

export type ChatChannelAddUsersResult = Channel | ChannelAddUsersError | ChannelNotFound | NotAuthorized;

export type ChatChannelCreateResult = Channel | ChannelCreateError | NotAuthorized;

export type ChatChannelResult = Channel | ChannelNotFound | MissingArguments | NotAuthenticated;

export type ChatChannelUpdateMembersResult = Channel | ChannelNotFound | ChannelUpdateMembersError | MissingArguments | NotAuthorized;

export type ChatChannelUpdateResult = Channel | ChannelNotFound | ChannelUpdateError | NotAuthenticated | NotAuthorized;

export type ChatChannelsResult = Channels | NotAuthenticated;

export type ChatToken = {
  __typename?: 'ChatToken';
  accessToken: Scalars['String']['output'];
};

export type ChatTokenResult = ChatToken | NotAuthenticated;

export type ChatUnreadMessagesResult = NotAuthenticated | UnreadCount | UnreadCountError;

export type Cheer = {
  __typename?: 'Cheer';
  anonymous?: Maybe<Scalars['Boolean']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  donorMessage?: Maybe<Scalars['String']['output']>;
  donorName?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  images?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  lastName?: Maybe<Scalars['String']['output']>;
  subtotalCents?: Maybe<Scalars['Int']['output']>;
};

export type CombinedPermissions = {
  __typename?: 'CombinedPermissions';
  permissions?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type Consumer = {
  __typename?: 'Consumer';
  accessBy?: Maybe<Scalars['String']['output']>;
  color: Scalars['String']['output'];
  description: Scalars['String']['output'];
  icon: Scalars['String']['output'];
  id: Scalars['String']['output'];
  inApps: Scalars['Boolean']['output'];
  logo: Scalars['String']['output'];
  mask: Scalars['String']['output'];
  modal?: Maybe<ConsumerModal>;
  title: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type ConsumerModal = {
  __typename?: 'ConsumerModal';
  descriptions: Array<Maybe<Scalars['String']['output']>>;
  header: Scalars['String']['output'];
};

export enum ContactMethodEnum {
  Email = 'EMAIL',
  PhoneNumber = 'PHONE_NUMBER'
}

export enum ContactTemplateMedium {
  Email = 'EMAIL',
  TextMessage = 'TEXT_MESSAGE'
}

export enum ContactTemplateType {
  GroupLeader = 'GROUP_LEADER',
  Participant = 'PARTICIPANT'
}

export type CreateChildFundraiserParticipantInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  fundraiserId: Scalars['ID']['input'];
  groupId?: InputMaybe<Scalars['ID']['input']>;
  joinType?: InputMaybe<JoinType>;
  lastName: Scalars['String']['input'];
  udid: Scalars['String']['input'];
};

export type CreatedFundraiserUser = {
  __typename?: 'CreatedFundraiserUser';
  id: Scalars['Int']['output'];
};

export type CreatedOtkEntry = {
  __typename?: 'CreatedOtkEntry';
  id: Scalars['Int']['output'];
};

export type CreatedParticipantRewards = {
  __typename?: 'CreatedParticipantRewards';
  id: Scalars['Int']['output'];
};

export type CurrentCustomersDeals = {
  __typename?: 'CurrentCustomersDeals';
  activity?: Maybe<Scalars['String']['output']>;
  dealName?: Maybe<Scalars['String']['output']>;
  dealStage?: Maybe<Scalars['String']['output']>;
  entityId?: Maybe<Scalars['String']['output']>;
  fundraiserStatus?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lastLaunch?: Maybe<Scalars['String']['output']>;
  leaderFirstName?: Maybe<Scalars['String']['output']>;
  leaderLastName?: Maybe<Scalars['String']['output']>;
  previousDealId?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  snapFundraiserId?: Maybe<Scalars['String']['output']>;
  totalRaised?: Maybe<Scalars['Int']['output']>;
};

/** Information of custom email templates */
export type CustomContactTemplates = {
  __typename?: 'CustomContactTemplates';
  dateCreated?: Maybe<Scalars['DateTime']['output']>;
  dateUpdated?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
};

export type DeletedIncentiveRecord = {
  __typename?: 'DeletedIncentiveRecord';
  id: Scalars['ID']['output'];
};

/** Not used yet. Will be removed soon. */
export type DonationInvite = {
  __typename?: 'DonationInvite';
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastEmailSentAt?: Maybe<Scalars['String']['output']>;
  senderName?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type DonationInviteReleaseStatus = {
  __typename?: 'DonationInviteReleaseStatus';
  areDonationInviteEmailsReleased: Scalars['Boolean']['output'];
};

export type DonationInviteRemoveData = {
  __typename?: 'DonationInviteRemoveData';
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
};

export type DonationInviteSmsData = {
  __typename?: 'DonationInviteSMSData';
  id: Scalars['Int']['output'];
  phoneNumber: Scalars['String']['output'];
  status: Scalars['String']['output'];
};

export type DonationLevel = {
  __typename?: 'DonationLevel';
  amount: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  level: DonationLevels;
  rewardDescription?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

export enum DonationLevels {
  Five = 'FIVE',
  Four = 'FOUR',
  One = 'ONE',
  Six = 'SIX',
  Three = 'THREE',
  Two = 'TWO'
}

export type DonationTopEarner = {
  __typename?: 'DonationTopEarner';
  participantID?: Maybe<Scalars['Int']['output']>;
};

/** Donation Statistics. Will change name to CampaignDonationStats later. */
export type Donations = {
  __typename?: 'Donations';
  /** Total of distinct donors in campaigns */
  AskedToShare?: Maybe<Scalars['Int']['output']>;
  /** Total count of donations in campaign */
  count?: Maybe<Scalars['Int']['output']>;
  /** Total donation amount in cents raised through email */
  emailTotal?: Maybe<Scalars['Int']['output']>;
  /** Total donation amount in cents raised other methods outside text, email and social */
  extraTotal?: Maybe<Scalars['Int']['output']>;
  /** Total of donation amount in cents raised through forward to friends. */
  fowardTotal?: Maybe<Scalars['Int']['output']>;
  /** Total quantity of gear items purchased in campaign. */
  gearAdded?: Maybe<Scalars['Int']['output']>;
  /** Total count of distinct donors that purchased gears. */
  gearPurchased?: Maybe<Scalars['Int']['output']>;
  /** Total of purchase amount in cents of gear (OTK) items */
  gearTotal?: Maybe<Scalars['Int']['output']>;
  /**
   * Total donation amounts in cents through retaining supporters from previous closed campaign.
   * Share Type is 'reisgn_a' or 'resign_b'
   */
  legacySupporters?: Maybe<Scalars['Int']['output']>;
  /** Total count of distinct donors who are new and donated campaigns through share-type 'supporter_referral' */
  newSupporters?: Maybe<Scalars['Int']['output']>;
  otherTotal?: Maybe<Scalars['Int']['output']>;
  /**
   * Total count of donors who are retaining supporters from previous closed campaign.
   * Share Type is 'reisgn_a' or 'resign_b'
   */
  pastSupporters?: Maybe<Scalars['Int']['output']>;
  /** Total donation amount in cents raised through social media */
  socialTotal?: Maybe<Scalars['Int']['output']>;
  /** Total donation amount in cents raised through sms/text */
  textTotal?: Maybe<Scalars['Int']['output']>;
};

export type DonorEmailData = {
  __typename?: 'DonorEmailData';
  deliveryStatus?: Maybe<Scalars['String']['output']>;
  emailAddress?: Maybe<Scalars['String']['output']>;
  followUpNumber?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  personListEntryID?: Maybe<Scalars['Int']['output']>;
};

export type DonorPersonListEntry = {
  __typename?: 'DonorPersonListEntry';
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
};

export type DonorsParticipantOtkIncentive = {
  __typename?: 'DonorsParticipantOTKIncentive';
  description?: Maybe<Scalars['String']['output']>;
  displayOrder?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  priceCents?: Maybe<Scalars['Int']['output']>;
  purchaseLimit?: Maybe<Scalars['Int']['output']>;
  purchased?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['String']['output']>;
};

export type DonorsParticipantOtkIncentives = {
  __typename?: 'DonorsParticipantOTKIncentives';
  incentives?: Maybe<Array<Maybe<DonorsParticipantOtkIncentive>>>;
};

export type DonorsParticipantsGiftShop = {
  __typename?: 'DonorsParticipantsGiftShop';
  fundraiserUserId?: Maybe<Scalars['Int']['output']>;
  giftShop?: Maybe<Array<Maybe<DonorsParticipantsGiftShopData>>>;
  totalPurchased?: Maybe<Scalars['Int']['output']>;
};

export type DonorsParticipantsGiftShopData = {
  __typename?: 'DonorsParticipantsGiftShopData';
  costCents?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  netEarnedPercentage?: Maybe<Scalars['Float']['output']>;
  priceCents?: Maybe<Scalars['Int']['output']>;
  promoLabel?: Maybe<Scalars['String']['output']>;
  purchaseLimit?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['String']['output']>;
};

/**
 * Account User is a user that is registered in Raise's database.
 * Deprecated: Use UserDirectory 's User instead instead
 */
export type DriveAccountUser = DriveUser & {
  __typename?: 'DriveAccountUser';
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  profilePicture?: Maybe<Scalars['String']['output']>;
  snapRaiseId?: Maybe<Scalars['Int']['output']>;
};

/** MDM Actor. e.g. Account Manager/Salereps/Sales Manager */
export type DriveAmSalesReps = {
  __typename?: 'DriveAmSalesReps';
  /** Primary contact email of salesrep */
  email?: Maybe<Scalars['String']['output']>;
  /** End time of salesrep */
  endDate?: Maybe<Scalars['DateTime']['output']>;
  /** First Name of Salesrep */
  firstName?: Maybe<Scalars['String']['output']>;
  /** Last Name of Salesrep */
  lastName?: Maybe<Scalars['String']['output']>;
  /** Primary phone number of salesrep */
  phone?: Maybe<Scalars['String']['output']>;
  /** The sales manager of this salesrep */
  salesManager?: Maybe<DriveAmSalesReps>;
  /** Salesrep Id. This is Raise's account user id. */
  salesrepId?: Maybe<Scalars['Int']['output']>;
  /** Start time of salesrep */
  startDate?: Maybe<Scalars['DateTime']['output']>;
  /** actor type. e.g. 'salesrep', 'salesmanager', 'am' */
  type?: Maybe<Scalars['String']['output']>;
};

export enum DriveAttendanceStatus {
  Attending = 'ATTENDING',
  NotAttending = 'NOT_ATTENDING'
}

/** Information of a single drive campaign */
export type DriveCampaign = {
  __typename?: 'DriveCampaign';
  /** The account manager of the campaign */
  accountManager?: Maybe<AccountManager>;
  /** @deprecated: not used, in favor of totalRaisedCents */
  donationRaisedCents?: Maybe<Scalars['Int']['output']>;
  /** The date the campaign ends */
  endDate?: Maybe<Scalars['DateTime']['output']>;
  /** The date the campaign has been finalized/closed */
  finalizedDate?: Maybe<Scalars['DateTime']['output']>;
  /** Indicate whether the campaign has any incentives, such as OTK */
  hasIncentive?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['Int']['output'];
  /** Name of campaign */
  name?: Maybe<Scalars['String']['output']>;
  /** Notes for a campaign */
  notes?: Maybe<Scalars['String']['output']>;
  /** Url-slug of campaign name which is a unique identifier */
  slug?: Maybe<Scalars['String']['output']>;
  /** The date campaign starts */
  startDate?: Maybe<Scalars['DateTime']['output']>;
  /** Current status of campaign */
  status?: Maybe<CampaignStatus>;
  /** The size of the team/group attached to campaign */
  teamSize?: Maybe<Scalars['Int']['output']>;
  /** @deprecated: not used, in favor of `totalRaisedCents` */
  totalRaised?: Maybe<Scalars['BigInt']['output']>;
  /** Total amount of donation of campaign in cents.  */
  totalRaisedCents?: Maybe<Scalars['Int']['output']>;
};

/**
 * Campaign/Fundraiser details information.
 * NOTE: In the future, we might merged with DriveCampaign or Campaign
 */
export type DriveCampaignDetails = {
  __typename?: 'DriveCampaignDetails';
  /** Account Balance of campaign. */
  accountBalance?: Maybe<AccountBalanceReturn>;
  /** The activity/sport of this campaign, e.g. 'football', 'wrestling', etc. */
  activityType?: Maybe<Scalars['String']['output']>;
  /** The amount in cents that campaigns was advanced in Wallet. */
  advanceAmount?: Maybe<Scalars['Int']['output']>;
  /** Bill type when settled/finalized. Values are 'invoice', 'regular' and 'default' */
  billType?: Maybe<Scalars['String']['output']>;
  /** Credit Card Fee Payer. Values are 'customer' or 'snap'. */
  ccFeePayer?: Maybe<Scalars['String']['output']>;
  /**
   * Charge back fee in cents.
   * The charge from payment processor when a donor cancels their donation through their bank instead of getting a refund from us directly. (usually $15 per)
   */
  chargebackFee?: Maybe<Scalars['Int']['output']>;
  /** The statistic of a donation of campaigns */
  donationStats?: Maybe<DriveCampaignDonationStats>;
  /** The statistics of emails delivered/sent */
  emailDelivery?: Maybe<EmailsInfo>;
  /** The type of snap fee being collected. e.g. 'standard', '1+1', '20%/15%', etc. */
  feeType?: Maybe<Scalars['String']['output']>;
  /** Total incentives price in cents. */
  gearCost?: Maybe<Scalars['Int']['output']>;
  /** Group leader of a campaign */
  groupLeader?: Maybe<DriveGroupLeader>;
  id: Scalars['Int']['output'];
  /** Cover Image for Campaign */
  image?: Maybe<Scalars['String']['output']>;
  /** Goal of how much a fundraiser wants to raise */
  initialGoalCents?: Maybe<Scalars['Int']['output']>;
  /** kyc processor. Values are 'wepay' or 'stripe' */
  kycProcessor?: Maybe<Scalars['String']['output']>;
  /** kyc status of a campaign. Values are 'pending', 'active', 'inactive', etc. */
  kycStatus?: Maybe<Scalars['String']['output']>;
  /** The address to ship the check for this campaign */
  mailingAddress?: Maybe<Scalars['String']['output']>;
  /** The amount in cents that campaigns was manual-advanced in Raise. */
  manualAdvanceAmount?: Maybe<Scalars['Int']['output']>;
  /**
   * Net raised amount in cents. Equals totalRaised minus snapFee and adds the otkBonus.
   * It is the amount client received in their check/deposit.
   */
  netRaisedTotal?: Maybe<Scalars['Int']['output']>;
  /** Notes for settlement for this campaign */
  notes?: Maybe<Scalars['String']['output']>;
  /** The legal name of a fundraisers organization */
  organizationLegalName?: Maybe<Scalars['String']['output']>;
  /** The tin number of the organization */
  organizationTin?: Maybe<Scalars['String']['output']>;
  /** Total bonus amount of otk in cents */
  otkBonus?: Maybe<Scalars['Int']['output']>;
  /** The statistics of participants's activity of campaign */
  participantStats?: Maybe<DriveParticipantStats>;
  /** Processing Fee when settled/finalized. */
  processingFee?: Maybe<Scalars['Int']['output']>;
  /** The date where the campaign has settle its payments from snap */
  settlementDate?: Maybe<Scalars['DateTime']['output']>;
  /** How the funds has delivered to the organization. Values are 'check_fundraiser', 'check_salesrep', 'check_office' and 'direct_deposit' */
  settlementMethod?: Maybe<Scalars['String']['output']>;
  /** The amount in cents collected as fee when a campaign ends based of the fee type */
  snapFee?: Maybe<Scalars['Float']['output']>;
  /** Location of this fundraiser */
  territory?: Maybe<Scalars['String']['output']>;
  /** The amount of purchase in cents. */
  totalPurchaseCents?: Maybe<Scalars['Int']['output']>;
  /** Total raised of a fundraiser */
  totalRaisedCents?: Maybe<Scalars['Int']['output']>;
  /** The total raised combined this organization has previously ran campaigns */
  totalRaisedHistory?: Maybe<Scalars['Int']['output']>;
};

/** Pulls data on a campaigns donation broken down */
export type DriveCampaignDonationStats = {
  __typename?: 'DriveCampaignDonationStats';
  ccCoverageAmountCents?: Maybe<Scalars['Int']['output']>;
  donationAppFeeCents?: Maybe<Scalars['Int']['output']>;
  /** The amount that been raised */
  donationRaisedCents?: Maybe<Scalars['Int']['output']>;
  /** The total count of donations */
  donationsCount?: Maybe<Scalars['Int']['output']>;
  offlineTotalCents?: Maybe<Scalars['Int']['output']>;
  onlineTotalCents?: Maybe<Scalars['Int']['output']>;
  /** The count of participants */
  participantCount?: Maybe<Scalars['Int']['output']>;
  /** The donated amount in cents that been raised by emails delivered */
  totalEmailDonations?: Maybe<Scalars['Int']['output']>;
  /** The amount that been raised by miscellaneous forms */
  totalExtraDonations?: Maybe<Scalars['Int']['output']>;
  totalOtherDonations?: Maybe<Scalars['Int']['output']>;
  /** The donated amount that been raised by sharing through social media */
  totalSocialDonations?: Maybe<Scalars['Int']['output']>;
  /** The donated amount in cents that been raised by text message */
  totalTextDonations?: Maybe<Scalars['Int']['output']>;
};

/** Paginated list of campaigns */
export type DriveCampaignList = {
  __typename?: 'DriveCampaignList';
  /** Total campaigns  */
  count?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<DriveCampaign>>>;
  offset?: Maybe<Scalars['Int']['output']>;
};

/** Statistics for a set of campaigns in campaign-search. */
export type DriveCampaignSearchStatistics = {
  __typename?: 'DriveCampaignSearchStatistics';
  avgDonationDollars?: Maybe<Scalars['Float']['output']>;
  avgEmailsDelivered?: Maybe<Scalars['Float']['output']>;
  avgEmailsPerParticipant?: Maybe<Scalars['Float']['output']>;
  avgRaisedDollarsPerCampaign?: Maybe<Scalars['Float']['output']>;
  avgRaisedDollarsPerEmail?: Maybe<Scalars['Float']['output']>;
  avgTeamSize?: Maybe<Scalars['Float']['output']>;
  totalCampaign?: Maybe<Scalars['BigInt']['output']>;
  totalCountDelivered?: Maybe<Scalars['BigInt']['output']>;
  totalCountParticpants?: Maybe<Scalars['BigInt']['output']>;
  totalDonations?: Maybe<Scalars['BigInt']['output']>;
  totalRaisedByEmail?: Maybe<Scalars['Float']['output']>;
  totalRaisedDollars?: Maybe<Scalars['Float']['output']>;
};

export type DriveCampaignUpdateInput = {
  notes?: InputMaybe<Scalars['String']['input']>;
};

export type DriveDateCompareInput = {
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
};

/** Represents a Event object and its attributes */
export type DriveEvent = {
  __typename?: 'DriveEvent';
  /** List of messages in the activity feed. Order by posted ascending by default. */
  activityFeed?: Maybe<Array<Maybe<DriveEventActivityFeed>>>;
  /** Activity types for an event. e.g. `football`, 'basketball', etc. */
  activityTypes?: Maybe<Array<Scalars['String']['output']>>;
  /** Event Agenda which include start-time/end-time of event dates. Should be empty is **agendaConfig.isSameTime** is true */
  agenda?: Maybe<Array<DriveEventAgendaItem>>;
  /**
   * The configuration for agenda. Format as follows
   *
   * ```JSON
   * {
   *   isSameTime: true,
   *   startTime: "09:00AM",
   *   endTime: "07:00PM"
   * }
   * ```
   * where **isSameTime** means all event dates will be occured at same **startTime** and **endTime**.
   * If **isSameTime**, **startTime** and **endTime** should be filled-in
   */
  agendaConfig?: Maybe<Scalars['JSON']['output']>;
  /** List of attendees. */
  attendees?: Maybe<Array<DriveEventAttendee>>;
  /** Booth information for an event. */
  booth?: Maybe<DriveEventBooth>;
  /** The user who is the clinic leader. */
  clinicLeader?: Maybe<User>;
  /** The user who created the event. */
  createdBy?: Maybe<User>;
  /** The description of an event. */
  description?: Maybe<Scalars['String']['output']>;
  /** Indicator that event requires a door prize. */
  doorPrizedRequired?: Maybe<Scalars['Boolean']['output']>;
  /** The end date of an event. */
  endDate: Scalars['String']['output'];
  /** The hotel address for the attendees */
  hotelAddress?: Maybe<Scalars['String']['output']>;
  /** The hotel name for the attendees */
  hotelName?: Maybe<Scalars['String']['output']>;
  /** Url for hubspot link. */
  hubspotTrackingLink?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** Notes associated for the event. */
  notes?: Maybe<Scalars['String']['output']>;
  /** A indicator whether the event had been completed planning phase. */
  planningCompleted?: Maybe<Scalars['Boolean']['output']>;
  /** The time zone of an event */
  preferedTimezone?: Maybe<Scalars['String']['output']>;
  /** Description for the sponsorship */
  sponsorDescription?: Maybe<Scalars['String']['output']>;
  /** Indicator whether the door prize has been confirmed. */
  sponsorDoorPrized?: Maybe<Scalars['Boolean']['output']>;
  /** The start date of an event. */
  startDate: Scalars['String']['output'];
  /** The events status. */
  status: DriveEventStatus;
  /** The tier level for this event. Valid values is `1`, `2` and `3` */
  tier?: Maybe<Scalars['Int']['output']>;
  /** The name of the event. */
  title: Scalars['String']['output'];
  /** Venue location information for an event. */
  venue?: Maybe<DriveVenue>;
  /** Website link for an event. */
  websiteLink?: Maybe<Scalars['String']['output']>;
};

/** Information of a activity feed message */
export type DriveEventActivityFeed = {
  __typename?: 'DriveEventActivityFeed';
  /** A list of replies the message */
  children?: Maybe<Array<Maybe<DriveEventActivityFeed>>>;
  /** The date this post was created */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** The event id */
  eventId: Scalars['ID']['output'];
  /** If the message is pinned to the top */
  featured?: Maybe<Scalars['Boolean']['output']>;
  /** The ID of the message */
  id: Scalars['String']['output'];
  /** The message posted */
  message?: Maybe<Scalars['String']['output']>;
  /** The date when this post has been updated */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** A users first name and last and link to profile photo */
  user?: Maybe<User>;
  /** The id of the user who posted the message */
  userId: Scalars['ID']['output'];
};

export type DriveEventActivityInput = {
  featured?: InputMaybe<Scalars['Boolean']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  parentId?: InputMaybe<Scalars['String']['input']>;
};

export type DriveEventAgendaInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  endTime?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  startTime?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Attributes for an events agenda */
export type DriveEventAgendaItem = {
  __typename?: 'DriveEventAgendaItem';
  description?: Maybe<Scalars['String']['output']>;
  endTime?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  location?: Maybe<Scalars['String']['output']>;
  startTime?: Maybe<Scalars['DateTime']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

/** Represents an events attendee attributes */
export type DriveEventAttendee = {
  __typename?: 'DriveEventAttendee';
  /** The department the attendee is in. */
  department?: Maybe<Scalars['String']['output']>;
  /** The event ID */
  eventId: Scalars['ID']['output'];
  /** The status of an attendee flight request. Default is `NO_REQUEST`. */
  flightRequest?: Maybe<EventAttendeeRequestStatus>;
  /** The status of an attendee hotel request. Default is `NO_REQUEST`. */
  hotelRequest?: Maybe<EventAttendeeRequestStatus>;
  /** @deprecated Use userId instead */
  id: Scalars['ID']['output'];
  /** Indication whether this attendee a clinic leader. */
  isClinicLeader?: Maybe<Scalars['Boolean']['output']>;
  /** The status of an attendee attendence.  */
  status: DriveAttendanceStatus;
  /** User Information of attendee, includes `firstName`, `lastName`, `email`, etc. defined in UserDirectory. */
  user?: Maybe<User>;
  /** The udId of an attendee. e.g. `ud_abc123`  */
  userId: Scalars['ID']['output'];
};

export type DriveEventAttendeeInput = {
  department?: InputMaybe<Scalars['String']['input']>;
  flightRequest?: InputMaybe<EventAttendeeRequestStatus>;
  hotelRequest?: InputMaybe<EventAttendeeRequestStatus>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

/** Booth attributes */
export type DriveEventBooth = {
  __typename?: 'DriveEventBooth';
  /** Notes for the booth e.g. Electricity is paid for */
  boothNotes?: Maybe<Scalars['String']['output']>;
  /** The time to end breakdown or clean up event equipment in Venue. */
  breakdownEndTime?: Maybe<Scalars['DateTime']['output']>;
  /** The time to start breakdown or clean up event equipment in Venue. */
  breakdownStartTime?: Maybe<Scalars['DateTime']['output']>;
  /** Indication whether electricity is provided. */
  electricityProvided?: Maybe<Scalars['Boolean']['output']>;
  /** Indication whether flooring is provided. */
  flooringProvided?: Maybe<Scalars['Boolean']['output']>;
  /** Indication whether internet is provided. */
  internetProvided?: Maybe<Scalars['Boolean']['output']>;
  /** The time to end setup for event */
  setupEndTime?: Maybe<Scalars['DateTime']['output']>;
  /** The time to begin setup for event */
  setupStartTime?: Maybe<Scalars['DateTime']['output']>;
};

export type DriveEventBoothInput = {
  boothNotes?: InputMaybe<Scalars['String']['input']>;
  breakdownEndTime?: InputMaybe<Scalars['String']['input']>;
  breakdownStartTime?: InputMaybe<Scalars['String']['input']>;
  electricityProvided?: InputMaybe<Scalars['Boolean']['input']>;
  flooringProvided?: InputMaybe<Scalars['Boolean']['input']>;
  internetProvided?: InputMaybe<Scalars['Boolean']['input']>;
  setupEndTime?: InputMaybe<Scalars['String']['input']>;
  setupStartTime?: InputMaybe<Scalars['String']['input']>;
};

export type DriveEventInput = {
  activityTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  agenda?: InputMaybe<Array<DriveEventAgendaInput>>;
  agendaConfig?: InputMaybe<Scalars['JSON']['input']>;
  attendees?: InputMaybe<Array<DriveEventAttendeeInput>>;
  booth?: InputMaybe<DriveEventBoothInput>;
  clinicLeaderId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['String']['input']>;
  hotelAddress?: InputMaybe<Scalars['String']['input']>;
  hotelName?: InputMaybe<Scalars['String']['input']>;
  hubspotTrackingLink?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  planningCompleted?: InputMaybe<Scalars['Boolean']['input']>;
  preferedTimezone?: InputMaybe<Scalars['String']['input']>;
  sponsor?: InputMaybe<DriveEventSponsorInput>;
  startDate?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<DriveEventStatus>;
  tier?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  venue?: InputMaybe<DriveEventVenueInput>;
  websiteLink?: InputMaybe<Scalars['String']['input']>;
};

/** Paginated list of drive events */
export type DriveEventResults = {
  __typename?: 'DriveEventResults';
  events?: Maybe<Array<DriveEvent>>;
  offset?: Maybe<Scalars['Int']['output']>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type DriveEventSearchInput = {
  activityTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  attendees?: InputMaybe<Array<Scalars['String']['input']>>;
  boothOptions?: InputMaybe<DriveEventBoothInput>;
  dateRange?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  endDate?: InputMaybe<DriveDateCompareInput>;
  eventStatus?: InputMaybe<EventDateStatus>;
  id?: InputMaybe<Scalars['ID']['input']>;
  sponsor?: InputMaybe<DriveEventSponsorInput>;
  startDate?: InputMaybe<DriveDateCompareInput>;
  states?: InputMaybe<Array<Scalars['String']['input']>>;
  statuses?: InputMaybe<Array<DriveEventStatus>>;
  tiers?: InputMaybe<Array<Scalars['Int']['input']>>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type DriveEventSponsorInput = {
  doorPrized?: InputMaybe<Scalars['Boolean']['input']>;
  doorPrizedRequired?: InputMaybe<Scalars['Boolean']['input']>;
  sponsorDescription?: InputMaybe<Scalars['String']['input']>;
};

export enum DriveEventStatus {
  Closed = 'CLOSED',
  EmailSent = 'EMAIL_SENT',
  NotAttending = 'NOT_ATTENDING',
  Pending = 'PENDING',
  Registered = 'REGISTERED'
}

export type DriveEventVenueInput = {
  buildingOrRoom?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
};

export type DriveFieldInput = {
  label?: InputMaybe<Scalars['String']['input']>;
  linkText?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  type: DriveFieldType;
  value: Scalars['String']['input'];
};

export enum DriveFieldType {
  Html = 'HTML',
  Link = 'LINK',
  Text = 'TEXT'
}

export type DriveFileInput = {
  content: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

/** Organization Summary information used by Drive's Get List Application */
export type DriveGetListOrganizationSummaryReturn = {
  __typename?: 'DriveGetListOrganizationSummaryReturn';
  /**
   * Entity ID of the organization. Its purpose is for caching in Apollo Client.
   * @deprecated use id
   */
  _id?: Maybe<Scalars['Int']['output']>;
  activitiesWithoutCampaigns?: Maybe<Scalars['Int']['output']>;
  averageRaisedPerCampaign?: Maybe<Scalars['Float']['output']>;
  campaignCompleted?: Maybe<Scalars['Int']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  entityId?: Maybe<Scalars['Int']['output']>;
  hubspotId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  /** Number of campaigns that completed of this Organization */
  name?: Maybe<Scalars['String']['output']>;
  /** Total students of the organization. Inherited from NCES data and studentCount from orgs-api. */
  population?: Maybe<Scalars['Int']['output']>;
  /** @deprecated use potentialRaisedDollars */
  potentialRaised?: Maybe<Scalars['Float']['output']>;
  /** Total potential raised in dollars for this organization. Equals NCES total students multiply with National RPK. */
  potentialRaisedDollars?: Maybe<Scalars['Float']['output']>;
  /** @deprecated use saturationPercentage */
  saturationPercent?: Maybe<Scalars['Float']['output']>;
  /**
   * Saturation percentage of organization.
   * It is calculated by divide total logged-in students by NCES total students of this organization.
   */
  saturationPercentage?: Maybe<Scalars['Float']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  /** Lifetime total raised in dollars of organization. Computed from all non-upcoming campaigns. */
  totalRaised?: Maybe<Scalars['Float']['output']>;
  /** Lifetime total raised in cents of organization. */
  totalRaisedCents?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type DriveGetlistProspectsReturn = {
  __typename?: 'DriveGetlistProspectsReturn';
  offset?: Maybe<Scalars['Int']['output']>;
  results?: Maybe<Array<Maybe<HubspotProspectsDeals>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type DriveGetlistWinbackReturn = {
  __typename?: 'DriveGetlistWinbackReturn';
  offset?: Maybe<Scalars['Int']['output']>;
  results?: Maybe<Array<Maybe<WinbackDeals>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** Group Leader information in Drive */
export type DriveGroupLeader = {
  __typename?: 'DriveGroupLeader';
  /** The full name of the group leader */
  name?: Maybe<Scalars['String']['output']>;
};

export type DriveOrgListResults = {
  __typename?: 'DriveOrgListResults';
  offset?: Maybe<Scalars['Int']['output']>;
  orgs?: Maybe<Array<Maybe<DriveGetListOrganizationSummaryReturn>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type DriveOrgUserTrackingSearchInput = {
  trackingIdStartsWith?: InputMaybe<Scalars['String']['input']>;
  trackingType?: InputMaybe<Scalars['String']['input']>;
};

/** Organization used by Drive */
export type DriveOrganization = {
  __typename?: 'DriveOrganization';
  activity?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  legalName?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
};

/** List of organizations used by Drive */
export type DriveOrganizationList = {
  __typename?: 'DriveOrganizationList';
  list: Array<Maybe<DriveOrganization>>;
  offset?: Maybe<Scalars['Int']['output']>;
};

/** Statistics information related to Participants in one campaign */
export type DriveParticipantStats = {
  __typename?: 'DriveParticipantStats';
  /** Count of participants who had logged in */
  loggedInCount?: Maybe<Scalars['Int']['output']>;
  /** Count of participants with 20 emails sent */
  participantsWithAtLeastTwentyEmails?: Maybe<Scalars['Int']['output']>;
  /** Percentage of active participants of campaign. */
  participationPercent?: Maybe<Scalars['Int']['output']>;
  /** Count of participants with at least one donation */
  withAtLeastOneDonation?: Maybe<Scalars['Int']['output']>;
};

/** Information of a sms scheduled for release */
export type DrivePreloadSmsSchedule = {
  __typename?: 'DrivePreloadSmsSchedule';
  /** The date for release. Valid format is YYYY-MM-DD */
  date?: Maybe<Scalars['String']['output']>;
  /** The campaign id */
  fundraiserId?: Maybe<Scalars['Int']['output']>;
  /** The timeframe for release. */
  timeframe?: Maybe<DriveScheduleTimeFrame>;
  /** The timezone for scheduled release */
  timezone?: Maybe<Scalars['String']['output']>;
};

/** Input use for create schedule for preload email */
export type DriveScheduleInput = {
  /** Date to send out. Valid format is YYYY-MM-DD */
  date: Scalars['String']['input'];
  /** Timeframe of schedule release. */
  timeframe: DriveScheduleTimeFrame;
  /** Timezone of datetime to send out. e.g. American/New_York */
  timezone?: InputMaybe<Scalars['String']['input']>;
};

export enum DriveScheduleTimeFrame {
  Afternoon = 'AFTERNOON',
  Evening = 'EVENING',
  Morning = 'MORNING'
}

export type DriveScheduleUpdateInput = {
  /** Date to send out. Valid format is YYYY-MM-DD */
  date?: InputMaybe<Scalars['String']['input']>;
  /** Timeframe of schedule release. */
  timeframe?: InputMaybe<DriveScheduleTimeFrame>;
  /** Timezone of datetime to send out. e.g. American/New_York */
  timezone?: InputMaybe<Scalars['String']['input']>;
};

export type DriveTrackingInput = {
  trackingId: Scalars['String']['input'];
  trackingType?: InputMaybe<Scalars['String']['input']>;
  trackingValue: Scalars['String']['input'];
  trackingValueType?: InputMaybe<Scalars['String']['input']>;
};

export type DriveUser = {
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
};

export type DriveUserSearchInput = {
  isInternal?: InputMaybe<Scalars['Boolean']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type DriveUsersResult = {
  __typename?: 'DriveUsersResult';
  nextPage?: Maybe<Scalars['Boolean']['output']>;
  users?: Maybe<Array<Maybe<DriveAccountUser>>>;
};

/** Venue or location for Event used by Event Clinic */
export type DriveVenue = {
  __typename?: 'DriveVenue';
  /** Building name or Room number. e.g. `Room 330` */
  buildingOrRoom?: Maybe<Scalars['String']['output']>;
  /** City of Venue location, e.g. `Dallas` */
  city?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** Venue Name. e.g. `Hillton Convention Center`, e.g. */
  name: Scalars['String']['output'];
  /** The short-code for US States. e.g. `TX`, 'CA'  */
  state?: Maybe<Scalars['String']['output']>;
};

export type DriveVenueSearchInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
};

/** Information of a emails for a camapgin */
export type EmailsInfo = {
  __typename?: 'EmailsInfo';
  /** Total emails bounced back due to failed delivery */
  bounced?: Maybe<Scalars['Int']['output']>;
  /** Total emails loaded by particpants */
  loaded?: Maybe<Scalars['Int']['output']>;
  /** The schedule (timezone-sensitive) thatthe emails are scheduled to release */
  scheduledReleaseDate?: Maybe<ScheduledReleaseInfo>;
  /** Total emails sent */
  sent?: Maybe<Scalars['Int']['output']>;
  /** The datetime the emails were sent out  */
  sentAt?: Maybe<Scalars['DateTime']['output']>;
};

export type Error = {
  message: Scalars['String']['output'];
};

export enum EventAttendeeRequestStatus {
  NoRequest = 'NO_REQUEST',
  Processed = 'PROCESSED',
  Request = 'REQUEST'
}

export enum EventDateStatus {
  Active = 'Active',
  Closed = 'Closed',
  Upcoming = 'Upcoming'
}

export type Family = {
  __typename?: 'Family';
  children?: Maybe<Array<Maybe<User>>>;
  parents?: Maybe<Array<Maybe<User>>>;
};

export enum FinAcctStatus {
  ActionRequired = 'ACTION_REQUIRED',
  Active = 'ACTIVE',
  Approved = 'APPROVED',
  AwaitingReapproval = 'AWAITING_REAPPROVAL',
  Declined = 'DECLINED',
  Pending = 'PENDING',
  Terminated = 'TERMINATED',
  UnderReview = 'UNDER_REVIEW'
}

export enum FinAcctStripeEnv {
  Raise = 'RAISE',
  Sponsor = 'SPONSOR'
}

export type FinancialAccount = {
  __typename?: 'FinancialAccount';
  acctId?: Maybe<Scalars['ID']['output']>;
  applicationId?: Maybe<Scalars['ID']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  customerId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  kybId: Scalars['ID']['output'];
  paymentMethodId?: Maybe<Scalars['ID']['output']>;
  processor: Processor;
  recipientName?: Maybe<Scalars['String']['output']>;
  settlementMethod?: Maybe<SettlementMethod>;
  stateCode?: Maybe<StateCode>;
  status: FinAcctStatus;
  street?: Maybe<Scalars['String']['output']>;
  streetLine2?: Maybe<Scalars['String']['output']>;
  stripeEnv: FinAcctStripeEnv;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type FinancialAcctOrg = {
  __typename?: 'FinancialAcctOrg';
  acctId?: Maybe<Scalars['ID']['output']>;
  applicationId?: Maybe<Scalars['ID']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  customerId?: Maybe<Scalars['ID']['output']>;
  finAcctId?: Maybe<Scalars['ID']['output']>;
  kybId?: Maybe<Scalars['ID']['output']>;
  orgEin?: Maybe<Scalars['String']['output']>;
  orgId?: Maybe<Scalars['ID']['output']>;
  orgName?: Maybe<Scalars['String']['output']>;
  orgType?: Maybe<OrgTypeWithCampaign>;
  paymentMethodId?: Maybe<Scalars['ID']['output']>;
  processor?: Maybe<Processor>;
  recipientName?: Maybe<Scalars['String']['output']>;
  settlementMethod?: Maybe<SettlementMethod>;
  stateCode?: Maybe<StateCode>;
  status?: Maybe<FinAcctStatus>;
  street?: Maybe<Scalars['String']['output']>;
  streetLine2?: Maybe<Scalars['String']['output']>;
  stripeEnv?: Maybe<FinAcctStripeEnv>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type FinancialAcctUser = {
  __typename?: 'FinancialAcctUser';
  acctId?: Maybe<Scalars['ID']['output']>;
  applicationId?: Maybe<Scalars['ID']['output']>;
  authorizationCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  customerId?: Maybe<Scalars['ID']['output']>;
  finAcctId?: Maybe<Scalars['ID']['output']>;
  isBeneficialOwner?: Maybe<Scalars['Boolean']['output']>;
  isContact?: Maybe<Scalars['Boolean']['output']>;
  isPrincipal?: Maybe<Scalars['Boolean']['output']>;
  isRepresentative?: Maybe<Scalars['Boolean']['output']>;
  kybId?: Maybe<Scalars['ID']['output']>;
  kycId?: Maybe<Scalars['ID']['output']>;
  orgId?: Maybe<Scalars['ID']['output']>;
  personId?: Maybe<Scalars['ID']['output']>;
  processor?: Maybe<Processor>;
  recipientName?: Maybe<Scalars['String']['output']>;
  settlementMethod?: Maybe<SettlementMethod>;
  stateCode?: Maybe<StateCode>;
  status?: Maybe<FinAcctStatus>;
  street?: Maybe<Scalars['String']['output']>;
  streetLine2?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['ID']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type FundraiserCheerwall = {
  __typename?: 'FundraiserCheerwall';
  cheers?: Maybe<Array<Maybe<Cheer>>>;
  count?: Maybe<Scalars['Int']['output']>;
};

export type FundraiserCustomRewardsByLevel = {
  __typename?: 'FundraiserCustomRewardsByLevel';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  sizes?: Maybe<Scalars['String']['output']>;
};

export type FundraiserCustomRewardsData = {
  __typename?: 'FundraiserCustomRewardsData';
  rewards?: Maybe<Array<Maybe<FundraiserCustomRewardsByLevel>>>;
  rewardsLevel: Scalars['Int']['output'];
};

export type FundraiserDataByCoachJoinCode = {
  __typename?: 'FundraiserDataByCoachJoinCode';
  coachJoinCode?: Maybe<Scalars['String']['output']>;
  entityId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  joinCode?: Maybe<Scalars['String']['output']>;
  logo?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type FundraiserEntityResource = {
  __typename?: 'FundraiserEntityResource';
  entityId?: Maybe<Scalars['Int']['output']>;
};

export type FundraiserGroup = {
  __typename?: 'FundraiserGroup';
  id: Scalars['ID']['output'];
  label?: Maybe<Scalars['String']['output']>;
};

export type FundraiserParticipantPublic = {
  __typename?: 'FundraiserParticipantPublic';
  /** Raise Participant Id */
  id: Scalars['Int']['output'];
  participant?: Maybe<ParticipantPublic>;
};

/** @deprecated(reason: "Use participantsPublic instead") */
export type FundraiserParticipants = {
  __typename?: 'FundraiserParticipants';
  id: Scalars['Int']['output'];
  participant?: Maybe<User>;
};

export type FundraiserRaisedAmount = {
  __typename?: 'FundraiserRaisedAmount';
  subtotalCents?: Maybe<Scalars['Int']['output']>;
};

export type FundraiserRewardLevelsCount = {
  __typename?: 'FundraiserRewardLevelsCount';
  levelsCount?: Maybe<Scalars['Int']['output']>;
};

export type FundraiserRewardsProduct = {
  __typename?: 'FundraiserRewardsProduct';
  default?: Maybe<Scalars['Boolean']['output']>;
  fitting?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  sizes?: Maybe<Scalars['String']['output']>;
  tier?: Maybe<Scalars['Int']['output']>;
};

export type FundraiserTopDonation = {
  __typename?: 'FundraiserTopDonation';
  donorName?: Maybe<Scalars['String']['output']>;
  subtotalCents?: Maybe<Scalars['Int']['output']>;
};

export enum FundraiserUserImportType {
  CsvImport = 'csv_import',
  Rollover = 'rollover'
}

export type FundraiserUserIncentiveId = {
  __typename?: 'FundraiserUserIncentiveID';
  id?: Maybe<Scalars['Int']['output']>;
};

export type FundraiserUserRole = {
  __typename?: 'FundraiserUserRole';
  isGroupLeader?: Maybe<Scalars['Boolean']['output']>;
  isParticipant?: Maybe<Scalars['Boolean']['output']>;
  roles?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type GalleryItems = {
  __typename?: 'GalleryItems';
  campaignLogo?: Maybe<Scalars['String']['output']>;
  campaignLogoThumb?: Maybe<Scalars['String']['output']>;
  carouselItems?: Maybe<Array<Maybe<CarouselItem>>>;
};

export type GroupLeader = {
  __typename?: 'GroupLeader';
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
};

/** See CampaignMembership for details */
export type GroupLeaderCampaign = {
  __typename?: 'GroupLeaderCampaign';
  basicStatus: BasicCampaignStatus;
  donationLink: Scalars['String']['output'];
  entityId?: Maybe<Scalars['Int']['output']>;
  goalInDollars?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  isCocoach: Scalars['Boolean']['output'];
  joinCode?: Maybe<Scalars['String']['output']>;
  /** Getting Kyc status adds time to your query **expensive** */
  kyc?: Maybe<CampaignKyc>;
  name: Scalars['String']['output'];
  /** Primary group leader */
  primary?: Maybe<PrimaryGroupLeader>;
  raiseUserJoinedAt?: Maybe<Scalars['String']['output']>;
  /** Getting roster adds time to your query */
  roster?: Maybe<Array<Maybe<Roster>>>;
  status: CampaignStatus;
  teamSize?: Maybe<Scalars['Int']['output']>;
  totalCentsRaised?: Maybe<Scalars['Int']['output']>;
  whyDonateText?: Maybe<Scalars['String']['output']>;
};

export type GroupLeaderList = {
  __typename?: 'GroupLeaderList';
  count?: Maybe<Scalars['Int']['output']>;
  cursor?: Maybe<Scalars['String']['output']>;
  list: Array<Maybe<GroupLeader>>;
};

/** Hubspot Engagement entity */
export type HubspotCallEngagement = {
  __typename?: 'HubspotCallEngagement';
  contactIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  dealIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  engagementId?: Maybe<Scalars['String']['output']>;
  ownerId?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type HubspotProspectsDeals = {
  __typename?: 'HubspotProspectsDeals';
  activity?: Maybe<Scalars['String']['output']>;
  dealName?: Maybe<Scalars['String']['output']>;
  dealStage?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isProspectPinned?: Maybe<Scalars['Boolean']['output']>;
  lastActivityDate?: Maybe<Scalars['String']['output']>;
  leaderFirstName?: Maybe<Scalars['String']['output']>;
  leaderLastName?: Maybe<Scalars['String']['output']>;
  projectedLaunchDate?: Maybe<Scalars['String']['output']>;
};

export enum HubspotSortDirection {
  Ascending = 'ASCENDING',
  Descending = 'DESCENDING'
}

/** Common payload for mutations. */
export type IMutationResult = {
  __typename?: 'IMutationResult';
  data?: Maybe<Scalars['JSON']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export type InvalidCampaignPersonListData = {
  __typename?: 'InvalidCampaignPersonListData';
  email: Scalars['String']['output'];
  errorMessage?: Maybe<Scalars['String']['output']>;
};

export type InvalidCampaignSmsInviteData = {
  __typename?: 'InvalidCampaignSmsInviteData';
  errorMessage: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
};

export type InviteError = Error & {
  __typename?: 'InviteError';
  message: Scalars['String']['output'];
};

export type InviteInfo = {
  __typename?: 'InviteInfo';
  email?: Maybe<Scalars['String']['output']>;
  flow?: Maybe<Scalars['String']['output']>;
  flowParams?: Maybe<Scalars['JSONObject']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  joincode?: Maybe<Scalars['String']['output']>;
  params?: Maybe<UserInviteParams>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  requester?: Maybe<User>;
  status?: Maybe<Scalars['String']['output']>;
  type?: Maybe<InviteType>;
};

export type InviteList = {
  __typename?: 'InviteList';
  acceptedAt?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  expiresAt?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  joincode?: Maybe<Scalars['String']['output']>;
  params?: Maybe<UserInviteParams>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  requester?: Maybe<User>;
  status?: Maybe<UserInviteStatus>;
  type?: Maybe<InviteType>;
};

export type InviteMemberContactInput = {
  contact: Scalars['String']['input'];
  method: ContactMethodEnum;
  role: ChannelRolesEnum;
};

export type InviteMemberUsersInput = {
  id: Scalars['ID']['input'];
  role: ChannelRolesEnum;
};

export type InviteParentArguments = {
  joincode?: InputMaybe<Scalars['String']['input']>;
};

export type InviteSendResult = InviteError | InviteInfo;

export enum InviteType {
  Channel = 'channel',
  Org = 'org',
  Parent = 'parent'
}

export type InviteUserArguments = {
  apps?: InputMaybe<Array<InputMaybe<UserApps>>>;
  consumer?: InputMaybe<UserApps>;
  fundraiserId?: InputMaybe<Scalars['Int']['input']>;
  occupation?: InputMaybe<UserOccupation>;
  orgsAffiliation?: InputMaybe<Array<UserOrgAffiliationPayload>>;
  permissionIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  redirectUrl?: InputMaybe<Scalars['String']['input']>;
  roleIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type InviteUserOrgsArguments = {
  orgId?: InputMaybe<Scalars['String']['input']>;
  product?: InputMaybe<UserApps>;
  roleId?: InputMaybe<Scalars['String']['input']>;
};

export type InvitesListResponse = {
  __typename?: 'InvitesListResponse';
  invites?: Maybe<Array<InviteList>>;
  pagination: Pagination;
};

export type InvitesResponse = {
  __typename?: 'InvitesResponse';
  invites?: Maybe<Array<Maybe<InviteInfo>>>;
};

export enum JoinCodeType {
  GroupLeader = 'GROUP_LEADER',
  Invalid = 'INVALID',
  Participant = 'PARTICIPANT'
}

export type JoinFundraiserUserInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export enum JoinType {
  Fanx = 'FANX',
  Sma = 'SMA'
}

export type JoinedFundraiserId = {
  __typename?: 'JoinedFundraiserID';
  /** Returns the ID of the fundraiser */
  id?: Maybe<Scalars['Int']['output']>;
};

export type Leader = {
  __typename?: 'Leader';
  createdAt: Scalars['DateTime']['output'];
  isConfirmed?: Maybe<Scalars['Boolean']['output']>;
  title: TeamTitle;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type MdmDetails = DriveUser & {
  __typename?: 'MDMDetails';
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
};

export enum MatchStyle {
  Exact = 'EXACT',
  Fuzzy = 'FUZZY',
  Predictive = 'PREDICTIVE'
}

export type MessagesChannelInviteMembersResult = ChannelInviteMembers | ChannelInviteMembersError | ChannelNotFound | NotAuthenticated | NotAuthorized;

export type MessagesChannelInvitesResult = ChannelInvites | ChannelNotFound | NotAuthenticated | NotAuthorized;

export type MessagesChannelMembersResult = ChannelMembers | ChannelNotFound | NotAuthenticated;

export type MessagesChannelRemoveMembersResult = ChannelMembers | ChannelNotFound | ChannelRemoveMembersError | NotAuthenticated | NotAuthorized;

export type MessagesChannelsFilterInput = {
  orgId: Scalars['String']['input'];
};

export type MessagesMyChannelsResult = MyChannels | NotAuthenticated;

export type MessagesMyModeratedChannelsResult = MyModeratedChannels | NotAuthenticated;

export type MessagesUpsertUsersResult = ChannelUpsertUsersError | MessagesUsers | NotAuthorized;

export type MessagesUser = {
  __typename?: 'MessagesUser';
  id: Scalars['ID']['output'];
  image?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type MessagesUsers = {
  __typename?: 'MessagesUsers';
  users: Array<MessagesUser>;
};

export type MissingArguments = Error & {
  __typename?: 'MissingArguments';
  message: Scalars['String']['output'];
};

export enum ModType {
  Affiliate = 'AFFILIATE',
  Business = 'BUSINESS',
  Club = 'CLUB',
  District = 'DISTRICT',
  Individual = 'INDIVIDUAL',
  Other = 'OTHER',
  School = 'SCHOOL',
  Team = 'TEAM'
}

export type Mutation = {
  __typename?: 'Mutation';
  addParticipantGuardianDonationInviteEmailsV2: Array<ParticipantGuardianReturn>;
  campaignPersonListBulkCreate?: Maybe<CampaignPersonListEntries>;
  /** @deprecated Will be using campaignPersonListBulkCreate instead. */
  campaignPersonListCreate?: Maybe<CampaignPersonList>;
  campaignPersonListEntryDelete?: Maybe<CampaignPersonListEntryDelete>;
  /** Create saved search filters for campaigns for current user. */
  campaignSearchFilterCreate?: Maybe<CampaignSearchFilter>;
  /** Remove saved search filters for campaigns for current user. */
  campaignSearchFilterDelete?: Maybe<CampaignSearchFilter>;
  campaignSmsInviteAdd?: Maybe<CampaignSmsDataResponse>;
  campaignSmsInviteDelete: CampaignSmsInviteDeleteResponse;
  confirmPassword?: Maybe<Scalars['String']['output']>;
  /** Deletes a custom template based off ID */
  contactTemplateDelete?: Maybe<CustomContactTemplates>;
  /** Saves custom contact-template for current user */
  contactTemplateSave?: Maybe<CustomContactTemplates>;
  /** @deprecated(reason: "Use createChildFundraiserParticipantV2 instead") */
  createChildFundraiserParticipant: JoinedFundraiserId;
  createChildFundraiserParticipantV2: JoinedFundraiserId;
  createFundraiserUser?: Maybe<CreatedFundraiserUser>;
  createOtkParticipantEntry?: Maybe<CreatedOtkEntry>;
  driveAddHubspotProspectPin?: Maybe<Scalars['String']['output']>;
  driveAddWinbackPin?: Maybe<Scalars['String']['output']>;
  /** Finalize the (closed) fundraiser after campaign settled. */
  driveCampaignFinalize?: Maybe<IMutationResult>;
  /** Settle the fundraiser when campaigns start to be closed campaigns. */
  driveCampaignSettle?: Maybe<IMutationResult>;
  /** Update the fundraiser. Right now only fundraiser notes can be updated. */
  driveCampaignUpdate?: Maybe<IMutationResult>;
  /** @deprecated use driveDisableOrgActivity */
  driveDisableActivity?: Maybe<Scalars['String']['output']>;
  driveDisableOrgActivity?: Maybe<Scalars['String']['output']>;
  /** @deprecated use driveEnableOrgActivity */
  driveEnableActivity?: Maybe<Scalars['String']['output']>;
  driveEnableOrgActivity?: Maybe<Scalars['String']['output']>;
  /** Posts a message in the activity feed */
  driveEventActivityCreate?: Maybe<DriveEventActivityFeed>;
  /** Removeds an activity feed message */
  driveEventActivityRemove?: Maybe<Scalars['String']['output']>;
  /** Updates the message and feature toggle in a activity message */
  driveEventActivityUpdate?: Maybe<DriveEventActivityFeed>;
  /** Takes in 2 arguments to post the agendas */
  driveEventAgendaCreate?: Maybe<DriveEventAgendaItem>;
  /** Adds an attendee to event. */
  driveEventAttendeeAdd?: Maybe<DriveEventAttendee>;
  /** Updates attendance information of attendee. */
  driveEventAttendeeUpdate?: Maybe<DriveEventAttendee>;
  /** Removes an attendee from event. */
  driveEventAttendeesRemove?: Maybe<Scalars['Int']['output']>;
  /** Create Event (Drive Event-Tracker) */
  driveEventCreate?: Maybe<DriveEvent>;
  /** Delete Event (Drive Event-Tracker) */
  driveEventRemove?: Maybe<Scalars['String']['output']>;
  /** Update Event  (Drive Event-Tracker) */
  driveEventUpdate?: Maybe<DriveEvent>;
  /** Set tracking by specific organization current user. */
  driveOrgUserTrackingSet?: Maybe<DriveOrgUserTrackingReturn>;
  /** Cancel the scheduled preload sms */
  drivePreloadSmsScheduleCancel?: Maybe<Scalars['String']['output']>;
  /** Send out preload sms to all participants */
  drivePreloadSmsSend?: Maybe<Scalars['String']['output']>;
  drivePreloadSmsSendScheduleUpdate?: Maybe<Scalars['String']['output']>;
  driveRemoveHubspotProspectPin?: Maybe<Scalars['String']['output']>;
  driveRemoveWinbackPin?: Maybe<Scalars['String']['output']>;
  /** Resizes the uploaded attachment in S3 in Drive. */
  driveS3ImageResize?: Maybe<Scalars['String']['output']>;
  driveShareProjectedRaisedResults?: Maybe<IMutationResult>;
  /**
   * Generate S3 generate pre-signed url for Drive to upload.
   * This is used for uploading big attachments to the support ticket
   */
  driveUploadUrlCreate: Scalars['String']['output'];
  /** Associate a User with a FinancialAccount. This will also create a User Node only if they exist in User Directory but not Orgs. */
  finAcctUserAssociationCreate: MutationResponse;
  /** Add a deleted_at property to a User's FinancialAccount association. This will NOT delete the relationship. */
  finAcctUserAssociationDelete: MutationResponse;
  /**
   * Update a User's FinancialAccount association properties.
   *
   * This mutation will re-activate a user that has been deleted by default.
   *
   * **Note** There is a known bug preventing status Booleans from updating from True to False.
   */
  finAcctUserAssociationUpdate: MutationResponse;
  /**
   * Create a processor specific FinancialAccount Node that is associated with an Org.
   *
   * This requires a valid Org id and a valid KYB id
   */
  financialAcctCreate: MutationResponse;
  /** Change a FinancialAccount status to "TERMINATED". This will NOT delete the node. */
  financialAcctDelete: MutationResponse;
  /** Update a FinancialAccount properties that is already associated with an Org. */
  financialAcctUpdate: MutationResponse;
  fundraiserStoreUrlUpdate?: Maybe<UpdatedFundraiserStoreUrl>;
  /** Send out email to group-leader. Support CC emails */
  groupLeaderEmailSend?: Maybe<IMutationResult>;
  /** Update hubspot call engagement for fundraiser */
  hubspotCallEngagementToDealAdd?: Maybe<HubspotCallEngagement>;
  /** Update hubspot note engagement for fundraiser */
  hubspotNoteEngagementToDealAdd?: Maybe<HubspotCallEngagement>;
  inviteAccept?: Maybe<UserInviteResponse>;
  inviteCreate: Scalars['String']['output'];
  inviteDelete?: Maybe<Scalars['String']['output']>;
  joinFundraiserUser: JoinedFundraiserId;
  joinFundraiserUserById: JoinedFundraiserId;
  login?: Maybe<AuthChallenge>;
  logout?: Maybe<Scalars['Boolean']['output']>;
  messagesChannelAddModerators?: Maybe<ChatChannelAddModeratorsResult>;
  messagesChannelAddUsers?: Maybe<ChatChannelAddUsersResult>;
  messagesChannelCreate?: Maybe<ChatChannelCreateResult>;
  messagesChannelInviteMembers?: Maybe<MessagesChannelInviteMembersResult>;
  messagesChannelRemoveMembers?: Maybe<MessagesChannelRemoveMembersResult>;
  messagesChannelUpdate?: Maybe<ChatChannelUpdateResult>;
  messagesChannelUpdateMembers?: Maybe<ChatChannelUpdateMembersResult>;
  messagesToken?: Maybe<ChatTokenResult>;
  messagesUpsertUsers?: Maybe<MessagesUpsertUsersResult>;
  /** Create a new Affiliate. Affiliates have a parent Org, and this mutation will connect the two. */
  orgAffiliateCreate: MutationResponse;
  /** Create a new Club. Clubs do not have a parent Org. */
  orgClubCreate: MutationResponse;
  /** Create a new District. Districts do not have a parent Org. */
  orgDistrictCreate: MutationResponse;
  /**
   * Update an Org Node's label in parallel with changes being made in MOD.
   *
   * This mutation is intended to preserve User Associations and Financial Account Associations.
   */
  orgLabelUpdate: MutationResponse;
  /** Create a new Program named after a given activity and connect it to a given Org. */
  orgProgramCreate: MutationResponse;
  /** Create a new School. Schools usually have a parent District, and this mutation will optionally connect the two. */
  orgSchoolCreate: MutationResponse;
  /** Create a new Team for a given activity and connect it to a Program of the same activity under a given Org. */
  orgTeamCreate: MutationResponse;
  participantDonationInviteEdit?: Maybe<DonorEmailData>;
  participantDonationInviteEmailAdd?: Maybe<DonorEmailData>;
  participantDonationInviteRemove?: Maybe<Array<Maybe<DonationInviteRemoveData>>>;
  participantDonationInviteSMSAdd?: Maybe<DonationInviteSmsData>;
  /** This will update the emails of a Participant's Guardian Email field */
  participantGuardianEmailUpdate: ParticipantGuardianEmailUpdate;
  participantIncentiveSelectionDelete?: Maybe<DeletedIncentiveRecord>;
  participantRewardsBulkCreate?: Maybe<Array<Maybe<BulkRewards>>>;
  participantRewardsCreate?: Maybe<CreatedParticipantRewards>;
  participantSignup: Array<TransportType>;
  /** Send out emails to particpants */
  participantsEmailSend?: Maybe<IMutationResult>;
  /** Sends out texts to particpants */
  participantsTextSend?: Maybe<IMutationResult>;
  /** Reschedule time (timezone-sensitive) to send out preload email to all participants */
  preloadEmailRescheduleSend?: Maybe<PreloadEmailSchedule>;
  preloadEmailScheduleCancel?: Maybe<Scalars['Boolean']['output']>;
  /** Schedule time (timezone-sensitive) to send out preload email to all participants */
  preloadEmailScheduleSend?: Maybe<PreloadEmailSchedule>;
  /** Send out preload email to all participants */
  preloadEmailSend?: Maybe<Scalars['String']['output']>;
  /** Create multiple fundraiser users and return total number of fundraiser users created */
  raiseAdminFundraiserUsersCreateMany: Scalars['Int']['output'];
  roleCreate?: Maybe<Role>;
  roleDelete?: Maybe<Role>;
  rolePermissionsUpdate?: Maybe<Role>;
  roleUpdate?: Maybe<Role>;
  /**
   * Clone an existing Roster and attach it to a new Org. This Roster will NOT be synced or subscribed to changes to the original roster.
   *
   * This will also clone the list of Members.
   */
  rosterClone: MutationResponse;
  /** Create a new Roster for a given Org and attach a list of Members to it. */
  rosterCreate: MutationResponse;
  /** Update a Roster's properties including adding to the list of Members but NOT subtracting from it. */
  rosterUpdate: MutationResponse;
  samlCallback?: Maybe<Auth>;
  /** Update settlement note for a fundraiser */
  settlementIssuesRecord?: Maybe<Scalars['String']['output']>;
  /** Update settlement status for a fundraiser */
  settlementStatusUpdate?: Maybe<IMutationResult>;
  /** Send support-ticket email to the support team. */
  supportTicketSend?: Maybe<IMutationResult>;
  updateFundraiserUserTypeConfig?: Maybe<Scalars['Boolean']['output']>;
  updateParticipantCampaignConfiguration: ParticipantCampaignConfiguration;
  updateParticipantGroup?: Maybe<UpdatedParticipantGroupCount>;
  /** This will update the size of a Participant's OTK item */
  updateParticipantOtkSize: FundraiserUserIncentiveId;
  upgradeToManageUser?: Maybe<Scalars['String']['output']>;
  upgradeToWalletUser?: Maybe<User>;
  /**
   * Create a relationship between a User and a Program with a given activity under a given Organization.
   *
   * This will also create a User Node only if they exist in User Directory but not Orgs.
   */
  userActivityLeadsCreate: MutationResponse;
  /** Delete a relationship between a User and a Program with a given activity under a given Organization. */
  userActivityLeadsDelete: MutationResponse;
  /** Update a relationship between a User and a Program with a given activity under a given Organization. */
  userActivityLeadsUpdate: MutationResponse;
  userApiKeyCreate?: Maybe<ApiKey>;
  userApiKeyDelete: Scalars['String']['output'];
  userApiKeyUpdate?: Maybe<ApiKey>;
  /** @deprecated Please use userAssociationCreate instead */
  userAssociate: MutationResponse;
  /** Create first_seen and last_seen properties for a User and their product specific Org association. */
  userAssociationAccess: MutationResponse;
  /** Associate a User with an Org and a specific product. This will also create a User Node only if they exist in User Directory but not Orgs. */
  userAssociationCreate: MutationResponse;
  /** Add a deleted_at property to a User and their product specific Org association. This will NOT delete the relationship. */
  userAssociationDelete: MutationResponse;
  userBulkInviteSend: UserOrgInvitationResult;
  userChallengeUpdate?: Maybe<UserChallenge>;
  userChildCreate?: Maybe<User>;
  userChildUnassign: Scalars['String']['output'];
  userChildUpdate?: Maybe<User>;
  userCreate?: Maybe<User>;
  userEmailConfirm?: Maybe<Scalars['String']['output']>;
  userFittingAdd?: Maybe<UserFitting>;
  userImpersonate?: Maybe<Auth>;
  userImpersonateLogout?: Maybe<Auth>;
  /** If it is confirmed that a Group Leader is no longer affiliated with their former Org, mark their relationship with a campaign as 'no_longer_affiliated'. */
  userImpliedAffiliationDelete: MutationResponse;
  /** @deprecated use inviteCreate instead */
  userInvite: Scalars['String']['output'];
  /** @deprecated use inviteAccept instead */
  userInviteAccept?: Maybe<UserInviteResponse>;
  userInviteSend?: Maybe<InviteSendResult>;
  /** Connect a User to an Org with a specific title. This will also create a User Node only if they exist in User Directory but not Orgs. */
  userOrgAffiliationCreate: MutationResponse;
  /** Add a deleted_at timestamp to a User's title specific Org affiliation. */
  userOrgAffiliationDelete: MutationResponse;
  /**
   * Update the descripton, or isConfirmed on the affiliation between a User and an Org.
   *
   * If you want to change the title, you will need to delete the existing affiliation and create a new one.
   */
  userOrgAffiliationUpdate: MutationResponse;
  userPermissionsUpdate?: Maybe<UserPermissionsList>;
  userPhoneNumberChallengeConfirm?: Maybe<PhoneNumberChallengeConfirmation>;
  userPhoneNumberConfirm?: Maybe<Scalars['String']['output']>;
  userRefreshSession?: Maybe<Auth>;
  userResetPassword?: Maybe<UserResetPasswordResponseWithAvailableTransport>;
  userResetPasswordByAdmin?: Maybe<UserResetPasswordByAdminResponse>;
  userSignup?: Maybe<SignUpResponseWithAvailableTransport>;
  userSignupConfirm?: Maybe<Auth>;
  userSignupConfirmationResend: Array<TransportType>;
  /** Create a relationship between a User and a specific Team. This will also create a User Node only if they exist in User Directory but not Orgs. */
  userTeamLeadsCreate: MutationResponse;
  /** Add a deleted_at property to a title specific relationship between a User and Team. */
  userTeamLeadsDelete: MutationResponse;
  /** Update the isConfirmed boolean on a title specific relationship between a User and Team. */
  userTeamLeadsUpdate: MutationResponse;
  userUnassignParent?: Maybe<Scalars['String']['output']>;
  userUpdate?: Maybe<User>;
  userUpdatePassword?: Maybe<User>;
  /** Deprecated: use cron-jobs instead */
  weeklyEventEmailScheduleSend?: Maybe<IMutationResult>;
};


export type MutationAddParticipantGuardianDonationInviteEmailsV2Args = {
  emails?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationCampaignPersonListBulkCreateArgs = {
  emails: Array<Scalars['String']['input']>;
  fundraiserId: Scalars['Int']['input'];
  participantUserId: Scalars['Int']['input'];
};


export type MutationCampaignPersonListCreateArgs = {
  contextableId: Scalars['Int']['input'];
  contextableType: Scalars['String']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  participantUserId: Scalars['Int']['input'];
  personListType: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCampaignPersonListEntryDeleteArgs = {
  personListEntryId: Scalars['Int']['input'];
};


export type MutationCampaignSearchFilterCreateArgs = {
  filterCriteria?: InputMaybe<Scalars['JSON']['input']>;
  filterName?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCampaignSearchFilterDeleteArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationCampaignSmsInviteAddArgs = {
  fundraiserId: Scalars['Int']['input'];
  participantUserId: Scalars['Int']['input'];
  phoneNumbers: Array<Scalars['String']['input']>;
};


export type MutationCampaignSmsInviteDeleteArgs = {
  fundraiserId: Scalars['Int']['input'];
  smsInviteId: Scalars['Int']['input'];
};


export type MutationConfirmPasswordArgs = {
  email: Scalars['String']['input'];
  newPassword?: InputMaybe<Scalars['String']['input']>;
  verificationCode: Scalars['String']['input'];
};


export type MutationContactTemplateDeleteArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationContactTemplateSaveArgs = {
  templateMessage?: InputMaybe<Scalars['String']['input']>;
  templateName?: InputMaybe<Scalars['String']['input']>;
  templateSubject?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateChildFundraiserParticipantArgs = {
  input: CreateChildFundraiserParticipantInput;
};


export type MutationCreateChildFundraiserParticipantV2Args = {
  input: CreateChildFundraiserParticipantInput;
};


export type MutationCreateFundraiserUserArgs = {
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateOtkParticipantEntryArgs = {
  fundraiserId: Scalars['Int']['input'];
  incentiveId: Scalars['Int']['input'];
  size: Scalars['String']['input'];
};


export type MutationDriveAddHubspotProspectPinArgs = {
  dealId?: InputMaybe<Scalars['String']['input']>;
  hubspotOrgId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationDriveAddWinbackPinArgs = {
  dealId?: InputMaybe<Scalars['String']['input']>;
  hubspotOrgId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationDriveCampaignFinalizeArgs = {
  fundraiserId: Scalars['Int']['input'];
};


export type MutationDriveCampaignSettleArgs = {
  fundraiserIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};


export type MutationDriveCampaignUpdateArgs = {
  data: DriveCampaignUpdateInput;
  id: Scalars['Int']['input'];
};


export type MutationDriveDisableActivityArgs = {
  dealId?: InputMaybe<Scalars['String']['input']>;
  hubspotOrgId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationDriveDisableOrgActivityArgs = {
  activityId?: InputMaybe<Scalars['String']['input']>;
  activityType?: InputMaybe<Scalars['String']['input']>;
  campaignsCount?: InputMaybe<Scalars['Int']['input']>;
  curricularType?: InputMaybe<Scalars['String']['input']>;
  hubspotOrgId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationDriveEnableActivityArgs = {
  dealId?: InputMaybe<Scalars['String']['input']>;
  hubspotOrgId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationDriveEnableOrgActivityArgs = {
  activityId?: InputMaybe<Scalars['String']['input']>;
  hubspotOrgId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationDriveEventActivityCreateArgs = {
  eventId: Scalars['String']['input'];
  input?: InputMaybe<DriveEventActivityInput>;
};


export type MutationDriveEventActivityRemoveArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDriveEventActivityUpdateArgs = {
  id: Scalars['ID']['input'];
  input: DriveEventActivityInput;
};


export type MutationDriveEventAgendaCreateArgs = {
  eventId: Scalars['ID']['input'];
  input: DriveEventAgendaInput;
};


export type MutationDriveEventAttendeeAddArgs = {
  eventId: Scalars['ID']['input'];
  input: DriveEventAttendeeInput;
  update?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationDriveEventAttendeeUpdateArgs = {
  eventId: Scalars['ID']['input'];
  input: DriveEventAttendeeInput;
  userId: Scalars['ID']['input'];
};


export type MutationDriveEventAttendeesRemoveArgs = {
  eventId: Scalars['ID']['input'];
  userIds: Array<Scalars['String']['input']>;
};


export type MutationDriveEventCreateArgs = {
  input: DriveEventInput;
};


export type MutationDriveEventRemoveArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDriveEventUpdateArgs = {
  id: Scalars['ID']['input'];
  input: DriveEventInput;
};


export type MutationDriveOrgUserTrackingSetArgs = {
  input?: InputMaybe<DriveTrackingInput>;
  orgId: Scalars['String']['input'];
};


export type MutationDrivePreloadSmsScheduleCancelArgs = {
  fundraiserId: Scalars['Int']['input'];
};


export type MutationDrivePreloadSmsSendArgs = {
  fundraiserId: Scalars['Int']['input'];
  schedule?: InputMaybe<DriveScheduleInput>;
};


export type MutationDrivePreloadSmsSendScheduleUpdateArgs = {
  fundraiserId: Scalars['Int']['input'];
  schedule: DriveScheduleUpdateInput;
};


export type MutationDriveRemoveHubspotProspectPinArgs = {
  dealId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationDriveRemoveWinbackPinArgs = {
  dealId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationDriveS3ImageResizeArgs = {
  fileUploaded?: InputMaybe<Scalars['String']['input']>;
};


export type MutationDriveShareProjectedRaisedResultsArgs = {
  activityType: Scalars['String']['input'];
  earlyAccessApprovedFundsMax: Scalars['Int']['input'];
  earlyAccessApprovedFundsMin: Scalars['Int']['input'];
  location: Scalars['String']['input'];
  participantCount: Scalars['Int']['input'];
  projectedRaisedMax: Scalars['Int']['input'];
  projectedRaisedMin: Scalars['Int']['input'];
  to: Scalars['String']['input'];
};


export type MutationDriveUploadUrlCreateArgs = {
  fileName: Scalars['String']['input'];
};


export type MutationFinAcctUserAssociationCreateArgs = {
  finAcctId: Scalars['ID']['input'];
  isBeneficialOwner?: InputMaybe<Scalars['Boolean']['input']>;
  isContact?: InputMaybe<Scalars['Boolean']['input']>;
  isPrincipal?: InputMaybe<Scalars['Boolean']['input']>;
  isRepresentative?: InputMaybe<Scalars['Boolean']['input']>;
  kycId?: InputMaybe<Scalars['ID']['input']>;
  personId?: InputMaybe<Scalars['ID']['input']>;
  userId: Scalars['ID']['input'];
};


export type MutationFinAcctUserAssociationDeleteArgs = {
  finAcctId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationFinAcctUserAssociationUpdateArgs = {
  finAcctId: Scalars['ID']['input'];
  isBeneficialOwner?: InputMaybe<Scalars['Boolean']['input']>;
  isContact?: InputMaybe<Scalars['Boolean']['input']>;
  isPrincipal?: InputMaybe<Scalars['Boolean']['input']>;
  isRepresentative?: InputMaybe<Scalars['Boolean']['input']>;
  kycId?: InputMaybe<Scalars['ID']['input']>;
  personId?: InputMaybe<Scalars['ID']['input']>;
  userId: Scalars['ID']['input'];
};


export type MutationFinancialAcctCreateArgs = {
  acctId?: InputMaybe<Scalars['ID']['input']>;
  applicationId?: InputMaybe<Scalars['ID']['input']>;
  customerId?: InputMaybe<Scalars['ID']['input']>;
  kybId: Scalars['ID']['input'];
  orgId: Scalars['ID']['input'];
  processor: Processor;
  status?: FinAcctStatus;
  stripeEnv?: FinAcctStripeEnv;
};


export type MutationFinancialAcctDeleteArgs = {
  finAcctId: Scalars['ID']['input'];
};


export type MutationFinancialAcctUpdateArgs = {
  acctId?: InputMaybe<Scalars['ID']['input']>;
  applicationId?: InputMaybe<Scalars['ID']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  customerId?: InputMaybe<Scalars['ID']['input']>;
  finAcctId: Scalars['ID']['input'];
  kybId?: InputMaybe<Scalars['ID']['input']>;
  orgId: Scalars['ID']['input'];
  paymentMethodId?: InputMaybe<Scalars['ID']['input']>;
  recipientName?: InputMaybe<Scalars['String']['input']>;
  settlementMethod?: InputMaybe<SettlementMethod>;
  stateCode?: InputMaybe<StateCode>;
  status?: InputMaybe<FinAcctStatus>;
  street?: InputMaybe<Scalars['String']['input']>;
  streetLine2?: InputMaybe<Scalars['String']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};


export type MutationFundraiserStoreUrlUpdateArgs = {
  fundraiserId: Scalars['Int']['input'];
  storeUrl?: InputMaybe<Scalars['String']['input']>;
};


export type MutationGroupLeaderEmailSendArgs = {
  ccEmails?: InputMaybe<Array<Scalars['String']['input']>>;
  fundraiserId: Scalars['Int']['input'];
  groupLeaderEmail: Scalars['String']['input'];
  message: Scalars['String']['input'];
  subject: Scalars['String']['input'];
};


export type MutationHubspotCallEngagementToDealAddArgs = {
  fundraiserId: Scalars['Int']['input'];
};


export type MutationHubspotNoteEngagementToDealAddArgs = {
  fundraiserId: Scalars['Int']['input'];
};


export type MutationInviteAcceptArgs = {
  inviteId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationInviteCreateArgs = {
  email: Scalars['String']['input'];
  parent?: InputMaybe<InviteParentArguments>;
  sendEmail?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<InviteType>;
  user?: InputMaybe<InviteUserArguments>;
};


export type MutationInviteDeleteArgs = {
  inviteId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationJoinFundraiserUserArgs = {
  joinCode: Scalars['String']['input'];
  joinType?: InputMaybe<JoinType>;
  role: CampaignMemberAssociation;
};


export type MutationJoinFundraiserUserByIdArgs = {
  fundraiserId: Scalars['Int']['input'];
  importType?: InputMaybe<FundraiserUserImportType>;
  role: CampaignMemberAssociation;
  ssoId: Scalars['String']['input'];
  user: JoinFundraiserUserInput;
};


export type MutationLoginArgs = {
  consumer?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationLogoutArgs = {
  consumer?: InputMaybe<Scalars['String']['input']>;
};


export type MutationMessagesChannelAddModeratorsArgs = {
  id: Scalars['String']['input'];
  moderatorIds: Array<Scalars['String']['input']>;
};


export type MutationMessagesChannelAddUsersArgs = {
  id: Scalars['String']['input'];
  userIds: Array<Scalars['String']['input']>;
};


export type MutationMessagesChannelCreateArgs = {
  assignmentId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  moderatorIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name: Scalars['String']['input'];
  type: ChannelTypesEnum;
  userIds: Array<InputMaybe<Scalars['String']['input']>>;
};


export type MutationMessagesChannelInviteMembersArgs = {
  contacts: Array<InviteMemberContactInput>;
  id: Scalars['ID']['input'];
  users: Array<InviteMemberUsersInput>;
};


export type MutationMessagesChannelRemoveMembersArgs = {
  id: Scalars['String']['input'];
  userIds: Array<Scalars['String']['input']>;
};


export type MutationMessagesChannelUpdateArgs = {
  channel?: InputMaybe<ChannelUpdateInput>;
  id: Scalars['String']['input'];
};


export type MutationMessagesChannelUpdateMembersArgs = {
  assignment?: InputMaybe<AssignmentInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  moderatorIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  userIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationMessagesUpsertUsersArgs = {
  userIds: Array<Scalars['String']['input']>;
};


export type MutationOrgAffiliateCreateArgs = {
  city: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  einNumber: Scalars['String']['input'];
  formationYear?: InputMaybe<Scalars['Int']['input']>;
  hubspotId?: InputMaybe<Scalars['String']['input']>;
  legalName: Scalars['String']['input'];
  name: Scalars['String']['input'];
  parentOrgId?: InputMaybe<Scalars['ID']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  stateCode: StateCode;
  streetAddress: Scalars['String']['input'];
  type: OrgType;
  url?: InputMaybe<Scalars['String']['input']>;
  zipCode: Scalars['String']['input'];
};


export type MutationOrgClubCreateArgs = {
  city: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  einNumber: Scalars['String']['input'];
  formationYear?: InputMaybe<Scalars['Int']['input']>;
  hubspotId?: InputMaybe<Scalars['String']['input']>;
  legalName: Scalars['String']['input'];
  name: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  stateCode: StateCode;
  streetAddress: Scalars['String']['input'];
  url?: InputMaybe<Scalars['String']['input']>;
  zipCode: Scalars['String']['input'];
};


export type MutationOrgDistrictCreateArgs = {
  city: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  einNumber: Scalars['String']['input'];
  formationYear?: InputMaybe<Scalars['Int']['input']>;
  hubspotId?: InputMaybe<Scalars['String']['input']>;
  legalName: Scalars['String']['input'];
  name: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  stateCode: StateCode;
  streetAddress: Scalars['String']['input'];
  url?: InputMaybe<Scalars['String']['input']>;
  zipCode: Scalars['String']['input'];
};


export type MutationOrgLabelUpdateArgs = {
  auxiliary_type?: InputMaybe<ModType>;
  label: OrgType;
  modId: Scalars['ID']['input'];
  parentModId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationOrgProgramCreateArgs = {
  activity: ProgramActivity;
  orgId: Scalars['ID']['input'];
};


export type MutationOrgSchoolCreateArgs = {
  city: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  einNumber: Scalars['String']['input'];
  formationYear?: InputMaybe<Scalars['Int']['input']>;
  hubspotId?: InputMaybe<Scalars['String']['input']>;
  legalName: Scalars['String']['input'];
  name: Scalars['String']['input'];
  ncesId?: InputMaybe<Scalars['String']['input']>;
  parentDistrictId?: InputMaybe<Scalars['ID']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  stateCode: StateCode;
  streetAddress: Scalars['String']['input'];
  url?: InputMaybe<Scalars['String']['input']>;
  zipCode: Scalars['String']['input'];
};


export type MutationOrgTeamCreateArgs = {
  activity: ProgramActivity;
  name: Scalars['String']['input'];
  orgId: Scalars['ID']['input'];
  teamAge: TeamAge;
  teamGender: TeamGender;
  teamGroupType: TeamGroupType;
  teamSize?: InputMaybe<Scalars['Int']['input']>;
  teamTier: TeamTier;
};


export type MutationParticipantDonationInviteEditArgs = {
  campaignID: Scalars['String']['input'];
  donationInviteID: Scalars['String']['input'];
  newEmail: Scalars['String']['input'];
};


export type MutationParticipantDonationInviteEmailAddArgs = {
  campaignID: Scalars['String']['input'];
  email: Scalars['String']['input'];
};


export type MutationParticipantDonationInviteRemoveArgs = {
  email: Scalars['String']['input'];
  fundraiserId: Scalars['String']['input'];
  personListEntryId: Scalars['String']['input'];
};


export type MutationParticipantDonationInviteSmsAddArgs = {
  campaignID: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
};


export type MutationParticipantGuardianEmailUpdateArgs = {
  guardianEmail: Scalars['String']['input'];
  secondGuardianEmail?: InputMaybe<Scalars['String']['input']>;
};


export type MutationParticipantIncentiveSelectionDeleteArgs = {
  fundraiserId: Scalars['ID']['input'];
  fundraiserUserIncentiveId: Scalars['ID']['input'];
};


export type MutationParticipantRewardsBulkCreateArgs = {
  fundraiserId: Scalars['Int']['input'];
  rewards: Array<ParticipantRewardsInput>;
};


export type MutationParticipantRewardsCreateArgs = {
  fundraiserId: Scalars['Int']['input'];
  incentiveId: Scalars['Int']['input'];
  size: Scalars['String']['input'];
  tier: Scalars['Int']['input'];
};


export type MutationParticipantSignupArgs = {
  apps?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
};


export type MutationParticipantsEmailSendArgs = {
  fundraiserId: Scalars['Int']['input'];
  message: Scalars['String']['input'];
  subject: Scalars['String']['input'];
};


export type MutationParticipantsTextSendArgs = {
  fundraiserId: Scalars['Int']['input'];
  message: Scalars['String']['input'];
};


export type MutationPreloadEmailRescheduleSendArgs = {
  dateTime?: InputMaybe<Scalars['DateTime']['input']>;
  fundraiserId: Scalars['Int']['input'];
  offsetInSeconds?: InputMaybe<Scalars['Int']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
};


export type MutationPreloadEmailScheduleCancelArgs = {
  fundraiserId: Scalars['Int']['input'];
};


export type MutationPreloadEmailScheduleSendArgs = {
  dateTime: Scalars['DateTime']['input'];
  fundraiserId: Scalars['Int']['input'];
  timezone: Scalars['String']['input'];
};


export type MutationPreloadEmailSendArgs = {
  fundraiserId: Scalars['Int']['input'];
};


export type MutationRaiseAdminFundraiserUsersCreateManyArgs = {
  input: RaiseFundraiserUsersCreateManyInput;
};


export type MutationRoleCreateArgs = {
  data: RoleArguments;
};


export type MutationRoleDeleteArgs = {
  id: Scalars['String']['input'];
};


export type MutationRolePermissionsUpdateArgs = {
  permissionIds: Array<Scalars['String']['input']>;
  roleId: Scalars['String']['input'];
};


export type MutationRoleUpdateArgs = {
  data: RoleArguments;
  id: Scalars['String']['input'];
};


export type MutationRosterCloneArgs = {
  input: RosterCloneInput;
};


export type MutationRosterCreateArgs = {
  input?: InputMaybe<RosterCreateInput>;
};


export type MutationRosterUpdateArgs = {
  input: RosterUpdateInput;
};


export type MutationSamlCallbackArgs = {
  actualEmail?: InputMaybe<Scalars['String']['input']>;
  apps?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  consumer?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSettlementIssuesRecordArgs = {
  fundraiserId: Scalars['Int']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSettlementStatusUpdateArgs = {
  fundraiserId: Scalars['Int']['input'];
  settlementStatus?: InputMaybe<SettlementStatus>;
};


export type MutationSupportTicketSendArgs = {
  attachments?: InputMaybe<Array<DriveFileInput>>;
  ccEmails?: InputMaybe<Array<Scalars['String']['input']>>;
  deviceInfo?: InputMaybe<Scalars['String']['input']>;
  fundraiserId?: InputMaybe<Scalars['Int']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  subject: Scalars['String']['input'];
  supportTicketInfo?: InputMaybe<Array<DriveFieldInput>>;
  templateId?: InputMaybe<Scalars['String']['input']>;
  to: Support_Ticket_Department;
};


export type MutationUpdateFundraiserUserTypeConfigArgs = {
  id: Scalars['Int']['input'];
  userTypeConfig: UserTypeConfiguration;
};


export type MutationUpdateParticipantCampaignConfigurationArgs = {
  field?: InputMaybe<ParticipantCampaignConfigInput>;
  id: Scalars['ID']['input'];
};


export type MutationUpdateParticipantGroupArgs = {
  fundraiserId: Scalars['Int']['input'];
  personListsId: Scalars['String']['input'];
  userId: Scalars['Int']['input'];
};


export type MutationUpdateParticipantOtkSizeArgs = {
  fundraiserUserIncentiveId: Scalars['Int']['input'];
  size: Scalars['String']['input'];
};


export type MutationUpgradeToWalletUserArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUserActivityLeadsCreateArgs = {
  activity: ProgramActivity;
  isConfirmed?: InputMaybe<Scalars['Boolean']['input']>;
  orgId: Scalars['ID']['input'];
  title: TeamTitle;
  userId: Scalars['ID']['input'];
};


export type MutationUserActivityLeadsDeleteArgs = {
  activity: ProgramActivity;
  orgId: Scalars['ID']['input'];
  title: TeamTitle;
  userId: Scalars['ID']['input'];
};


export type MutationUserActivityLeadsUpdateArgs = {
  activity: ProgramActivity;
  isConfirmed: Scalars['Boolean']['input'];
  orgId: Scalars['ID']['input'];
  title: TeamTitle;
  userId: Scalars['ID']['input'];
};


export type MutationUserApiKeyCreateArgs = {
  expiresPeriod?: InputMaybe<Scalars['String']['input']>;
  maxRps?: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  permissionIds: Array<Scalars['String']['input']>;
};


export type MutationUserApiKeyDeleteArgs = {
  id: Scalars['String']['input'];
};


export type MutationUserApiKeyUpdateArgs = {
  id: Scalars['String']['input'];
  maxRps?: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  permissionIds: Array<Scalars['String']['input']>;
};


export type MutationUserAssociateArgs = {
  orgId: Scalars['ID']['input'];
  product: Product;
  roleId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationUserAssociationAccessArgs = {
  orgId: Scalars['ID']['input'];
  product: Product;
  userId: Scalars['ID']['input'];
};


export type MutationUserAssociationCreateArgs = {
  orgId: Scalars['ID']['input'];
  product: Product;
  roleId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationUserAssociationDeleteArgs = {
  orgId: Scalars['ID']['input'];
  product: Product;
  userId: Scalars['ID']['input'];
};


export type MutationUserBulkInviteSendArgs = {
  inviteType: InviteType;
  orgPayload: Array<UserOrgInvitationPayload>;
};


export type MutationUserChallengeUpdateArgs = {
  id: Scalars['String']['input'];
  status: UserChallengeStatus;
};


export type MutationUserChildCreateArgs = {
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
};


export type MutationUserChildUnassignArgs = {
  id: Scalars['String']['input'];
};


export type MutationUserChildUpdateArgs = {
  firstName: Scalars['String']['input'];
  id: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  profilePicture?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUserCreateArgs = {
  apps?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  email: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  occupation?: InputMaybe<UserOccupation>;
  parentId?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
  profilePicture?: InputMaybe<Scalars['String']['input']>;
  snapRaiseId?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationUserEmailConfirmArgs = {
  code: Scalars['String']['input'];
};


export type MutationUserFittingAddArgs = {
  fitting: UserFittingPreference;
};


export type MutationUserImpersonateArgs = {
  userId: Scalars['String']['input'];
};


export type MutationUserImpliedAffiliationDeleteArgs = {
  orgId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationUserInviteArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  joincode?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUserInviteAcceptArgs = {
  inviteId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUserInviteSendArgs = {
  channelInvite?: InputMaybe<ChannelInviteInput>;
  parentInvite?: InputMaybe<ParentInviteInput>;
  type: InviteType;
};


export type MutationUserOrgAffiliationCreateArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  isConfirmed?: InputMaybe<Scalars['Boolean']['input']>;
  orgId: Scalars['ID']['input'];
  title: AffiliationTitle;
  userId: Scalars['ID']['input'];
};


export type MutationUserOrgAffiliationDeleteArgs = {
  orgId: Scalars['ID']['input'];
  title: AffiliationTitle;
  userId: Scalars['ID']['input'];
};


export type MutationUserOrgAffiliationUpdateArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  isConfirmed?: InputMaybe<Scalars['Boolean']['input']>;
  orgId: Scalars['ID']['input'];
  title: AffiliationTitle;
  userId: Scalars['ID']['input'];
};


export type MutationUserPermissionsUpdateArgs = {
  negativePermissions: Array<UserPermissionAssignment>;
  permissions: Array<UserPermissionAssignment>;
  roleIds: Array<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
};


export type MutationUserPhoneNumberChallengeConfirmArgs = {
  challengeId: Scalars['String']['input'];
  code: Scalars['String']['input'];
};


export type MutationUserPhoneNumberConfirmArgs = {
  code: Scalars['String']['input'];
};


export type MutationUserRefreshSessionArgs = {
  refreshToken?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUserResetPasswordArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  transport?: InputMaybe<TransportEnum>;
  withLink?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationUserResetPasswordByAdminArgs = {
  forcePasswordResetChallenge?: InputMaybe<Scalars['Boolean']['input']>;
  password: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type MutationUserSignupArgs = {
  apps?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  consumer?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  inviteId?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  occupation?: InputMaybe<UserOccupation>;
  password: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
  profilePicture?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUserSignupConfirmArgs = {
  code: Scalars['String']['input'];
  consumer?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  skip?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationUserSignupConfirmationResendArgs = {
  email: Scalars['String']['input'];
  transport?: InputMaybe<TransportEnum>;
};


export type MutationUserTeamLeadsCreateArgs = {
  isConfirmed?: InputMaybe<Scalars['Boolean']['input']>;
  teamId: Scalars['ID']['input'];
  title: TeamTitle;
  userId: Scalars['ID']['input'];
};


export type MutationUserTeamLeadsDeleteArgs = {
  teamId: Scalars['ID']['input'];
  title: TeamTitle;
  userId: Scalars['ID']['input'];
};


export type MutationUserTeamLeadsUpdateArgs = {
  isConfirmed: Scalars['Boolean']['input'];
  teamId: Scalars['ID']['input'];
  title: TeamTitle;
  userId: Scalars['ID']['input'];
};


export type MutationUserUnassignParentArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  parentId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUserUpdateArgs = {
  apps?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  hsGradYear?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isConfirmed?: InputMaybe<Scalars['Boolean']['input']>;
  isDisabled?: InputMaybe<Scalars['Boolean']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  occupation?: InputMaybe<UserOccupation>;
  password?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  profilePicture?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUserUpdatePasswordArgs = {
  email: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
  oldPassword: Scalars['String']['input'];
};

export type MutationError = {
  __typename?: 'MutationError';
  message: Scalars['String']['output'];
  type?: Maybe<Scalars['String']['output']>;
};

export type MutationResponse = {
  __typename?: 'MutationResponse';
  errors: Array<Maybe<MutationError>>;
  properties?: Maybe<OrgsProperties>;
  success: Scalars['Boolean']['output'];
};

export type MyChannels = {
  __typename?: 'MyChannels';
  channels: Array<Channel>;
  count?: Maybe<Scalars['Int']['output']>;
};

export type MyModeratedChannels = {
  __typename?: 'MyModeratedChannels';
  channels: Array<Channel>;
  count?: Maybe<Scalars['Int']['output']>;
};

export type NotAuthenticated = Error & {
  __typename?: 'NotAuthenticated';
  message: Scalars['String']['output'];
};

export type NotAuthorized = Error & {
  __typename?: 'NotAuthorized';
  message: Scalars['String']['output'];
};

export type Org = {
  __typename?: 'Org';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  fields?: Maybe<Scalars['JSONObject']['output']>;
  financialAccounts?: Maybe<Array<FinancialAccount>>;
  id: Scalars['ID']['output'];
  lastMigrated?: Maybe<Scalars['DateTime']['output']>;
  /** @deprecated Please use lastMigrated instead */
  migrated?: Maybe<Scalars['DateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  parent?: Maybe<Org>;
  /** @deprecated Please use parent.id instead */
  parentId?: Maybe<Scalars['String']['output']>;
  stores?: Maybe<Array<Store>>;
  type: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};


export type OrgFieldsArgs = {
  names?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type OrgAffiliation = {
  __typename?: 'OrgAffiliation';
  affiliations: Array<UserAffiliation>;
  org: Org;
};

export type OrgCampaignAffiliation = {
  __typename?: 'OrgCampaignAffiliation';
  campaigns: Array<Org>;
  org: Org;
};

export type OrgCampaignIds = {
  __typename?: 'OrgCampaignIds';
  campaignIds: Array<Scalars['ID']['output']>;
  legacyRaiseIds: Array<Scalars['Int']['output']>;
  role?: Maybe<Scalars['ID']['output']>;
  rootOrgId: Scalars['ID']['output'];
};

export type OrgInviteArguments = {
  orgId: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type OrgStaffMember = {
  __typename?: 'OrgStaffMember';
  orgAffiliations: Array<UserAffiliation>;
  orgId: Scalars['ID']['output'];
  programsLed: Array<ProgramWithTitle>;
  teamsLed: Array<TeamWithTitle>;
  user: UserNode;
};

export enum OrgType {
  Booster = 'BOOSTER',
  Business = 'BUSINESS',
  Club = 'CLUB',
  District = 'DISTRICT',
  Foundation = 'FOUNDATION',
  Program = 'PROGRAM',
  Pta = 'PTA',
  School = 'SCHOOL',
  Shellschool = 'SHELLSCHOOL',
  Team = 'TEAM'
}

export enum OrgTypeWithCampaign {
  Booster = 'BOOSTER',
  Business = 'BUSINESS',
  Campaign = 'CAMPAIGN',
  Club = 'CLUB',
  District = 'DISTRICT',
  Foundation = 'FOUNDATION',
  Program = 'PROGRAM',
  Pta = 'PTA',
  School = 'SCHOOL',
  Shellschool = 'SHELLSCHOOL',
  Team = 'TEAM'
}

export type OrgsProperties = {
  __typename?: 'OrgsProperties';
  acctId?: Maybe<Scalars['ID']['output']>;
  applicationId?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  customerId?: Maybe<Scalars['ID']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  finAcctId?: Maybe<Scalars['ID']['output']>;
  firstSeen?: Maybe<Scalars['DateTime']['output']>;
  internal?: Maybe<Scalars['Boolean']['output']>;
  isBeneficialOwner?: Maybe<Scalars['Boolean']['output']>;
  isContact?: Maybe<Scalars['Boolean']['output']>;
  isPrincipal?: Maybe<Scalars['Boolean']['output']>;
  isRepresentative?: Maybe<Scalars['Boolean']['output']>;
  kybId?: Maybe<Scalars['ID']['output']>;
  kycId?: Maybe<Scalars['ID']['output']>;
  lastMigrated?: Maybe<Scalars['DateTime']['output']>;
  lastSeen?: Maybe<Scalars['DateTime']['output']>;
  memberCount?: Maybe<Scalars['Int']['output']>;
  /** @deprecated Please use lastMigrated instead */
  migrated?: Maybe<Scalars['DateTime']['output']>;
  orgId?: Maybe<Scalars['ID']['output']>;
  parentId?: Maybe<Scalars['ID']['output']>;
  personId?: Maybe<Scalars['ID']['output']>;
  processor?: Maybe<Processor>;
  programId?: Maybe<Scalars['ID']['output']>;
  roleId?: Maybe<Scalars['ID']['output']>;
  rosterId?: Maybe<Scalars['ID']['output']>;
  status?: Maybe<FinAcctStatus>;
  type?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  userId?: Maybe<Scalars['ID']['output']>;
};

export type OrgsRoster = {
  __typename?: 'OrgsRoster';
  campaign?: Maybe<Org>;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastMigrated?: Maybe<Scalars['DateTime']['output']>;
  members: Array<UserNode>;
  name: Scalars['String']['output'];
  org?: Maybe<Org>;
  product: Array<RosterCreator>;
  startYear: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  userDirectoryMembers: Array<UserPublic>;
};

export type OrgsSearchPaginationInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type OrgsSearchResponse = {
  __typename?: 'OrgsSearchResponse';
  hasNext?: Maybe<Scalars['Boolean']['output']>;
  hasPrevious?: Maybe<Scalars['Boolean']['output']>;
  orgs?: Maybe<Array<Maybe<Org>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type OrgsSearchWhereInput = {
  /** List of organization ids to filter from. */
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** The string to search in name of organization. */
  nameIncludes?: InputMaybe<Scalars['String']['input']>;
  /** Organization type. Valid values are "Booster" | "Club" | "District" | "Program" | "School".  */
  orgTypes?: InputMaybe<Array<OrgType>>;
  /** Allow to search any properties via JSON query. The key will auto convert to snake_case. */
  properties?: InputMaybe<Scalars['JSON']['input']>;
};

export type OtkFundraiserProduct = {
  __typename?: 'OtkFundraiserProduct';
  /** This otk item image will include the fundraiser logo. */
  dynamicImage?: Maybe<Scalars['String']['output']>;
  incentives?: Maybe<OtkProduct>;
};

export type OtkParticipantData = {
  __typename?: 'OtkParticipantData';
  id: Scalars['ID']['output'];
  incentives?: Maybe<OtkProduct>;
  size?: Maybe<Scalars['String']['output']>;
};

export type OtkProduct = {
  __typename?: 'OtkProduct';
  description?: Maybe<Scalars['String']['output']>;
  fitting?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** This is the base image for the otk item. It will not include the fundraiser logo. */
  image?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  priceCents?: Maybe<Scalars['Int']['output']>;
  productType?: Maybe<Scalars['String']['output']>;
  sizes?: Maybe<Scalars['String']['output']>;
};

export type Pagination = {
  __typename?: 'Pagination';
  currentPage: Scalars['Int']['output'];
  itemCount: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
};

export type ParentInviteInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  joincode: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type Participant = {
  __typename?: 'Participant';
  campaignId?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastLogin?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['String']['output']>;
};

/** See CampaignMembership for details */
export type ParticipantCampaign = {
  __typename?: 'ParticipantCampaign';
  areDonationInviteEmailsReleased: Scalars['Boolean']['output'];
  basicStatus: BasicCampaignStatus;
  campaignDates?: Maybe<CampaignDates>;
  campaignGoal?: Maybe<Scalars['Int']['output']>;
  donationsRaised?: Maybe<ParticipantDonationsRaised>;
  /** Getting donor email addresses adds time to your query */
  donorEmailData?: Maybe<Array<Maybe<DonorEmailData>>>;
  donorPersonListEntries?: Maybe<Array<Maybe<DonorPersonListEntry>>>;
  /** Getting donor phone numbers adds time to your query */
  donorPhoneNumbers?: Maybe<Array<Maybe<DonationInviteSmsData>>>;
  fundraiserRewardLevelsCount?: Maybe<FundraiserRewardLevelsCount>;
  galleryItems?: Maybe<GalleryItems>;
  group?: Maybe<ParticipantGroup>;
  hasCustomRewards?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  isCoparticipant?: Maybe<Scalars['Boolean']['output']>;
  isGuardianLedCampaign: Scalars['Boolean']['output'];
  isOTKEnabled?: Maybe<Scalars['Boolean']['output']>;
  isRewardsEnabled?: Maybe<Scalars['Boolean']['output']>;
  isTopEarner: Scalars['Boolean']['output'];
  joinCode?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  newStack_participantFundraiserLink?: Maybe<Scalars['String']['output']>;
  otk?: Maybe<Array<Maybe<OtkParticipantData>>>;
  participantGoal?: Maybe<Scalars['Int']['output']>;
  participantGuardianEmails?: Maybe<ParticipantGuardianEmail>;
  participantRewardsData?: Maybe<Array<Maybe<ParticipantRewardsData>>>;
  primaryColor: Scalars['String']['output'];
  raiseUserId?: Maybe<Scalars['Int']['output']>;
  raiseUserJoinedAt?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  /** Datetime-ISO format for sms invites released */
  smsInvitesReleasedAt?: Maybe<Scalars['String']['output']>;
  status: CampaignStatus;
  userFitting?: Maybe<UserFitting>;
};

export type ParticipantCampaignConfigInput = {
  autoImportEmails?: InputMaybe<ParticipantCampaignConfigStates>;
  autoImportTexts?: InputMaybe<ParticipantCampaignConfigStates>;
  giftShop?: InputMaybe<ParticipantCampaignConfigStates>;
  guardianSetup?: InputMaybe<ParticipantCampaignConfigStates>;
  profileSetup?: InputMaybe<ParticipantCampaignConfigStates>;
  rewards?: InputMaybe<ParticipantCampaignConfigStates>;
};

export enum ParticipantCampaignConfigStates {
  Completed = 'COMPLETED',
  NotApplicable = 'NOT_APPLICABLE',
  NotCompleted = 'NOT_COMPLETED',
  Skipped = 'SKIPPED'
}

export type ParticipantCheer = {
  __typename?: 'ParticipantCheer';
  anonymous?: Maybe<Scalars['Boolean']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  donorMessage?: Maybe<Scalars['String']['output']>;
  donorName?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  images?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  lastName?: Maybe<Scalars['String']['output']>;
  subtotalCents?: Maybe<Scalars['Int']['output']>;
};

export type ParticipantCheerwall = {
  __typename?: 'ParticipantCheerwall';
  cheers?: Maybe<Array<Maybe<ParticipantCheer>>>;
  count?: Maybe<Scalars['Int']['output']>;
};

export type ParticipantDonationsRaised = {
  __typename?: 'ParticipantDonationsRaised';
  numberOfDonations: Scalars['Int']['output'];
  subtotalCents: Scalars['Int']['output'];
};

export type ParticipantGroup = {
  __typename?: 'ParticipantGroup';
  id?: Maybe<Scalars['ID']['output']>;
  label?: Maybe<Scalars['String']['output']>;
};

export type ParticipantGuardianDonorEntry = {
  __typename?: 'ParticipantGuardianDonorEntry';
  email?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  fundraiserId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type ParticipantGuardianEmail = {
  __typename?: 'ParticipantGuardianEmail';
  guardianEmail?: Maybe<Scalars['String']['output']>;
  secondaryGuardianEmail?: Maybe<Scalars['String']['output']>;
};

export type ParticipantGuardianEmailUpdate = {
  __typename?: 'ParticipantGuardianEmailUpdate';
  id?: Maybe<Scalars['Int']['output']>;
};

export type ParticipantGuardianFundraiser = {
  __typename?: 'ParticipantGuardianFundraiser';
  id: Scalars['ID']['output'];
  logo?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  participants?: Maybe<Array<Maybe<ParticipantUser>>>;
  slug: Scalars['String']['output'];
  status: BasicCampaignStatus;
};

export type ParticipantGuardianReturn = {
  __typename?: 'ParticipantGuardianReturn';
  entry?: Maybe<Array<ParticipantGuardianDonorEntry>>;
};

export type ParticipantList = {
  __typename?: 'ParticipantList';
  count?: Maybe<Scalars['Int']['output']>;
  cursor?: Maybe<Scalars['String']['output']>;
  list: Array<Maybe<Participant>>;
};

export type ParticipantPublic = {
  __typename?: 'ParticipantPublic';
  firstName?: Maybe<Scalars['String']['output']>;
  /** User directory id */
  id?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  profilePicture?: Maybe<Scalars['String']['output']>;
};

export type ParticipantRewardsData = {
  __typename?: 'ParticipantRewardsData';
  id?: Maybe<Scalars['Int']['output']>;
  incentives?: Maybe<FundraiserRewardsProduct>;
  size?: Maybe<Scalars['String']['output']>;
  tier?: Maybe<Scalars['Int']['output']>;
};

export type ParticipantRewardsInput = {
  incentiveId: Scalars['Int']['input'];
  size: Scalars['String']['input'];
  tier: Scalars['Int']['input'];
};

export type ParticipantSsoid = {
  __typename?: 'ParticipantSSOID';
  userDirectoryId?: Maybe<Scalars['String']['output']>;
};

export type ParticipantTextTemplate = {
  __typename?: 'ParticipantTextTemplate';
  guardianTemplate?: Maybe<Scalars['String']['output']>;
  participantTemplate?: Maybe<Scalars['String']['output']>;
};

export type ParticipantTopDonation = {
  __typename?: 'ParticipantTopDonation';
  donorName?: Maybe<Scalars['String']['output']>;
  subtotalCents?: Maybe<Scalars['Int']['output']>;
};

export type ParticipantUser = {
  __typename?: 'ParticipantUser';
  fundraiserUserId: Scalars['Int']['output'];
  user: User;
};

export type ParticipantsInfo = {
  __typename?: 'ParticipantsInfo';
  count?: Maybe<Scalars['Int']['output']>;
  loggedInCount?: Maybe<Scalars['Int']['output']>;
  withAtLeastOneDonation?: Maybe<Scalars['Int']['output']>;
  withAtLeastTwentyInvites?: Maybe<Scalars['Int']['output']>;
};

export type Permission = {
  __typename?: 'Permission';
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type PhoneNumberChallengeConfirmation = Tokens & {
  __typename?: 'PhoneNumberChallengeConfirmation';
  accessToken?: Maybe<Scalars['String']['output']>;
  refreshToken?: Maybe<Scalars['String']['output']>;
};

export type PotentialCustomersDeals = {
  __typename?: 'PotentialCustomersDeals';
  activity?: Maybe<Scalars['String']['output']>;
  campaignsCount?: Maybe<Scalars['Int']['output']>;
  curricularType?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
};

/** Information of a emails scheduled for release */
export type PreloadEmailSchedule = {
  __typename?: 'PreloadEmailSchedule';
  /** The date and time for release */
  dateTime?: Maybe<Scalars['DateTime']['output']>;
  /** The campaign id */
  fundraiserId?: Maybe<Scalars['Int']['output']>;
  /** The timezone for scheduled release */
  timezone?: Maybe<Scalars['String']['output']>;
};

/** Information of premade email templates */
export type PremadeContactTemplate = {
  __typename?: 'PremadeContactTemplate';
  /** The message of the template. Plain text only */
  message?: Maybe<Scalars['String']['output']>;
  /** The name of the template */
  name?: Maybe<Scalars['String']['output']>;
  /** The subject of the template */
  subject?: Maybe<Scalars['String']['output']>;
  templateMedium?: Maybe<ContactTemplateMedium>;
  /** The type of template */
  templateType?: Maybe<ContactTemplateType>;
};

/**
 * Primary Group Leader
 *
 * The primary group leader is the main contact for a fundraiser
 *
 * The association is not direct with a user but made through email
 */
export type PrimaryGroupLeader = {
  __typename?: 'PrimaryGroupLeader';
  /** Signifies user is primary group leader. Extra query. */
  currentUserIsPrimary: Scalars['Boolean']['output'];
  email?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
};

export enum Processor {
  Stripe = 'STRIPE',
  Unit = 'UNIT'
}

export enum Product {
  Connect = 'CONNECT',
  Insights = 'INSIGHTS',
  Manage = 'MANAGE',
  Raise = 'RAISE',
  Spend = 'SPEND',
  Sponsor = 'SPONSOR',
  Store = 'STORE'
}

export enum ProgramActivity {
  ActionSports = 'ACTION_SPORTS',
  Archery = 'ARCHERY',
  Art = 'ART',
  Asb = 'ASB',
  Avid = 'AVID',
  Badminton = 'BADMINTON',
  Band = 'BAND',
  Baseball = 'BASEBALL',
  Basketball = 'BASKETBALL',
  Bowling = 'BOWLING',
  Boxing = 'BOXING',
  Business = 'BUSINESS',
  Cheerleading = 'CHEERLEADING',
  Chess = 'CHESS',
  Choir = 'CHOIR',
  Crew = 'CREW',
  CrossCountry = 'CROSS_COUNTRY',
  CultureAndLanguage = 'CULTURE_AND_LANGUAGE',
  Cycling = 'CYCLING',
  Dance = 'DANCE',
  Debate = 'DEBATE',
  Deca = 'DECA',
  Drama = 'DRAMA',
  Equestrian = 'EQUESTRIAN',
  Fashion = 'FASHION',
  Fbla = 'FBLA',
  Fccla = 'FCCLA',
  Fencing = 'FENCING',
  FieldHockey = 'FIELD_HOCKEY',
  FilmAndTvProduction = 'FILM_AND_TV_PRODUCTION',
  Football = 'FOOTBALL',
  FreshmanClass = 'FRESHMAN_CLASS',
  Golf = 'GOLF',
  GsaAndLgbtqi = 'GSA_AND_LGBTQI',
  Gymnastics = 'GYMNASTICS',
  Hockey = 'HOCKEY',
  Hosa = 'HOSA',
  Journalism = 'JOURNALISM',
  JuniorClass = 'JUNIOR_CLASS',
  KeyAndLink = 'KEY_AND_LINK',
  Lacrosse = 'LACROSSE',
  MartialArts = 'MARTIAL_ARTS',
  ModelUnAndTrial = 'MODEL_UN_AND_TRIAL',
  Music = 'MUSIC',
  NationalHonorsSociety = 'NATIONAL_HONORS_SOCIETY',
  NonProfit = 'NON_PROFIT',
  OrchestraAndSymphony = 'ORCHESTRA_AND_SYMPHONY',
  Other = 'OTHER',
  Outdoors = 'OUTDOORS',
  Photography = 'PHOTOGRAPHY',
  Polo = 'POLO',
  Powerlifting = 'POWERLIFTING',
  PromAndHomecoming = 'PROM_AND_HOMECOMING',
  Robotics = 'ROBOTICS',
  Rotc = 'ROTC',
  Rugby = 'RUGBY',
  Sailing = 'SAILING',
  Scholarship = 'SCHOLARSHIP',
  SeniorClass = 'SENIOR_CLASS',
  Shooting = 'SHOOTING',
  Skiing = 'SKIING',
  Soccer = 'SOCCER',
  Softball = 'SOFTBALL',
  SophomoreClass = 'SOPHOMORE_CLASS',
  Stem = 'STEM',
  StudentGovernment = 'STUDENT_GOVERNMENT',
  Surf = 'SURF',
  SwimAndDive = 'SWIM_AND_DIVE',
  TableTennis = 'TABLE_TENNIS',
  Tennis = 'TENNIS',
  TrackAndField = 'TRACK_AND_FIELD',
  UltimateFrisbee = 'ULTIMATE_FRISBEE',
  VideoGame = 'VIDEO_GAME',
  Volleyball = 'VOLLEYBALL',
  WaterPolo = 'WATER_POLO',
  WinterSports = 'WINTER_SPORTS',
  Wrestling = 'WRESTLING',
  Writing = 'WRITING',
  Yearbook = 'YEARBOOK'
}

export type ProgramLeadership = {
  __typename?: 'ProgramLeadership';
  org: Org;
  programs: Array<ProgramWithTitle>;
};

export type ProgramWithTitle = {
  __typename?: 'ProgramWithTitle';
  program: Org;
  titles: Array<Leader>;
};

export type PublicFundraiserData = {
  __typename?: 'PublicFundraiserData';
  alternateColor?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  donationMinDollars?: Maybe<Scalars['Int']['output']>;
  endDate?: Maybe<Scalars['DateTime']['output']>;
  fanStore?: Maybe<Scalars['Boolean']['output']>;
  goal?: Maybe<Scalars['Int']['output']>;
  hasCustomRewards?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  incStore?: Maybe<Scalars['Boolean']['output']>;
  isGuardianLedCampaign?: Maybe<Scalars['Boolean']['output']>;
  joinCode?: Maybe<Scalars['String']['output']>;
  logo?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  participantGoal?: Maybe<Scalars['Int']['output']>;
  personalMessage?: Maybe<Scalars['String']['output']>;
  primaryColor?: Maybe<Scalars['String']['output']>;
  programLeader?: Maybe<Scalars['String']['output']>;
  programType?: Maybe<Scalars['String']['output']>;
  secondaryColor?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['DateTime']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  storeUrl?: Maybe<Scalars['String']['output']>;
  whyDonations?: Maybe<Scalars['String']['output']>;
};

export type Query = {
  __typename?: 'Query';
  /** Return startDateTime and endDateTime as formatted UTC strings */
  campaignDates?: Maybe<CampaignDates>;
  campaignHistoryListGet?: Maybe<Array<Maybe<CampaignHistoryList>>>;
  campaignKyc?: Maybe<CampaignKyc>;
  /**
   * Query uses Users JWT to find their Memberships
   * See CampaignMembership type for details
   */
  campaignMemberships: Array<CampaignMembership>;
  /** List of saved search filters for campaigns of current user. */
  campaignSearchFiltersGet?: Maybe<Array<Maybe<CampaignSearchFilter>>>;
  /** List campaigns based on logged-in user */
  campaigns?: Maybe<CampaignList>;
  consumers?: Maybe<Array<Maybe<Consumer>>>;
  /** List of contact templates based on current user */
  customContactTemplatesGet?: Maybe<Array<Maybe<CustomContactTemplates>>>;
  donationLevelsByFundraiserId: Array<DonationLevel>;
  /** @deprecated This can be queried for specific personas via CampaignMemberships. */
  donationTopEarner?: Maybe<DonationTopEarner>;
  donorPersonListEntries?: Maybe<Array<Maybe<DonorPersonListEntry>>>;
  donorsParticipantOTKIncentives?: Maybe<DonorsParticipantOtkIncentives>;
  donorsParticipantsGiftShop?: Maybe<DonorsParticipantsGiftShop>;
  /** List all activity types of campaigns. Used by Drive */
  driveActivityTypes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /**
   * Get details of campaigns.
   * This endpoint need to considered to combined with searchDriveCampaign endpoint
   */
  driveCampaignDetails?: Maybe<DriveCampaignDetails>;
  /**
   * Get statistics of the set of campaigns returned by the filter.
   * This endpoint are meant to be used with the searchDriveCampaigns endpoint.
   * Arguments should be the same.
   */
  driveCampaignSearchStats?: Maybe<DriveCampaignSearchStatistics>;
  /** Show details of a specific event */
  driveEvent?: Maybe<DriveEvent>;
  /** List events */
  driveEvents?: Maybe<DriveEventResults>;
  driveGetEarlyAccessFunds?: Maybe<EarlyAccess>;
  driveGetListActivitiesAvailableList?: Maybe<DriveGetListPotentialCustomersReturn>;
  driveGetListActivitiesNotAvailableList?: Maybe<DriveGetListPotentialCustomersReturn>;
  driveGetListCampaignHistoryList?: Maybe<DriveGetListCurrentCustomersReturn>;
  driveGetListCurrentCustomersList?: Maybe<DriveGetListCurrentCustomersReturn>;
  driveGetListHubspotProspectsList?: Maybe<DriveGetlistProspectsReturn>;
  driveGetListOrgList?: Maybe<DriveOrgListResults>;
  /** Returns Organization Summary information of single organization */
  driveGetListOrganizationSummary?: Maybe<DriveGetListOrganizationSummaryReturn>;
  /** @deprecated changed name to driveGetListActivitiesAvailableList */
  driveGetListPotentialCustomersList?: Maybe<DriveGetListPotentialCustomersReturn>;
  driveGetListWinbackList?: Maybe<DriveGetlistWinbackReturn>;
  driveGetProjectedRaised?: Maybe<EarlyAccess>;
  /** Return all tracking of current user to specific organization in Drive's Get List app. */
  driveOrgUserTrackings?: Maybe<Array<Maybe<DriveOrgUserTrackingReturn>>>;
  /** List organization types used by Drive */
  driveOrganizationTypes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** List all salesreps */
  driveSalesreps?: Maybe<Array<Maybe<DriveAmSalesReps>>>;
  /** List all salesreps which had re-assigned any campaigns to current user. This endpoint is applicable for account-manager only. */
  driveSalesrepsByAccountManager?: Maybe<Array<Maybe<DriveAmSalesReps>>>;
  /** List all salesreps which managed by current user. This endpoint is applicable for sales manager only. */
  driveSalesrepsByManager?: Maybe<Array<Maybe<DriveAmSalesReps>>>;
  driveSmsInviteData?: Maybe<SmsInviteData>;
  /**
   * Search Raise users by UserDirectory 's id
   * @deprecated Use UserDirectory 's users query instead
   */
  driveUsers?: Maybe<DriveUsersResult>;
  /** List Event-Tracker's venues */
  driveVenues?: Maybe<Array<Maybe<DriveVenue>>>;
  family?: Maybe<Family>;
  /**
   * Get all Financial Accounts that are associated with a Stripe Connect Account Id
   *
   * If you are looking for Financial Accounts associated with a parent Org, please use the 'financialAcctRoot' query.
   */
  financialAcctAcctId: Array<FinancialAcctOrg>;
  /**
   * Get all Financial Accounts that are associated with an Org using the Org Id.
   *
   * If you are looking for Financial Accounts associated with a parent Org, please use the 'financialAcctRoot' query.
   */
  financialAcctOrg: Array<FinancialAcctOrg>;
  /**
   * Get all Financial Accounts that are associated with an Org using the Kyb Id.
   *
   * If you are looking for Financial Accounts associated with a parent Org, please use the 'financialAcctRoot' query.
   */
  financialAcctOrgByKyb: Array<FinancialAcctOrg>;
  /**
   * Get all Financial Accounts that are associated with a parent Org. This includes parents of parents and all the way up the tree.
   *
   * This will NOT return the Financial Accounts for the target Org, please use the 'financialAcctOrg' query for specific Orgs.
   */
  financialAcctRoot: Array<FinancialAcctOrg>;
  /**
   * Get all User data associated with a single Financial Account with an Orgs Platform Id.
   *
   * Eg: 'finacct_stripe_abcd1234'
   */
  financialAcctUsers: Array<FinancialAcctUser>;
  /**
   * Get all User and Financial Account data associated with a single Kyc Id.
   *
   * Eg: 'vkyc_abcd1234'
   */
  financialAcctUsersByKyc: Array<FinancialAcctUser>;
  fundraiserCheerwall?: Maybe<FundraiserCheerwall>;
  fundraiserCustomRewardsDetails?: Maybe<Array<Maybe<FundraiserCustomRewardsData>>>;
  fundraiserDataByCoachJoinCode?: Maybe<FundraiserDataByCoachJoinCode>;
  /** resourceId is the fundraiserId */
  fundraiserEntityResources?: Maybe<FundraiserEntityResource>;
  fundraiserGroups?: Maybe<Array<Maybe<FundraiserGroup>>>;
  fundraiserRaisedAmount?: Maybe<FundraiserRaisedAmount>;
  fundraiserRewardsDetails?: Maybe<Array<Maybe<FundraiserRewardsProduct>>>;
  fundraiserTopDonation?: Maybe<FundraiserTopDonation>;
  fundraiserUserRole?: Maybe<FundraiserUserRole>;
  galleryItems?: Maybe<GalleryItems>;
  getCampaignRoster?: Maybe<Array<Maybe<Roster>>>;
  getFundraiserUserID?: Maybe<Scalars['Int']['output']>;
  /** List of group leaders */
  groupLeaders?: Maybe<GroupLeaderList>;
  invite?: Maybe<InviteInfo>;
  invites?: Maybe<Array<Maybe<InviteList>>>;
  invitesList?: Maybe<InvitesListResponse>;
  /** joinCode is a string that is used to join a fundraiser */
  joinCodeType: JoinCodeType;
  me?: Maybe<UserWithPermissions>;
  messagesChannel?: Maybe<ChatChannelResult>;
  messagesChannelInvites?: Maybe<MessagesChannelInvitesResult>;
  messagesChannelMembers?: Maybe<MessagesChannelMembersResult>;
  messagesChannels?: Maybe<ChatChannelsResult>;
  messagesMyChannels?: Maybe<MessagesMyChannelsResult>;
  messagesMyModeratedChannels?: Maybe<MessagesMyModeratedChannelsResult>;
  messagesUnreadCount?: Maybe<ChatUnreadMessagesResult>;
  /**
   * Get Org Node properties by its Org id. If hierarchy is set to true, it will return all the children of the organization, but NOT the target Org.
   *
   * 'fields' returns JSON of all remaining properties. You can optionally limit this to specific fields by passing in an array of keys.
   *
   * Example:
   *   "names": [
   *     "country",
   *     "zip_code"
   *   ]
   */
  org?: Maybe<Array<Maybe<Org>>>;
  /**
   * Get Org Node properties from the campaign's Org id (eg: "cam_abc123")
   *
   * You can request a specifc Org type by passing in a label.
   *
   * If hierarchy is set to true, this query will return every Org all the way to the root
   */
  orgCampaignId?: Maybe<Array<Maybe<Org>>>;
  /**
   * Get all Campaigns under a specific Org. This will return an array of all Campaigns in the type of Org, sorted by
   * start_date descending.
   */
  orgCampaigns?: Maybe<Array<Maybe<Org>>>;
  /** Get Org Node properties by its Hubspot id. */
  orgHubspotId: Array<Org>;
  /**
   * Get an Organization's Org platform id by its Raise entities.id
   *
   * OR
   *
   * Get a Campaign's Org platform id by its Raise fundraisers.id
   *
   * This ONLY returns an Org id, not the full Org Node.
   */
  orgId?: Maybe<Scalars['ID']['output']>;
  /**
   * Get the Org paid by a given campaign id. This required an Org Platform Campaign Id (eg: "cam_abc123")
   * If you only have a Raise Fundraiser Id, you can use the 'orgId' query to find it.
   */
  orgPayable?: Maybe<Array<Maybe<Org>>>;
  /**
   * Fuzzy search for Orgs by their name property, returned in order of match quality.
   * Optionally accepts label, ein, city, two letter state code, or zip code.
   *
   * This search has a default limit of 25 and a maximim limit of 100 responses.
   */
  orgSearch: Array<Org>;
  /** Get all Users that are associated with a specific Org. */
  orgStaffRoster: Array<OrgStaffMember>;
  /**
   * Get all Users that are associated with an Org and a specific product.
   *
   * This includes role and a boolean if the user is internal (snap-raise or snapraise email).
   */
  orgUserAssociations: Array<Maybe<UserNode>>;
  /**
   * Search for Orgs by their name property, with an option to specify label.
   *
   * This will only return the first 100 orgs.
   */
  orgs: Array<Org>;
  /**
   * If you are simply searching by name property, please use the 'orgs' query.
   *
   * This advanced search searches by type or specific properties. Default limit is 25 items.
   * @deprecated Please use orgSearch instead
   */
  orgsSearch?: Maybe<OrgsSearchResponse>;
  /** This query will return OTK Fundraiser Product details */
  otkFundraiserData?: Maybe<Array<Maybe<OtkFundraiserProduct>>>;
  /** This query will return OTK Participant details */
  otkParticipantDetails?: Maybe<Array<Maybe<OtkParticipantData>>>;
  /** This query will return the most recent OTK Participant details */
  otkPastParticipantDetails?: Maybe<Array<Maybe<OtkParticipantData>>>;
  participantCampaignConfigurationData?: Maybe<ParticipantCampaignConfiguration>;
  participantCheerwall?: Maybe<ParticipantCheerwall>;
  participantDonationsRaised: ParticipantDonationsRaised;
  /** This query will return the incentives a Participant earned for a given fundraiser */
  participantEarnedRewardsDetails?: Maybe<Array<Maybe<ParticipantRewardsData>>>;
  participantGuardianFundraisers?: Maybe<Array<Maybe<ParticipantGuardianFundraiser>>>;
  participantPublic?: Maybe<ParticipantPublic>;
  /** This query will return Participant Rewards details */
  participantRewardsDetails?: Maybe<Array<Maybe<ParticipantRewardsData>>>;
  participantSSOId?: Maybe<ParticipantSsoid>;
  participantShareMessage: Scalars['String']['output'];
  participantTextTemplate?: Maybe<ParticipantTextTemplate>;
  participantTopDonation?: Maybe<ParticipantTopDonation>;
  /** List of participants */
  participants?: Maybe<ParticipantList>;
  participantsListV2?: Maybe<Array<Maybe<FundraiserParticipants>>>;
  participantsPublic?: Maybe<Array<Maybe<FundraiserParticipantPublic>>>;
  permissions?: Maybe<Array<Maybe<Permission>>>;
  /** List of pre-made contact templates */
  premadeContactTemplatesGet?: Maybe<Array<Maybe<PremadeContactTemplate>>>;
  /** This resolver is only for testing purposes at this time */
  primaryGroupLeader?: Maybe<PrimaryGroupLeader>;
  /** Identifier uses fundraiser join_code or slug */
  publicFundraiserData?: Maybe<PublicFundraiserData>;
  /** Get user public data from User Directory by userID */
  publicUserData?: Maybe<User>;
  /** A query to get last year's fundraiser by the id of this year's fundraiser */
  raiseAdminFundraiserPast?: Maybe<RaiseAdminFundraiserPast>;
  role?: Maybe<Role>;
  roles?: Maybe<Array<Maybe<Role>>>;
  /** Get one Roster with it's members by its Roster id. */
  roster?: Maybe<OrgsRoster>;
  /** Get all Rosters and their members that are associated with a specific Org. */
  rosters: Array<OrgsRoster>;
  /** Search campaigns */
  searchDriveCampaigns?: Maybe<DriveCampaignList>;
  /** Search Raise's Organization. @Deprecated: should be replaced by Orgs API */
  searchDriveOrganizationsByName?: Maybe<DriveOrganizationList>;
  user?: Maybe<User>;
  /** Get all Programs/Activities that a User leads, grouped by parent Org. */
  userActivityLeadership: Array<ProgramLeadership>;
  userAffiliations: Array<OrgAffiliation>;
  userApiKey?: Maybe<ApiKey>;
  userApiKeyBySecret?: Maybe<ApiKey>;
  userApiKeys?: Maybe<Array<Maybe<ApiKey>>>;
  /** Get all Org ids that are associated with a single User and a specific product, as well all the Campaign ids in each hierarchy. */
  userAssociationCampaigns: Array<OrgCampaignIds>;
  /** Get all Orgs that are associated with a User and a specific product. */
  userAssociations: Array<Maybe<Org>>;
  userBySecret?: Maybe<UserWithPermissions>;
  /** Get all Financial Account data associated with a single User Directory ID. */
  userFinancialAccounts: Array<FinancialAcctUser>;
  userFitting?: Maybe<UserFitting>;
  /**
   * Get a list of Campaigns grouped by Org that User has been a Group Leader for.
   *
   * If you do not pass in a User ID, we will use the logged in User's ID.
   */
  userGroupLeadership: Array<OrgCampaignAffiliation>;
  /** Get a list of implied affiliations based on GroupLeader relationships. */
  userImpliedAffiliations: Array<OrgCampaignAffiliation>;
  userInvites?: Maybe<InvitesResponse>;
  /** Get all titles that are associated with a User and a specific Org. */
  userOrgAffiliation: Array<UserAffiliation>;
  /** Get a list Campaigns grouped by Org that User has been a Participant in. */
  userParticipation: Array<OrgCampaignAffiliation>;
  userPermissions?: Maybe<UserPermissionsList>;
  /** @deprecated use userPublicInfo instead */
  userPublic?: Maybe<UserPublic>;
  userPublicInfo?: Maybe<Array<Maybe<UserPublicInfoResult>>>;
  /** Get all Teams that a User leads. */
  userTeamLeadership: Array<TeamLeadership>;
  userToken: Auth;
  /** Get counts of unconfirmed LEADS, AFFILIATIONS, and IMPLIED affiliations for a User. */
  userUnconfirmedAffiliations: UserAffiliationCounts;
  users?: Maybe<Users>;
};


export type QueryCampaignDatesArgs = {
  campaignId: Scalars['String']['input'];
};


export type QueryCampaignHistoryListGetArgs = {
  fundraiserId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryCampaignKycArgs = {
  campaignId: Scalars['String']['input'];
};


export type QueryCampaignMembershipsArgs = {
  basicStatus?: InputMaybe<BasicCampaignStatus>;
  fundraiserId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryCampaignsArgs = {
  accountManagerIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  daysClosed?: InputMaybe<Scalars['String']['input']>;
  daysToLaunch?: InputMaybe<Scalars['String']['input']>;
  fetchAllCampaigns?: InputMaybe<Scalars['Boolean']['input']>;
  getTeamSelling?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  salesrepIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  sortDirection?: InputMaybe<SortDirection>;
  sortField?: InputMaybe<SortField>;
  status?: InputMaybe<Scalars['String']['input']>;
};


export type QueryConsumersArgs = {
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryDonationLevelsByFundraiserIdArgs = {
  fundraiserId: Scalars['String']['input'];
};


export type QueryDonationTopEarnerArgs = {
  fundraiserId: Scalars['String']['input'];
};


export type QueryDonorPersonListEntriesArgs = {
  fundraiserId: Scalars['Int']['input'];
  userId: Scalars['Int']['input'];
};


export type QueryDonorsParticipantOtkIncentivesArgs = {
  fundraiserId: Scalars['String']['input'];
  participantId: Scalars['String']['input'];
};


export type QueryDonorsParticipantsGiftShopArgs = {
  fundraiserId: Scalars['String']['input'];
  participantId: Scalars['String']['input'];
};


export type QueryDriveCampaignDetailsArgs = {
  fundraiserId: Scalars['Int']['input'];
};


export type QueryDriveCampaignSearchStatsArgs = {
  activityTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  campaignName?: InputMaybe<Scalars['String']['input']>;
  campaignStatuses?: InputMaybe<Array<CampaignStatus>>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  fundraiserId?: InputMaybe<Scalars['Int']['input']>;
  hasIncentive?: InputMaybe<Scalars['Boolean']['input']>;
  maxTeamSize?: InputMaybe<Scalars['Int']['input']>;
  maxTotalRaisedCents?: InputMaybe<Scalars['Int']['input']>;
  minTeamSize?: InputMaybe<Scalars['Int']['input']>;
  minTotalRaisedCents?: InputMaybe<Scalars['Int']['input']>;
  organizationIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  organizationTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  salesrepIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  territories?: InputMaybe<Array<Scalars['String']['input']>>;
  usStates?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type QueryDriveEventArgs = {
  id: Scalars['ID']['input'];
};


export type QueryDriveEventsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<SortDirection>;
  sortField?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<DriveEventSearchInput>;
};


export type QueryDriveGetEarlyAccessFundsArgs = {
  activityType: Scalars['String']['input'];
  campaignStatus?: InputMaybe<Scalars['String']['input']>;
  fundraiserId: Scalars['Int']['input'];
  participantCount: Scalars['Int']['input'];
  state: Scalars['String']['input'];
};


export type QueryDriveGetListActivitiesAvailableListArgs = {
  hubspotId?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortField?: InputMaybe<Scalars['String']['input']>;
};


export type QueryDriveGetListActivitiesNotAvailableListArgs = {
  hubspotId?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<SortDirection>;
  sortField?: InputMaybe<Scalars['String']['input']>;
};


export type QueryDriveGetListCampaignHistoryListArgs = {
  hubspotId?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortField?: InputMaybe<Scalars['String']['input']>;
};


export type QueryDriveGetListCurrentCustomersListArgs = {
  hubspotId?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortField?: InputMaybe<Scalars['String']['input']>;
};


export type QueryDriveGetListHubspotProspectsListArgs = {
  hubspotId?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortField?: InputMaybe<Scalars['String']['input']>;
};


export type QueryDriveGetListOrgListArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orgName?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<SortDirection>;
  sortField?: InputMaybe<Scalars['String']['input']>;
};


export type QueryDriveGetListOrganizationSummaryArgs = {
  hubspotId?: InputMaybe<Scalars['String']['input']>;
  orgId: Scalars['Int']['input'];
};


export type QueryDriveGetListPotentialCustomersListArgs = {
  hubspotId?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortField?: InputMaybe<Scalars['String']['input']>;
};


export type QueryDriveGetListWinbackListArgs = {
  hubspotId?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortField?: InputMaybe<Scalars['String']['input']>;
};


export type QueryDriveGetProjectedRaisedArgs = {
  activityType: Scalars['String']['input'];
  participantCount: Scalars['Int']['input'];
  state: Scalars['String']['input'];
};


export type QueryDriveOrgUserTrackingsArgs = {
  orgId: Scalars['String']['input'];
  where?: InputMaybe<DriveOrgUserTrackingSearchInput>;
};


export type QueryDriveSalesrepsByManagerArgs = {
  type?: InputMaybe<SalesrepType>;
};


export type QueryDriveSmsInviteDataArgs = {
  fundraiserId: Scalars['Int']['input'];
};


export type QueryDriveUsersArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<DriveUserSearchInput>;
};


export type QueryDriveVenuesArgs = {
  where?: InputMaybe<DriveVenueSearchInput>;
};


export type QueryFinancialAcctAcctIdArgs = {
  acctId: Scalars['ID']['input'];
};


export type QueryFinancialAcctOrgArgs = {
  orgId: Scalars['ID']['input'];
};


export type QueryFinancialAcctOrgByKybArgs = {
  kybId: Scalars['ID']['input'];
};


export type QueryFinancialAcctRootArgs = {
  orgId: Scalars['ID']['input'];
};


export type QueryFinancialAcctUsersArgs = {
  finAcctId: Scalars['ID']['input'];
};


export type QueryFinancialAcctUsersByKycArgs = {
  kycId: Scalars['ID']['input'];
};


export type QueryFundraiserCheerwallArgs = {
  fundraiserId: Scalars['String']['input'];
  take: Scalars['Int']['input'];
};


export type QueryFundraiserCustomRewardsDetailsArgs = {
  fundraiserId: Scalars['String']['input'];
};


export type QueryFundraiserDataByCoachJoinCodeArgs = {
  coachJoinCode: Scalars['String']['input'];
};


export type QueryFundraiserEntityResourcesArgs = {
  resourceId: Scalars['Int']['input'];
};


export type QueryFundraiserGroupsArgs = {
  joinCode: Scalars['String']['input'];
};


export type QueryFundraiserRaisedAmountArgs = {
  fundraiserId: Scalars['String']['input'];
};


export type QueryFundraiserRewardsDetailsArgs = {
  fundraiserId: Scalars['String']['input'];
};


export type QueryFundraiserTopDonationArgs = {
  fundraiserId: Scalars['String']['input'];
};


export type QueryFundraiserUserRoleArgs = {
  fundraiserId: Scalars['Int']['input'];
};


export type QueryGalleryItemsArgs = {
  fundraiserId: Scalars['String']['input'];
};


export type QueryGetCampaignRosterArgs = {
  campaignId: Scalars['String']['input'];
};


export type QueryGroupLeadersArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  groupLeaderId?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryInviteArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type QueryInvitesArgs = {
  limit?: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  showAll?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryInvitesListArgs = {
  currentPage?: Scalars['Int']['input'];
  pageSize?: Scalars['Int']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  showAll?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryJoinCodeTypeArgs = {
  joinCode: Scalars['String']['input'];
};


export type QueryMessagesChannelArgs = {
  assignment?: InputMaybe<AssignmentInput>;
  id?: InputMaybe<Scalars['String']['input']>;
};


export type QueryMessagesChannelInvitesArgs = {
  id: Scalars['String']['input'];
};


export type QueryMessagesChannelMembersArgs = {
  id: Scalars['String']['input'];
};


export type QueryMessagesChannelsArgs = {
  filters?: InputMaybe<MessagesChannelsFilterInput>;
};


export type QueryOrgArgs = {
  hierarchy?: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
};


export type QueryOrgCampaignIdArgs = {
  campaignId: Scalars['ID']['input'];
  hierarchy?: Scalars['Boolean']['input'];
  label?: OrgType;
};


export type QueryOrgCampaignsArgs = {
  orgId: Scalars['ID']['input'];
};


export type QueryOrgHubspotIdArgs = {
  hubspotId: Scalars['String']['input'];
};


export type QueryOrgIdArgs = {
  raiseId: Scalars['Int']['input'];
};


export type QueryOrgPayableArgs = {
  campaignId: Scalars['ID']['input'];
};


export type QueryOrgSearchArgs = {
  city?: InputMaybe<Scalars['String']['input']>;
  ein?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<OrgType>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  matchStyle?: InputMaybe<MatchStyle>;
  name: Scalars['String']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  stateCode?: InputMaybe<StateCode>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};


export type QueryOrgStaffRosterArgs = {
  orgId: Scalars['ID']['input'];
};


export type QueryOrgUserAssociationsArgs = {
  orgId: Scalars['ID']['input'];
  product: Product;
};


export type QueryOrgsArgs = {
  label?: InputMaybe<OrgType>;
  nameIncludes: Scalars['String']['input'];
};


export type QueryOrgsSearchArgs = {
  pagination?: InputMaybe<OrgsSearchPaginationInput>;
  where?: InputMaybe<OrgsSearchWhereInput>;
};


export type QueryOtkFundraiserDataArgs = {
  fundraiserId: Scalars['ID']['input'];
};


export type QueryOtkParticipantDetailsArgs = {
  fundraiserId: Scalars['ID']['input'];
};


export type QueryOtkPastParticipantDetailsArgs = {
  fundraiserId: Scalars['ID']['input'];
};


export type QueryParticipantCampaignConfigurationDataArgs = {
  campaignID: Scalars['ID']['input'];
};


export type QueryParticipantCheerwallArgs = {
  fundraiserId: Scalars['String']['input'];
  participantId: Scalars['String']['input'];
  take: Scalars['Int']['input'];
};


export type QueryParticipantDonationsRaisedArgs = {
  fundraiserId: Scalars['Int']['input'];
  userId: Scalars['Int']['input'];
};


export type QueryParticipantEarnedRewardsDetailsArgs = {
  fundraiserId: Scalars['String']['input'];
  userId: Scalars['Int']['input'];
};


export type QueryParticipantPublicArgs = {
  fundraiserId: Scalars['Int']['input'];
  userId: Scalars['Int']['input'];
};


export type QueryParticipantRewardsDetailsArgs = {
  fundraiserId: Scalars['String']['input'];
  userId: Scalars['Int']['input'];
};


export type QueryParticipantSsoIdArgs = {
  fundraiserId: Scalars['Int']['input'];
  userId: Scalars['Int']['input'];
};


export type QueryParticipantShareMessageArgs = {
  fundraiserId: Scalars['String']['input'];
  msgType: Scalars['String']['input'];
};


export type QueryParticipantTextTemplateArgs = {
  fundraiserId: Scalars['Int']['input'];
  userId: Scalars['Int']['input'];
};


export type QueryParticipantTopDonationArgs = {
  fundraiserId: Scalars['String']['input'];
  participantId: Scalars['String']['input'];
};


export type QueryParticipantsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  participantId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryParticipantsListV2Args = {
  fundraiserId: Scalars['Int']['input'];
};


export type QueryParticipantsPublicArgs = {
  fundraiserId: Scalars['Int']['input'];
};


export type QueryPrimaryGroupLeaderArgs = {
  campaignId: Scalars['String']['input'];
};


export type QueryPublicFundraiserDataArgs = {
  fundraiserId?: InputMaybe<Scalars['Int']['input']>;
  identifier?: InputMaybe<Scalars['String']['input']>;
};


export type QueryPublicUserDataArgs = {
  userId: Scalars['Int']['input'];
};


export type QueryRaiseAdminFundraiserPastArgs = {
  id: Scalars['Int']['input'];
};


export type QueryRoleArgs = {
  id: Scalars['String']['input'];
};


export type QueryRolesArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type QueryRosterArgs = {
  rosterId: Scalars['ID']['input'];
};


export type QueryRostersArgs = {
  orgId: Scalars['ID']['input'];
  product?: InputMaybe<RosterCreator>;
};


export type QuerySearchDriveCampaignsArgs = {
  activityTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  campaignName?: InputMaybe<Scalars['String']['input']>;
  campaignStatuses?: InputMaybe<Array<CampaignStatus>>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  fundraiserId?: InputMaybe<Scalars['Int']['input']>;
  hasIncentive?: InputMaybe<Scalars['Boolean']['input']>;
  isPendingSettlement?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  maxTeamSize?: InputMaybe<Scalars['Int']['input']>;
  maxTotalRaisedCents?: InputMaybe<Scalars['Int']['input']>;
  minTeamSize?: InputMaybe<Scalars['Int']['input']>;
  minTotalRaisedCents?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  organizationIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  organizationTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  salesrepIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  sortDirection?: InputMaybe<SortDirection>;
  sortField?: InputMaybe<SortField>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  territories?: InputMaybe<Array<Scalars['String']['input']>>;
  usStates?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type QuerySearchDriveOrganizationsByNameArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  organizationName?: InputMaybe<Scalars['String']['input']>;
};


export type QueryUserArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};


export type QueryUserActivityLeadershipArgs = {
  userId: Scalars['ID']['input'];
};


export type QueryUserAffiliationsArgs = {
  userId: Scalars['ID']['input'];
};


export type QueryUserApiKeyArgs = {
  id: Scalars['String']['input'];
};


export type QueryUserApiKeyBySecretArgs = {
  secret: Scalars['String']['input'];
};


export type QueryUserAssociationCampaignsArgs = {
  product: Product;
  userId: Scalars['ID']['input'];
};


export type QueryUserAssociationsArgs = {
  id: Scalars['ID']['input'];
  product: Product;
};


export type QueryUserBySecretArgs = {
  secret: Scalars['String']['input'];
};


export type QueryUserFinancialAccountsArgs = {
  userId: Scalars['ID']['input'];
};


export type QueryUserGroupLeadershipArgs = {
  userId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryUserImpliedAffiliationsArgs = {
  userId: Scalars['ID']['input'];
};


export type QueryUserInvitesArgs = {
  ids: Array<Scalars['String']['input']>;
};


export type QueryUserOrgAffiliationArgs = {
  orgId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type QueryUserParticipationArgs = {
  userId: Scalars['ID']['input'];
};


export type QueryUserPermissionsArgs = {
  userId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryUserPublicArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type QueryUserPublicInfoArgs = {
  id?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};


export type QueryUserTeamLeadershipArgs = {
  userId: Scalars['ID']['input'];
};


export type QueryUserTokenArgs = {
  expirationTime?: InputMaybe<Scalars['String']['input']>;
  permissions?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type QueryUserUnconfirmedAffiliationsArgs = {
  userId: Scalars['ID']['input'];
};


export type QueryUsersArgs = {
  filter?: InputMaybe<UsersFilter>;
  isInternal?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  snapRaiseId?: InputMaybe<Scalars['Int']['input']>;
  snapSpendId?: InputMaybe<Scalars['Int']['input']>;
};

export type RaiseAdminFundraiser = {
  __typename?: 'RaiseAdminFundraiser';
  /** End date of upcoming fundraiser */
  endDate?: Maybe<Scalars['DateTime']['output']>;
  /** Raise Resource Entity ID of the fundraiser */
  entityId?: Maybe<Scalars['Int']['output']>;
  /** Int of participants from previous year */
  groupSize?: Maybe<Scalars['Int']['output']>;
  /** Fundraiser ID */
  id?: Maybe<Scalars['Int']['output']>;
  /** Fundraiser Image URL */
  image?: Maybe<Scalars['String']['output']>;
  /** Fundraiser name */
  name?: Maybe<Scalars['String']['output']>;
  /** A query for raise admin users to get upcoming resign participants */
  participantListForFundraiser?: Maybe<RaiseAdminParticipantList>;
  /** Has the fundraiser been rolled over? */
  rollover?: Maybe<Scalars['Boolean']['output']>;
  /** Start date of upcoming fundraiser */
  startDate?: Maybe<Scalars['DateTime']['output']>;
};


export type RaiseAdminFundraiserParticipantListForFundraiserArgs = {
  filter?: InputMaybe<RaiseAdminParticipantFilter>;
};

export type RaiseAdminFundraiserPast = {
  __typename?: 'RaiseAdminFundraiserPast';
  /** Current year's fundraiser */
  current?: Maybe<RaiseAdminFundraiser>;
  /** Last year's fundraiser */
  past?: Maybe<RaiseAdminFundraiser>;
};

export type RaiseAdminParticipant = {
  __typename?: 'RaiseAdminParticipant';
  /** Participant email */
  email?: Maybe<Scalars['String']['output']>;
  /** Participant first name */
  firstName?: Maybe<Scalars['String']['output']>;
  /** Participant ID */
  id?: Maybe<Scalars['Int']['output']>;
  /** Participant last name */
  lastName?: Maybe<Scalars['String']['output']>;
  /** Participant's Profile Image */
  profileImage?: Maybe<Scalars['String']['output']>;
};

export type RaiseAdminParticipantFilter = {
  /** Conditions to order by */
  orderBy?: InputMaybe<RaiseAdminParticipantOrderInput>;
  /** Conditions to filter by */
  where?: InputMaybe<RaiseAdminParticipantWhereInput>;
};

export type RaiseAdminParticipantList = {
  __typename?: 'RaiseAdminParticipantList';
  /** Total number of fundraisers */
  count: Scalars['Int']['output'];
  /** List of fundraisers */
  list?: Maybe<Array<Maybe<RaiseAdminParticipant>>>;
};

export type RaiseAdminParticipantOrderInput = {
  email?: InputMaybe<SortOrderEnum>;
  id?: InputMaybe<SortOrderEnum>;
  name?: InputMaybe<SortOrderEnum>;
};

export type RaiseAdminParticipantWhereInput = {
  /** Nullable String Filter for Participant email */
  email?: InputMaybe<Scalars['String']['input']>;
  /** Nullable String Filter for Participant name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Nullable Boolean Filter for Participant Rollover */
  rollover?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RaiseFundraiserUsersCreateManyInput = {
  fundraiser_id: Scalars['Int']['input'];
  user_ids: Array<Scalars['Int']['input']>;
};

export type RaiseUser = {
  __typename?: 'RaiseUser';
  email?: Maybe<Scalars['String']['output']>;
  /** SSO User ID */
  id?: Maybe<Scalars['String']['output']>;
};

export type Role = {
  __typename?: 'Role';
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  permissions?: Maybe<Array<Maybe<RolePermission>>>;
  scope?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type RoleArguments = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  scope?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
};

export type RolePermission = {
  __typename?: 'RolePermission';
  id?: Maybe<Scalars['String']['output']>;
  permission?: Maybe<Permission>;
  permissionId?: Maybe<Scalars['String']['output']>;
  roleId?: Maybe<Scalars['String']['output']>;
};

export type Roster = {
  __typename?: 'Roster';
  id?: Maybe<Scalars['ID']['output']>;
  members?: Maybe<Array<Maybe<RosterMember>>>;
  name?: Maybe<Scalars['String']['output']>;
};

export type RosterCloneInput = {
  activity?: InputMaybe<ProgramActivity>;
  description?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<TeamGender>;
  name: Scalars['String']['input'];
  orgId: Scalars['ID']['input'];
  product: RosterCreator;
  rosterId: Scalars['ID']['input'];
  startYear: Scalars['Int']['input'];
  tier?: InputMaybe<TeamTier>;
};

export type RosterCreateInput = {
  activity?: InputMaybe<ProgramActivity>;
  description?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<TeamGender>;
  members?: Array<RosterUser>;
  name: Scalars['String']['input'];
  orgId: Scalars['ID']['input'];
  product: RosterCreator;
  startYear: Scalars['Int']['input'];
  tier?: InputMaybe<TeamTier>;
};

export enum RosterCreator {
  Admin = 'ADMIN',
  Messaging = 'MESSAGING',
  Raise = 'RAISE',
  Registration = 'REGISTRATION',
  Spend = 'SPEND'
}

export type RosterMember = {
  __typename?: 'RosterMember';
  association?: Maybe<CampaignMemberAssociation>;
  id?: Maybe<Scalars['ID']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
  wasDeleted?: Maybe<Scalars['Boolean']['output']>;
};

export type RosterUpdateInput = {
  activity?: InputMaybe<ProgramActivity>;
  description?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<TeamGender>;
  members?: Array<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  rosterId: Scalars['ID']['input'];
  startYear?: InputMaybe<Scalars['Int']['input']>;
};

export type RosterUser = {
  id: Scalars['ID']['input'];
  isAdmin?: InputMaybe<Scalars['Boolean']['input']>;
  isDependent?: InputMaybe<Scalars['Boolean']['input']>;
  isGuardian?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum Support_Ticket_Department {
  Accounts = 'ACCOUNTS',
  Hubspot = 'HUBSPOT',
  It = 'IT',
  Pep = 'PEP',
  Sales = 'SALES',
  Sponsor = 'SPONSOR',
  Store = 'STORE',
  Wallet = 'WALLET',
  Warehouse = 'WAREHOUSE'
}

export enum SalesrepType {
  AccountManager = 'ACCOUNT_MANAGER',
  SalesRep = 'SALES_REP'
}

/** Information of a emails scheduled for cron jobs */
export type ScheduledReleaseInfo = {
  __typename?: 'ScheduledReleaseInfo';
  /** The date and time for release */
  date?: Maybe<Scalars['DateTime']['output']>;
  /** The timezone for scheduled release */
  timezone?: Maybe<Scalars['String']['output']>;
};

export enum SchoolCategory {
  Charter = 'CHARTER',
  Magnet = 'MAGNET',
  Private = 'PRIVATE',
  Public = 'PUBLIC'
}

/** Settlement details for closed campaign. */
export type SettlementDetails = {
  __typename?: 'SettlementDetails';
  /** The amount in cents that campaigns was advanced in Wallet. */
  advanceAmount?: Maybe<Scalars['Int']['output']>;
  /** Address to send out check to */
  checkingAddress?: Maybe<Scalars['String']['output']>;
  /** The type of snap fee being collected. e.g. 'standard', '1+1', '20%/15%', etc. */
  feeType?: Maybe<Scalars['String']['output']>;
  /** Total cost of incentives in cents */
  gearCost?: Maybe<Scalars['Int']['output']>;
  /** Code to build logo */
  incLogo?: Maybe<Scalars['String']['output']>;
  /** Primary color to build logo */
  incLogoPrimaryColor?: Maybe<Scalars['String']['output']>;
  /** Script to build logo */
  incLogoScript?: Maybe<Scalars['String']['output']>;
  /** Secondary color to build logo */
  incLogoSecondaryColor?: Maybe<Scalars['String']['output']>;
  /** Filename of Logo of Group of Campaign */
  logoImage?: Maybe<Scalars['String']['output']>;
  /** The amount in cents that campaigns was manual-advanced in Raise. */
  manualAdvanceAmount?: Maybe<Scalars['Int']['output']>;
  /**
   * Net raised amount in cents. Equals totalRaised minus snapFee and adds the otkBonus.
   * It is the amount client received in their check/deposit.
   */
  netRaisedTotal?: Maybe<Scalars['Int']['output']>;
  /** Notes of campaign */
  notes?: Maybe<Scalars['String']['output']>;
  /** Legal Name of Organization */
  organizationLegalName?: Maybe<Scalars['String']['output']>;
  /** TIN of Organization */
  organizationTin?: Maybe<Scalars['String']['output']>;
  /** Total bonus amount that team will receive through otk purchase cost (usually 10%) in cents. */
  otkBonus?: Maybe<Scalars['Int']['output']>;
  /** Link to OTK Logo */
  otkLogoUrl?: Maybe<Scalars['String']['output']>;
  /** Percentage of participation of campaign */
  participationPercent?: Maybe<Scalars['Int']['output']>;
  /** Notes for settlement for this campaign */
  settlementNotes?: Maybe<Scalars['String']['output']>;
  /** Default Shipping address to send out gears/merchandise/check */
  shippingAddress?: Maybe<Scalars['String']['output']>;
  /** Total Fee in cents that Raise collects from campaigns */
  snapFee?: Maybe<Scalars['Int']['output']>;
};

export enum SettlementMethod {
  DirectDeposit = 'DIRECT_DEPOSIT',
  MailedCheck = 'MAILED_CHECK'
}

/** Fixed statuses for a campaigns settlement status */
export enum SettlementStatus {
  InReview = 'IN_REVIEW',
  Unsubmitted = 'UNSUBMITTED',
  Verified = 'VERIFIED'
}

/** Attributes for settlement status */
export type SettlementStatusUpdate = {
  __typename?: 'SettlementStatusUpdate';
  fundraiserId?: Maybe<Scalars['Int']['output']>;
  settlementStatus?: Maybe<SettlementStatus>;
};

export type SignUpResponseWithAvailableTransport = {
  __typename?: 'SignUpResponseWithAvailableTransport';
  availableTransport: Array<TransportEnum>;
  result: Array<TransportType>;
};

export type SmsInviteData = {
  __typename?: 'SmsInviteData';
  count?: Maybe<Scalars['Int']['output']>;
  isReleased?: Maybe<Scalars['Boolean']['output']>;
  smsInviteReleasedAt?: Maybe<Scalars['DateTime']['output']>;
};

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum SortField {
  AdvancedFunds = 'ADVANCED_FUNDS',
  ClosedStatus = 'CLOSED_STATUS',
  DaysLeft = 'DAYS_LEFT',
  EndDate = 'END_DATE',
  KycStatus = 'KYC_STATUS',
  Name = 'NAME',
  Participation = 'PARTICIPATION',
  Preload = 'PRELOAD',
  SettlementDetails = 'SETTLEMENT_DETAILS',
  StartDate = 'START_DATE',
  Status = 'STATUS',
  TotalRaised = 'TOTAL_RAISED'
}

export enum SortOrderEnum {
  Asc = 'asc',
  Desc = 'desc'
}

export enum StateCode {
  Ak = 'AK',
  Al = 'AL',
  Ar = 'AR',
  As = 'AS',
  Az = 'AZ',
  Ca = 'CA',
  Co = 'CO',
  Ct = 'CT',
  Dc = 'DC',
  De = 'DE',
  Fl = 'FL',
  Ga = 'GA',
  Gu = 'GU',
  Hi = 'HI',
  Ia = 'IA',
  Id = 'ID',
  Il = 'IL',
  In = 'IN',
  Ks = 'KS',
  Ky = 'KY',
  La = 'LA',
  Ma = 'MA',
  Md = 'MD',
  Me = 'ME',
  Mi = 'MI',
  Mn = 'MN',
  Mo = 'MO',
  Mp = 'MP',
  Ms = 'MS',
  Mt = 'MT',
  Nc = 'NC',
  Nd = 'ND',
  Ne = 'NE',
  Nh = 'NH',
  Nj = 'NJ',
  Nm = 'NM',
  Nv = 'NV',
  Ny = 'NY',
  Oh = 'OH',
  Ok = 'OK',
  Or = 'OR',
  Pa = 'PA',
  Pr = 'PR',
  Ri = 'RI',
  Sc = 'SC',
  Sd = 'SD',
  Tn = 'TN',
  Tx = 'TX',
  Um = 'UM',
  Ut = 'UT',
  Va = 'VA',
  Vi = 'VI',
  Vt = 'VT',
  Wa = 'WA',
  Wi = 'WI',
  Wv = 'WV',
  Wy = 'WY'
}

export type Store = {
  __typename?: 'Store';
  code?: Maybe<Scalars['String']['output']>;
  domain?: Maybe<Scalars['String']['output']>;
  enabled?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

/** See CampaignMembership for details */
export type SupporterCampaign = {
  __typename?: 'SupporterCampaign';
  id: Scalars['ID']['output'];
};

export enum TeamAge {
  Adult = 'ADULT',
  Preteen = 'PRETEEN',
  Teen = 'TEEN',
  Youth = 'YOUTH'
}

export enum TeamGender {
  Coed = 'COED',
  Female = 'FEMALE',
  Male = 'MALE'
}

export enum TeamGroupType {
  Club = 'CLUB',
  Sport = 'SPORT'
}

export type TeamLeadership = {
  __typename?: 'TeamLeadership';
  org: Org;
  teams: Array<TeamWithTitle>;
};

export enum TeamTier {
  First = 'FIRST',
  Freshman = 'FRESHMAN',
  FullProgram = 'FULL_PROGRAM',
  JuniorVarsity = 'JUNIOR_VARSITY',
  JuniorVarsityC = 'JUNIOR_VARSITY_C',
  Second = 'SECOND',
  Varsity = 'VARSITY'
}

export enum TeamTitle {
  AssistantCoach = 'ASSISTANT_COACH',
  BoosterClubLeader = 'BOOSTER_CLUB_LEADER',
  BoosterClubMember = 'BOOSTER_CLUB_MEMBER',
  ClubSportsAdministrator = 'CLUB_SPORTS_ADMINISTRATOR',
  ClubSportsDirector = 'CLUB_SPORTS_DIRECTOR',
  ClubSportsEmployee = 'CLUB_SPORTS_EMPLOYEE',
  Coach = 'COACH',
  FineArtsDirector = 'FINE_ARTS_DIRECTOR',
  SchoolClubAdvisor = 'SCHOOL_CLUB_ADVISOR',
  TeacherInstructor = 'TEACHER_INSTRUCTOR'
}

export type TeamWithTitle = {
  __typename?: 'TeamWithTitle';
  team: Org;
  titles: Array<Leader>;
};

export type Tokens = {
  accessToken?: Maybe<Scalars['String']['output']>;
  refreshToken?: Maybe<Scalars['String']['output']>;
};

export enum TransportEnum {
  Email = 'email',
  Sms = 'sms'
}

export type TransportType = {
  __typename?: 'TransportType';
  recipient?: Maybe<Scalars['String']['output']>;
  transport?: Maybe<TransportEnum>;
};

export type UnreadCount = {
  __typename?: 'UnreadCount';
  totalUnreadCount: Scalars['Int']['output'];
};

export type UnreadCountError = Error & {
  __typename?: 'UnreadCountError';
  message: Scalars['String']['output'];
};

export type UpdatedFundraiserStoreUrl = {
  __typename?: 'UpdatedFundraiserStoreUrl';
  id?: Maybe<Scalars['Int']['output']>;
};

export type UpdatedParticipantGroupCount = {
  __typename?: 'UpdatedParticipantGroupCount';
  count?: Maybe<Scalars['Int']['output']>;
};

export type User = {
  __typename?: 'User';
  apps?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isConfirmed?: Maybe<Scalars['Boolean']['output']>;
  isDisabled?: Maybe<Scalars['Boolean']['output']>;
  language?: Maybe<Scalars['String']['output']>;
  lastLoginAt?: Maybe<Scalars['Date']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  occupation?: Maybe<UserOccupation>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  profilePicture?: Maybe<Scalars['String']['output']>;
  /** @deprecated will be removed in the next release */
  snapRaiseId?: Maybe<Scalars['String']['output']>;
  /** @deprecated will be removed in the next release */
  snapSpendId?: Maybe<Scalars['String']['output']>;
};

export type UserAffiliation = {
  __typename?: 'UserAffiliation';
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  isConfirmed?: Maybe<Scalars['Boolean']['output']>;
  title: AffiliationTitle;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type UserAffiliationCounts = {
  __typename?: 'UserAffiliationCounts';
  affiliatedCount: Scalars['Int']['output'];
  impliedCount: Scalars['Int']['output'];
  leadsCount: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum UserApps {
  Connect = 'connect',
  Drive = 'drive',
  Fanx = 'fanx',
  Home = 'home',
  Insights = 'insights',
  Manage = 'manage',
  Raise = 'raise',
  Spend = 'spend',
  SpendNew = 'spend_new',
  Sponsor = 'sponsor',
  Store = 'store',
  Wallet = 'wallet'
}

export type UserChallenge = {
  __typename?: 'UserChallenge';
  completedAt?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  params?: Maybe<Scalars['JSONObject']['output']>;
  skippedAt?: Maybe<Scalars['String']['output']>;
  status: UserChallengeStatus;
};

export enum UserChallengeStatus {
  Awaiting = 'AWAITING',
  Completed = 'COMPLETED',
  Skipped = 'SKIPPED'
}

export type UserFitting = {
  __typename?: 'UserFitting';
  fitting?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
};

export enum UserFittingPreference {
  Mens = 'MENS',
  Womens = 'WOMENS'
}

export type UserInviteParams = {
  __typename?: 'UserInviteParams';
  occupation?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
};

export type UserInviteResponse = {
  __typename?: 'UserInviteResponse';
  redirect?: Maybe<Scalars['String']['output']>;
};

export enum UserInviteStatus {
  Accepted = 'ACCEPTED',
  Declined = 'DECLINED',
  Pending = 'PENDING'
}

export type UserNode = {
  __typename?: 'UserNode';
  id: Scalars['ID']['output'];
  internal?: Maybe<Scalars['Boolean']['output']>;
  role?: Maybe<Scalars['ID']['output']>;
  type?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export enum UserOccupation {
  DistrictAdministrator = 'district_administrator',
  FinancialAdministrator = 'financial_administrator',
  GroupLeader = 'group_leader',
  Other = 'other',
  Parent = 'parent',
  SchoolDirector = 'school_director',
  StateAdministrator = 'state_administrator',
  StudentOrParticipant = 'student_or_participant',
  Unknown = 'unknown'
}

export type UserOrgAffiliationPayload = {
  orgId?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UserOrgInvitationPayload = {
  email?: InputMaybe<Scalars['String']['input']>;
  orgId?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UserOrgInvitationResult = {
  __typename?: 'UserOrgInvitationResult';
  id?: Maybe<Scalars['String']['output']>;
  info?: Maybe<Scalars['JSON']['output']>;
  meta?: Maybe<Scalars['JSON']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type UserPermission = {
  __typename?: 'UserPermission';
  id: Scalars['String']['output'];
  scope?: Maybe<Scalars['String']['output']>;
};

export type UserPermissionAssignment = {
  id: Scalars['String']['input'];
  scope?: InputMaybe<Scalars['String']['input']>;
};

export type UserPermissionsList = {
  __typename?: 'UserPermissionsList';
  id?: Maybe<Scalars['String']['output']>;
  negativePermissions?: Maybe<Array<Maybe<UserPermission>>>;
  permissions?: Maybe<Array<Maybe<UserPermission>>>;
  roleIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type UserPublic = {
  __typename?: 'UserPublic';
  createdAt?: Maybe<Scalars['Date']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isInternal?: Maybe<Scalars['Boolean']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  notMigrated?: Maybe<Scalars['Boolean']['output']>;
  occupation?: Maybe<UserOccupation>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  profilePicture?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['String']['output']>;
};

export type UserPublicInfo = {
  __typename?: 'UserPublicInfo';
  isInternal?: Maybe<Scalars['Boolean']['output']>;
  notMigrated?: Maybe<Scalars['Boolean']['output']>;
};

export type UserPublicInfoResult = {
  __typename?: 'UserPublicInfoResult';
  info?: Maybe<UserPublicInfo>;
  user?: Maybe<UserPublic>;
};

export type UserResetPasswordByAdminResponse = {
  __typename?: 'UserResetPasswordByAdminResponse';
  user?: Maybe<User>;
};

export type UserResetPasswordResponseWithAvailableTransport = {
  __typename?: 'UserResetPasswordResponseWithAvailableTransport';
  availableTransport: Array<TransportEnum>;
  info?: Maybe<Scalars['String']['output']>;
  result: Array<TransportType>;
};

export enum UserTypeConfiguration {
  Default = 'DEFAULT',
  GuardianLead = 'GUARDIAN_LEAD'
}

export type UserWithPermissions = {
  __typename?: 'UserWithPermissions';
  apps?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  hasFamily?: Maybe<Scalars['Boolean']['output']>;
  hsGradYear?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  language?: Maybe<Scalars['String']['output']>;
  lastLoginAt?: Maybe<Scalars['Date']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  occupation?: Maybe<UserOccupation>;
  /** @deprecated use parents instead of parent */
  parent?: Maybe<Scalars['String']['output']>;
  parents?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  permissions?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  profilePicture?: Maybe<Scalars['String']['output']>;
  /** @deprecated will be removed in the next release */
  snapRaiseId?: Maybe<Scalars['String']['output']>;
  /** @deprecated will be removed in the next release */
  snapSpendId?: Maybe<Scalars['String']['output']>;
};

export type Users = {
  __typename?: 'Users';
  nextPage?: Maybe<Scalars['Boolean']['output']>;
  users?: Maybe<Array<Maybe<User>>>;
};

export type UsersFilter = {
  userIds: Array<Scalars['ID']['input']>;
};

export type WinbackDeals = {
  __typename?: 'WinbackDeals';
  activity?: Maybe<Scalars['String']['output']>;
  amount?: Maybe<Scalars['String']['output']>;
  closedLost?: Maybe<Scalars['String']['output']>;
  dealName?: Maybe<Scalars['String']['output']>;
  dealStage?: Maybe<Scalars['String']['output']>;
  hubspotId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isWinbackPinned?: Maybe<Scalars['Boolean']['output']>;
  lastLaunch?: Maybe<Scalars['String']['output']>;
  leaderFirstName?: Maybe<Scalars['String']['output']>;
  leaderLastName?: Maybe<Scalars['String']['output']>;
  previousDealId?: Maybe<Scalars['String']['output']>;
  previousFundId?: Maybe<Scalars['String']['output']>;
};

export type ApprovedFunds = {
  __typename?: 'approvedFunds';
  approvedFundsCents?: Maybe<Scalars['Int']['output']>;
  isPaidBack?: Maybe<Scalars['Boolean']['output']>;
};

export type DriveGetListCurrentCustomersReturn = {
  __typename?: 'driveGetListCurrentCustomersReturn';
  offset?: Maybe<Scalars['Int']['output']>;
  results?: Maybe<Array<Maybe<CurrentCustomersDeals>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type DriveGetListPotentialCustomersReturn = {
  __typename?: 'driveGetListPotentialCustomersReturn';
  offset?: Maybe<Scalars['Int']['output']>;
  results?: Maybe<Array<Maybe<PotentialCustomersDeals>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** Last clicked tracking information used by Drive's Get List Application */
export type DriveOrgUserTrackingReturn = {
  __typename?: 'driveOrgUserTrackingReturn';
  /** ID of the organization. */
  orgId?: Maybe<Scalars['String']['output']>;
  /** A unique tracking id */
  trackingId?: Maybe<Scalars['String']['output']>;
  trackingType?: Maybe<Scalars['String']['output']>;
  /** The value of the tracking type */
  trackingValue?: Maybe<Scalars['String']['output']>;
  trackingValueType?: Maybe<Scalars['String']['output']>;
  /** ID of the user. */
  userId?: Maybe<Scalars['String']['output']>;
};

export type EarlyAccess = {
  __typename?: 'earlyAccess';
  activityType?: Maybe<Scalars['String']['output']>;
  earlyAccessApprovedFundsMax?: Maybe<Scalars['Int']['output']>;
  earlyAccessApprovedFundsMin?: Maybe<Scalars['Int']['output']>;
  isNationalRpk?: Maybe<Scalars['Boolean']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  participantCount?: Maybe<Scalars['Int']['output']>;
  projectedRaisedMax?: Maybe<Scalars['Int']['output']>;
  projectedRaisedMin?: Maybe<Scalars['Int']['output']>;
};

export type ParticipantCampaignConfiguration = {
  __typename?: 'participantCampaignConfiguration';
  autoImportEmails: ParticipantCampaignConfigStates;
  autoImportTexts: ParticipantCampaignConfigStates;
  campaignID: Scalars['String']['output'];
  giftShop: ParticipantCampaignConfigStates;
  guardianSetup: ParticipantCampaignConfigStates;
  id: Scalars['ID']['output'];
  profileSetup: ParticipantCampaignConfigStates;
  rewards: ParticipantCampaignConfigStates;
  updatedAt: Scalars['DateTime']['output'];
  userID: Scalars['String']['output'];
};
