import { Vue } from "vue-class-component";
import Drawer from "@/components/ui/Drawer.vue";

export type DrawerManager = {
  opened?: [string, Drawer];
  drawers: Record<string, Drawer>;
  open(name: string): void;
  close(): void;
};

const manager: DrawerManager = {
  drawers: {},

  open(name: string): void {
    if (this.opened && this.opened[0] !== name && this.opened[1].isOpen) {
      this.opened[1].close(false);
    }

    if (this.drawers[name]) {
      this.opened = [name, this.drawers[name]];
      this.opened[1].open();
    }
  },

  close() {
    if (this.opened && this.opened[1].isOpen) this.opened[1].close(false);
  },
};

export const useDrawers = (
  component: Vue,
  names: Array<string>
): DrawerManager => {
  return {
    ...manager,
    drawers: names.reduce(
      (acc, name) => ({ ...acc, [name]: component.$refs[name] as Drawer }),
      {}
    ),
  };
};
