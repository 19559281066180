<template>
  <form @submit.prevent="update()" v-keyboard-submit>
    <InputField
      class="block mb-4"
      label="Current Password"
      name="oldPassword"
      type="password"
      v-model="oldPassword"
      :validation="v$.oldPassword"
    ></InputField>

    <InputField
      class="block mb-4"
      label="New Password"
      name="newPassword"
      type="password"
      v-model="newPassword"
      :validation="v$.newPassword"
      help-text="Your password must be 8-32 characters, and include at least one lowercase letter, one uppercase letter, and a number."
    ></InputField>

    <InputField
      class="block mb-4"
      label="Confirm Password"
      name="confirmPassword"
      type="password"
      v-model="confirmPassword"
      :validation="v$.confirmPassword"
    ></InputField>
  </form>
</template>

<script lang="ts">
import { Options } from "vue-class-component";
import { mapGetters } from "vuex";

import type { User } from "@/types/graphql";

import InputField from "@/components/snap-ui/InputField.vue";

import { $notify } from "@/services/notification";
import { RequiredRule, PasswordRule, SameAsRule } from "@/util/validator";
import { userUpdatePassword } from "@/services/user.service";

import { Form, FormSubmitError, Model, Submit } from "@/core/Form";

@Options({
  components: { InputField },
  computed: {
    ...mapGetters(["me"]),
  },

  watch: {
    error(value: FormSubmitError) {
      if (value)
        $notify({
          title: value.message,
          description: value.description,
          type: "danger",
        });
    },
  },
})
export default class PasswordUpdateForm extends Form {
  me?: User;

  @Model({ requires: RequiredRule("Current Password is required") })
  oldPassword = "";

  @Model({
    requires: RequiredRule("New Password is required"),
    strong: PasswordRule(
      "Your password must be 8-32 characters, and include at least one lowercase letter, one uppercase letter, and a number."
    ),
  })
  newPassword = "";

  @Model({ sameAs: SameAsRule("Passwords don't match.", "newPassword") })
  confirmPassword = "";

  @Submit()
  async update(): Promise<void | boolean> {
    if (this.isFormValid()) {
      await userUpdatePassword(
        this.me?.email as string,
        this.oldPassword,
        this.newPassword
      );

      this.oldPassword = "";
      this.newPassword = "";
      this.confirmPassword = "";

      this.resetValidation(["newPassword", "confirmPassword"]);

      await $notify({
        title: "Your password has been updated",
        type: "success",
      });
    } else {
      return false;
    }
  }
}
</script>
