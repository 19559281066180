export class FiniteStateMachine<T> {
  states: Array<T> = [];
  current: T;

  constructor(states: Array<T>) {
    this.states = [...states];
    this.current = this.states[0];
  }

  set(state: T) {
    if (this.states.includes(state) && this.current !== state) {
      this.current = state;
    }
  }

  is(state: T): boolean {
    return this.current === state;
  }
}

export const useFiniteStateMachine = <T>(states: Array<T>) => {
  return new FiniteStateMachine<T>(states);
};
