import { createDecorator, Options, Vue } from "vue-class-component";
import {
  ErrorObject,
  useVuelidate,
  Validation,
  ValidationArgs,
} from "@vuelidate/core";
import { Ref } from "vue";
import { ApolloError } from "@apollo/client";
import { GraphQLErrors } from "@apollo/client/errors";

import { useFiniteStateMachine } from "./FiniteStateMachine";

import Autofocus from "@/directives/v-autofocus";
import KeyboardSubmit from "@/directives/v-keyboard-submit";
import * as Sentry from "@/util/sentry";

type FormState = "NORMAL" | "SUBMITTING" | "ERROR";
type V = Ref<Validation> & Record<string, Validation>;

type ServerError = {
  $validator: "$externalResults";
  $response: null;
  $pending: false;
  $params: unknown;
} & ErrorObject;

type NetworkError = {
  message: string;
  extensions?: unknown;
};

type FormSubmitArgs = {
  model?: string;
};

export type FormSubmitError = {
  message: string;
  description?: Array<string>;
} | null;

const createServerError = (name: string, message: string): ServerError => ({
  $message: message,
  $params: {},
  $pending: false,
  $property: name,
  $propertyPath: name,
  $response: null,
  $uid: `${name}-externalResult-0`,
  $validator: "$externalResults",
});

const parseGraphQLErrors = (graphQLErrors?: GraphQLErrors): Array<string> => {
  return (
    graphQLErrors
      ?.reduce(
        (acc, error) => [
          ...acc,
          ...(error && Array.isArray(error.extensions?.details)
            ? (error.extensions?.details || []).reduce(
                (acc, detail) => [...acc, detail.message],
                []
              )
            : []),
        ],
        [] as Array<string>
      )
      .filter(Boolean) || []
  );
};

const parseNetworkErrors = (errors?: Array<NetworkError>): Array<string> => {
  return (errors || []).map((error) => error?.message).filter(Boolean);
};

@Options<Form>({
  directives: { autofocus: Autofocus, "keyboard-submit": KeyboardSubmit },
  emits: ["form:success", "form:error", "state:normal", "state:submitting"],
})
export class Form extends Vue {
  isValid = true;
  error: FormSubmitError = null;

  state$ = useFiniteStateMachine<FormState>(["NORMAL", "SUBMITTING", "ERROR"]);

  v$: V = useVuelidate({
    $lazy: true,
  }) as V;

  setValidity(isValid: boolean, error: FormSubmitError = null): void {
    this.isValid = isValid;
    this.error = error;
  }

  canSubmit(): boolean {
    return !this.state$.is("SUBMITTING") && this.isFormValid();
  }

  isFormValid(): boolean {
    (this.v$ as unknown as Validation).$touch();
    return !(this.v$ as unknown as Validation).$invalid;
  }

  async isPartialValid(names: Array<string> = []): Promise<boolean> {
    const waitsForValidation: Array<Promise<boolean>> = names.map((name) =>
      (this.v$[name] as Validation).$validate()
    );

    return (await Promise.all(waitsForValidation)).every((_) => _);
  }

  resetValidation(names: Array<string> = []): void {
    if (names.length > 0) names.forEach((name) => this.v$[name]?.$reset());
    else {
      (this.v$ as unknown as Validation).$reset();
    }
  }

  setServerError(name: string | undefined, message: string | boolean) {
    if (name !== undefined && this.v$[name]) {
      this.v$[name].$reset();
      this.v$[name].$externalResults.splice(0, 1);

      if (typeof message === "string")
        this.v$[name].$externalResults[0] = createServerError(name, message);

      this.v$[name].$touch();
    }
  }
}

// https://github.com/kaorun343/vue-property-decorator
// https://class-component.vuejs.org/guide/custom-decorators.html
export const Submit = (args: FormSubmitArgs = {}) => {
  return createDecorator((options, key) => {
    const originalMethod = options.methods[key];

    options.methods[key] = async function wrapperMethod(...argv: unknown[]) {
      if (this.state$.is("SUBMITTING")) return;

      try {
        this.setValidity(true);
        this.setServerError(args.model, false);

        this.state$.set("SUBMITTING");
        this.$emit("state:submitting");

        if ((await originalMethod.apply(this, argv)) !== false) {
          this.$emit("form:success");
        }
      } catch (e) {
        const message = (e as Error).message;
        const apolloError = e as ApolloError;

        const error = {
          message,
          description: [
            ...parseGraphQLErrors(apolloError?.graphQLErrors),
            ...parseNetworkErrors(
              (apolloError?.networkError as any)?.result?.errors
            ),
          ],
        };

        this.setValidity(false, error);
        this.setServerError(args.model, message);
        this.$emit("form:error", error);

        Sentry.captureException(e as Error);
      } finally {
        this.state$.set("NORMAL");
        this.$emit("state:normal");
      }
    };
  });
};

// https://github.com/mesemus/vuelidate-property-decorators
export const Model = (rule: ValidationArgs = {}) => {
  return createDecorator((options, key) => {
    if (!options.model$) options.model$ = {};
    if (!options.validations) options.validations = () => options.model$;
    if (rule) options.model$[key] = rule;
  });
};
