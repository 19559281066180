import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["href", "size"]
const _hoisted_2 = ["href", "size", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.href)
      ? (_openBlock(), _createElementBlock("snap-link", {
          key: 0,
          href: _ctx.href,
          size: _ctx.size,
          class: "inline-block"
        }, [
          _renderSlot(_ctx.$slots, "default")
        ], 8, _hoisted_1))
      : _createCommentVNode("", true),
    (_ctx.to)
      ? (_openBlock(), _createBlock(_component_router_link, {
          key: 1,
          to: _ctx.to,
          class: "inline-block"
        }, {
          default: _withCtx(({ href, navigate }) => [
            _createElementVNode("snap-link", {
              href: href,
              size: _ctx.size,
              onClick: navigate
            }, [
              _renderSlot(_ctx.$slots, "default")
            ], 8, _hoisted_2)
          ]),
          _: 3
        }, 8, ["to"]))
      : _createCommentVNode("", true)
  ], 64))
}