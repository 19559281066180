const RGB_MASK = /^#([0-9a-f]{2})([0-9a-f]{2})([0-9a-f]{2})$/i;

export type RGB = {
  r: number;
  g: number;
  b: number;
};

export const rgb = (color: string): RGB | undefined => {
  const match = color.match(RGB_MASK);
  if (match && match.length === 4) {
    return {
      r: parseInt(match[1], 16),
      g: parseInt(match[2], 16),
      b: parseInt(match[3], 16),
    };
  } else {
    return undefined;
  }
};

export const isRGBValid = (color: string): boolean => !!color.match(RGB_MASK);

export const colorDistance = (color1: string, color2: string): number => {
  const rgb1 = rgb(color1);
  const rgb2 = rgb(color2);

  if (rgb1 && rgb2) {
    return Math.sqrt(
      Math.pow(rgb2.r - rgb1.r, 2) +
        Math.pow(rgb2.g - rgb1.g, 2) +
        Math.pow(rgb2.b - rgb1.b, 2)
    );
  } else return Infinity;
};

export const nearestColor = (
  color: string,
  colors: Array<string>
): string | undefined => {
  let D = Infinity;
  let result: string | undefined = undefined;

  colors.forEach((destination) => {
    const distance = colorDistance(color, destination);

    if (distance < D) {
      D = distance;
      result = destination;
    }
  });

  return result;
};
