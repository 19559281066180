<template>
  <snap-button
    v-if="link"
    size="xs"
    :icon="link.icon"
    :iconPosition="link.iconPosition"
    variant="tertiary"
    @click="leadTo(link.href)"
    :title="link.href"
    >{{ link.text }}
  </snap-button>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { mapGetters } from "vuex";

import {
  type Family,
  UserOccupation,
  UserWithPermissions,
} from "@/types/graphql";
import { consumersList } from "@/services/consumers.service";
import { subgraphUrl } from "@/util/api";

import FamilyQuery from "@/graphql/family.query.graphql";
import { FeatureToggle } from "@/store/treatments.module";

type BackButtonLogicResult = {
  text: string;
  icon: string;
  iconPosition: string;
  href: string;
};

@Options<BackButton>({
  computed: {
    ...mapGetters(["me", "referrer", "featureOn"]),
  },

  apollo: {
    family: {
      query: FamilyQuery,
      skip() {
        return !this.hasFamily;
      },
    },
  },
})
export default class BackButton extends Vue {
  me?: UserWithPermissions;
  featureOn?: FeatureToggle;

  referrer?: string;
  family?: Family;

  consumers: Record<string, string> = {};

  get hasFamily(): boolean {
    return (
      !!this.featureOn?.("USER-914-route-to-guardian-view") &&
      !!this.me?.hasFamily
    );
  }

  get hasChildren(): boolean {
    return (
      !!this.family?.children &&
      this.family?.children?.length > 0 &&
      !!this.family?.parents?.find((user) => user?.id === this.me?.id)
    );
  }

  get link(): BackButtonLogicResult | undefined {
    if (this.referrer) {
      return {
        text: "Back",
        icon: "arrow-left-line",
        iconPosition: "left",
        href: this.referrer,
      };
    }

    if (
      this.me?.occupation === UserOccupation.GroupLeader ||
      (this.me?.apps?.length && this.me?.apps?.length > 1)
    ) {
      return {
        text: "My Apps",
        icon: "arrow-right-line",
        iconPosition: "right",
        href: this.consumers?.["home"],
      };
    }

    if (this.hasFamily && this.hasChildren) {
      return {
        text: "Snap! Raise",
        icon: "arrow-right-line",
        iconPosition: "right",
        href: `${subgraphUrl("raise")}/participants/guardian-view`,
      };
    }

    if (
      this.me?.occupation === UserOccupation.StudentOrParticipant ||
      this.me?.occupation === UserOccupation.Parent
    ) {
      return {
        text: "Snap! Raise",
        icon: "arrow-right-line",
        iconPosition: "right",
        href: `${subgraphUrl("raise")}/participants/join-new-fundraiser`,
      };
    }

    return undefined;
  }

  async beforeMount() {
    await this.$store.dispatch("useTreatments");

    if (this.$route.query.referrer) {
      await this.$store.dispatch("storeReferrer", this.$route.query.referrer);
    }

    this.consumers = Object.fromEntries(
      (await consumersList())?.map((app) => [app.id, app.url])
    );
  }

  async leadTo(href: string) {
    await this.$store.dispatch("removeReferrer");
    location.href = href;
  }
}
</script>
