<template>
  <div class="landing min-h-screen">
    <section
      class="art hidden md:fixed md:right-0 md:min-h-screen md:w-1/2 md:px-14 md:flex md:flex-col md:justify-center lg:px-20 py-8"
    >
      <img
        src="../assets/SnapOrbit.png"
        width="510"
        height="539"
        alt="Snap! Orbit"
        class="block w-auto mb-4 px-6 lg:pl-12 lg:pr-32"
        v-if="!featureOn?.('USER-916-new-image-on-external-pages')"
      />
      <img
        src="../assets/SnapOrbit_v2.png"
        width="1182"
        height="1200"
        alt="Snap! Orbit"
        class="block w-auto mb-4 px-6 lg:pl-12 lg:pr-32"
        v-if="featureOn?.('USER-916-new-image-on-external-pages')"
      />

      <div v-if="!featureOn?.('USER-916-new-image-on-external-pages')">
        <h1 class="text-gray-900 md:text-4xl lg:text-5xl font-bold mb-4">
          Solutions that support what matters.
        </h1>
        <p class="text-sm font-light mb-4">
          Snap! Mobile supports program and group leaders, teams, and clubs with
          comprehensive industry-leading solutions.
        </p>
        <p>
          <snap-link
            href="https://snapraise.com/snap-mobile"
            size="xs"
            target="_blank"
            >Learn more about our platform.
          </snap-link>
        </p>
      </div>

      <div v-if="featureOn?.('USER-916-new-image-on-external-pages')">
        <h1 class="text-gray-900 md:text-4xl lg:text-4xl font-bold mb-4">
          The essential software platform for athletics and activities.
        </h1>
        <p class="text-sm font-light mb-4">
          Snap! Mobile supports athletic directors, program and group leaders,
          teams, and clubs with safe, secure, industry-leading solutions.
        </p>
        <p>
          <snap-link
            href="https://snapraise.com/snap-mobile"
            size="xs"
            target="_blank"
            >Learn more about our platform.
          </snap-link>
        </p>
      </div>
    </section>

    <section
      class="content bg-white p-4 min-h-screen md:flex md:flex-row md:w-1/2 md:relative md:rounded-none md:mt-0 lg:p-10 flex-1"
    >
      <div class="max-w-lg lg:w-3/4 mx-auto self-center">
        <snap-logo
          product="snap-logo-mobile"
          variant="1line"
          class="block md:hidden w-52 mb-6"
        ></snap-logo>

        <snap-logo
          product="snap-logo-mobile"
          variant="stacked"
          class="hidden md:block w-44 mb-6"
        ></snap-logo>

        <slot></slot>
      </div>
    </section>
  </div>

  <div
    class="fixed top-0 right-0 p-1 text-transparent z-50"
    style="font-size: 0.5rem"
  >
    {{ version }}
  </div>

  <ZendeskHelpWidget></ZendeskHelpWidget>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { mapGetters } from "vuex";

import ZendeskHelpWidget from "@/components/ZendeskHelpWidget.vue";

import { FeatureToggle } from "@/store/treatments.module";

@Options({
  components: { ZendeskHelpWidget },
  computed: {
    ...mapGetters(["featureOn"]),
  },
})
export default class ExternalPage extends Vue {
  version = process.env.VUE_APP_BUILD_ID;

  featureOn?: FeatureToggle;
}
</script>

<style scoped lang="scss">
.art {
  background-color: #f3f8fc;

  h1 {
    color: #051c31;
  }

  p {
    color: #1e293b;
  }
}
</style>
