const MAX_IMAGE_SIZE = 1024;

export const loadImage = async (
  file: File,
  maxImageSize: number = MAX_IMAGE_SIZE
): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (readerEvent) => {
      const image = new Image();

      image.onload = (/*imageEvent*/) => {
        const canvas = document.createElement("canvas");
        const width = image.width;
        const height = image.height;

        let mw = width,
          mh = height;

        if (width > height) {
          if (width > maxImageSize) {
            mh = (height * maxImageSize) / width;
            mw = maxImageSize;
          }
        } else {
          if (height > maxImageSize) {
            mw = (width * maxImageSize) / height;
            mh = maxImageSize;
          }
        }

        canvas.width = mw;
        canvas.height = mh;
        canvas.getContext("2d")?.drawImage(image, 0, 0, mw, mh);

        resolve(canvas.toDataURL("image/jpeg"));
      };

      image.onerror = (/*imageEvent*/) => {
        reject();
      };

      image.src = readerEvent.target?.result as string;
    };

    reader.readAsDataURL(file);
  });
};

export const loadText = async (file: File): Promise<string> => {
  return new Promise((resolve) => {
    const reader = new FileReader();

    reader.onload = (readerEvent) => {
      resolve(readerEvent.target?.result as string);
    };

    reader.readAsText(file);
  });
};
