<template>
  <form @submit.prevent="create" v-keyboard-submit>
    <InputField
      class="mb-4"
      label="First Name"
      type="text"
      v-model="firstName"
      :validation="v$.firstName"
      required
    ></InputField>

    <InputField
      class="mb-4"
      label="Last Name"
      type="text"
      v-model="lastName"
      :validation="v$.lastName"
      required
    ></InputField>
  </form>
</template>

<script lang="ts">
import { Options } from "vue-class-component";

import { Form, FormSubmitError, Model, Submit } from "@/core/Form";

import InputField from "@/components/snap-ui/InputField.vue";

import {
  EmojiForbiddenRule,
  RequiredRule,
  StringLengthRule,
} from "@/util/validator";
import { $notify } from "@/services/notification";
import { userChildCreate } from "@/services/user.service";

@Options({
  components: { InputField },
  watch: {
    error(value: FormSubmitError) {
      if (value)
        $notify({
          title: value.message,
          description: value.description,
          type: "danger",
        });
    },
  },
})
export default class UserChildCreateForm extends Form {
  @Model({
    required: RequiredRule("First name is required."),
    length: StringLengthRule(
      "First Name should be 1 to 50 characters long",
      1,
      50
    ),
    emoji: EmojiForbiddenRule(
      "Emojis and special characters are not supported."
    ),
  })
  firstName = "";

  @Model({
    required: RequiredRule("Last name is required."),
    length: StringLengthRule(
      "Last Name should be 1 to 50 characters long",
      1,
      50
    ),
    emoji: EmojiForbiddenRule(
      "Emojis and special characters are not supported."
    ),
  })
  lastName = "";

  @Submit()
  async create() {
    if (this.isFormValid()) {
      const child = await userChildCreate(this.firstName, this.lastName);

      $notify({
        title: `Child account ${child.firstName} ${child.lastName} has been created`,
        type: "success",
      });
    } else {
      return false;
    }
  }
}
</script>
