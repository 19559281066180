import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputField = _resolveComponent("InputField")!
  const _directive_keyboard_submit = _resolveDirective("keyboard-submit")!

  return _withDirectives((_openBlock(), _createElementBlock("form", {
    onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.create && _ctx.create(...args)), ["prevent"]))
  }, [
    _createVNode(_component_InputField, {
      class: "mb-4",
      label: "First Name",
      type: "text",
      modelValue: _ctx.firstName,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.firstName) = $event)),
      validation: _ctx.v$.firstName,
      required: ""
    }, null, 8, ["modelValue", "validation"]),
    _createVNode(_component_InputField, {
      class: "mb-4",
      label: "Last Name",
      type: "text",
      modelValue: _ctx.lastName,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.lastName) = $event)),
      validation: _ctx.v$.lastName,
      required: ""
    }, null, 8, ["modelValue", "validation"])
  ], 32)), [
    [_directive_keyboard_submit]
  ])
}