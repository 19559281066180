<template>
  <div>
    <div class="relative">
      <slot></slot>
      <div
        v-if="isDragOver"
        class="absolute inset-0 opacity-70 cursor-pointer w-full bg-white"
      ></div>
      <input
        type="file"
        id="input-file"
        @change="onFileChange($event)"
        @dragenter="isDragOver = true"
        @dragleave="isDragOver = false"
        @drop="isDragOver = false"
        class="absolute inset-0 opacity-0 cursor-pointer w-full"
        :accept="accept"
      />
    </div>

    <div class="pt-2" v-if="!!validation && validation.$invalid">
      <p class="text-sm text-red-500">
        {{ validation?.$silentErrors[0]?.$message }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { Validation } from "@vuelidate/core";

import { loadImage } from "@/util/file";

@Options<Upload>({
  props: ["accept", "modelValue", "validation"],
  emits: ["update:modelValue"],

  watch: {
    modelValue(value: string) {
      if (value === "") (this as unknown as Upload).clear();
    },
  },
})
export default class Upload extends Vue {
  accept?: string;
  validation?: Validation;

  file?: File;
  content?: string;

  isDragOver = false;

  created() {
    this.file = undefined;
    this.content = "";
  }

  async onFileChange(event: Event): Promise<void> {
    const profilePicture = event.target;

    if (profilePicture && profilePicture instanceof HTMLInputElement) {
      const files = profilePicture.files;
      if (files && files.length > 0) {
        this.file = files[0];
        this.content = await loadImage(this.file, 512);
        this.$emit("update:modelValue", this.content);
      }
    }
  }

  // async onFileDrop(event: DragEvent): Promise<void> {
  //   console.log(event.dataTransfer?.files);
  // }

  clear() {
    this.file = undefined;
    this.content = "";
    this.$emit("update:modelValue", this.content);
  }
}
</script>
