import { renderSlot as _renderSlot, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("snap-auth-input", _mergeProps(_ctx.attrs, {
    value: _ctx.modelValue,
    onSnapAuthInputUpdate: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args)))
  }), [
    _renderSlot(_ctx.$slots, "default")
  ], 16, _hoisted_1))
}