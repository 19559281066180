import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = { class: "flex gap-2 flex-wrap" }
const _hoisted_3 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputField = _resolveComponent("InputField")!
  const _component_Alert = _resolveComponent("Alert")!
  const _component_InputCodeField = _resolveComponent("InputCodeField")!
  const _directive_keyboard_submit = _resolveDirective("keyboard-submit")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.isWaitingForConfirmation)
      ? _withDirectives((_openBlock(), _createElementBlock("form", {
          key: 0,
          class: "flex items-start gap-2 my-4",
          onSubmit: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.requestUpdate()), ["prevent"]))
        }, [
          _createVNode(_component_InputField, {
            class: "block flex-1",
            label: "Phone",
            name: "phoneNumber",
            type: "text",
            modelValue: _ctx.phoneNumber,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.phoneNumber) = $event)),
            validation: _ctx.v$.phoneNumber
          }, null, 8, ["modelValue", "validation"]),
          _createElementVNode("snap-button", {
            "button-type": "submit",
            "icon-position": "left",
            shape: "circular",
            size: "md",
            variant: "primary",
            class: "mt-6",
            disabled: !_ctx.isPhoneNumberChanged || _ctx.state$.is('SUBMITTING')
          }, "Update ", 8, _hoisted_1)
        ], 32)), [
          [_directive_keyboard_submit]
        ])
      : _createCommentVNode("", true),
    (_ctx.isWaitingForConfirmation)
      ? _withDirectives((_openBlock(), _createElementBlock("form", {
          key: 1,
          class: "my-4",
          onSubmit: _cache[4] || (_cache[4] = _withModifiers(($event: any) => (_ctx.confirmUpdate()), ["prevent"]))
        }, [
          (!_ctx.state$.is('SUBMITTING'))
            ? (_openBlock(), _createBlock(_component_Alert, {
                key: 0,
                type: "warning"
              }, {
                description: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.phoneNumber), 1)
                ]),
                default: _withCtx(() => [
                  _createTextVNode(" We’ve sent a verification code to ")
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", null, [
              _createVNode(_component_InputCodeField, {
                label: "Phone Verification Code",
                modelValue: _ctx.code,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.code) = $event)),
                validation: _ctx.v$.code,
                "help-text": "Please enter your 6-digit verification code."
              }, null, 8, ["modelValue", "validation"]),
              _createElementVNode("snap-link", {
                onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.requestUpdate(true)), ["prevent"]))
              }, "Send New Code ")
            ]),
            _createElementVNode("snap-button", {
              "button-type": "submit",
              "icon-position": "left",
              shape: "circular",
              size: "md",
              variant: "primary",
              class: "mt-6",
              disabled: _ctx.state$.is('SUBMITTING') || _ctx.code.length < 6
            }, "Confirm ", 8, _hoisted_3)
          ])
        ], 32)), [
          [_directive_keyboard_submit]
        ])
      : _createCommentVNode("", true)
  ]))
}