import { mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = [".selectMenuOptions"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("snap-select-menu", _mergeProps(_ctx.attrs, {
    ".selectMenuOptions": _ctx.selectMenuOptions,
    onSnapSelectMenuUpdated: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onSelectMenuUpdated && _ctx.onSelectMenuUpdated(...args)))
  }), null, 48, _hoisted_1))
}