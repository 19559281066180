<template>
  <Select
    v-model="selected"
    :label="label"
    :options="options"
    :validation="validation"
    :placeholder="multi ? 'Select Roles' : 'Select Role'"
    :modalTitle="multi ? 'Select Roles' : 'Select Role'"
    @update:modelValue="$emit('update:modelValue', $event)"
    :multi="multi"
    search="true"
    search-method="contain"
  ></Select>
</template>

<script lang="ts">
import { watch, PropType } from "vue";
import { Options, Vue } from "vue-class-component";
import { Validation } from "@vuelidate/core";

import { SelectOptions } from "@/types/snap-ui";
import { Role } from "@/types/graphql";

import Select from "@/components/snap-ui/Select.vue";

import { getRolesList } from "@/services/roles.service";
import { roleTitle } from "@/util/roles";

@Options({
  props: {
    label: String,
    modelValue: [String, Array<string>],
    validation: Object as PropType<Validation>,
  },
  emits: ["update:modelValue"],
  components: { Select },
})
export default class RolesSelect extends Vue {
  label?: string = "";
  modelValue!: string | Array<string>;
  validation?: Validation | null = null;

  roles: Array<Role> = [];
  selected?: string | Array<string>;

  async beforeMount() {
    this.assignValue(this.modelValue);

    watch<string | Array<string>>(
      () => this.modelValue,
      (value) => this.assignValue(value)
    );

    this.roles = [...(await getRolesList())].sort((a, b) =>
      (a?.title as string) > (b?.title as string) ? 1 : -1
    );
  }

  assignValue(value: string | Array<string>) {
    if (Array.isArray(value)) {
      this.selected = [...value];
    } else {
      this.selected = value;
    }
  }

  get multi(): boolean {
    return Array.isArray(this.modelValue);
  }

  get options(): SelectOptions {
    return this.roles.map((role) => ({
      name: roleTitle(role),
      value: role.id as string,
    }));
  }
}
</script>
