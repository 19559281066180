import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "relative" }
const _hoisted_2 = {
  key: 0,
  class: "absolute inset-0 opacity-70 cursor-pointer w-full bg-white"
}
const _hoisted_3 = ["accept"]
const _hoisted_4 = {
  key: 0,
  class: "pt-2"
}
const _hoisted_5 = { class: "text-sm text-red-500" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "default"),
      (_ctx.isDragOver)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2))
        : _createCommentVNode("", true),
      _createElementVNode("input", {
        type: "file",
        id: "input-file",
        onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onFileChange($event))),
        onDragenter: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isDragOver = true)),
        onDragleave: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isDragOver = false)),
        onDrop: _cache[3] || (_cache[3] = ($event: any) => (_ctx.isDragOver = false)),
        class: "absolute inset-0 opacity-0 cursor-pointer w-full",
        accept: _ctx.accept
      }, null, 40, _hoisted_3)
    ]),
    (!!_ctx.validation && _ctx.validation.$invalid)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.validation?.$silentErrors[0]?.$message), 1)
        ]))
      : _createCommentVNode("", true)
  ]))
}