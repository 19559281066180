import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, createElementVNode as _createElementVNode, vShow as _vShow, withCtx as _withCtx, withModifiers as _withModifiers, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "login-form" }
const _hoisted_2 = { class: "text-right" }
const _hoisted_3 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormError = _resolveComponent("FormError")!
  const _component_FormErrorInline = _resolveComponent("FormErrorInline")!
  const _component_InputField = _resolveComponent("InputField")!
  const _directive_autofocus = _resolveDirective("autofocus")!
  const _directive_keyboard_submit = _resolveDirective("keyboard-submit")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.featureOn?.('USER-888-new-sign-up-view'))
      ? (_openBlock(), _createBlock(_component_FormError, {
          key: 0,
          error: _ctx.error,
          class: "mb-6 absolute top-8 inset-x-8 z-20"
        }, null, 8, ["error"]))
      : _createCommentVNode("", true),
    (_ctx.featureOn?.('USER-888-new-sign-up-view'))
      ? (_openBlock(), _createBlock(_component_FormErrorInline, {
          key: 1,
          error: _ctx.error,
          class: "mb-4"
        }, null, 8, ["error"]))
      : _createCommentVNode("", true),
    _withDirectives((_openBlock(), _createElementBlock("form", {
      onSubmit: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.login()), ["prevent"]))
    }, [
      _withDirectives(_createVNode(_component_InputField, {
        class: "mb-4",
        label: "Email",
        type: "email",
        modelValue: _ctx.email,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
        validation: _ctx.v$.email,
        onInput: _ctx.checkAuthFlow,
        "data-testid": "email"
      }, null, 8, ["modelValue", "validation", "onInput"]), [
        [_directive_autofocus]
      ]),
      _withDirectives(_createVNode(_component_InputField, {
        class: "mb-4",
        label: "Password",
        type: "password",
        modelValue: _ctx.password,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.password) = $event)),
        validation: _ctx.v$.password,
        "data-testid": "password"
      }, {
        default: _withCtx(() => [
          _createElementVNode("snap-link", {
            slot: "code",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('reset-password'))),
            size: "sm",
            "data-testid": "forgot_password"
          }, "Forgot Password? ")
        ]),
        _: 1
      }, 8, ["modelValue", "validation"]), [
        [_vShow, _ctx.flow.is('FLOW_PASSWORD')]
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("snap-button", {
          "button-type": "submit",
          shape: "circular",
          size: "lg",
          variant: "primary",
          disabled: _ctx.state$.is('SUBMITTING'),
          "data-testid": "sign_in"
        }, "Sign In ", 8, _hoisted_3)
      ])
    ], 32)), [
      [_directive_keyboard_submit]
    ])
  ]))
}