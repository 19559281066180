import { required, email, helpers } from "@vuelidate/validators";
import type { ValidationRule } from "@vuelidate/core";
import PasswordValidator from "password-validator";
import { phone } from "phone";

import { isURL } from "./helper";
import { consumersList } from "@/services/consumers.service";

export const RequiredRule = (message: string): ValidationRule => {
  return helpers.withMessage(message, required);
};

export const EmailRule = (message: string): ValidationRule => {
  return helpers.withMessage(message, email);
};

export const SameAsRule = (message: string, name: string): ValidationRule => {
  return helpers.withMessage(message, (value, siblings, vm) => {
    return value === vm[name];
  });
};

export const ValidationCodeRule = (message: string): ValidationRule => {
  return helpers.withMessage(message, helpers.regex(/^\d{6}$/));
};

export const PasswordRule = (
  message: string,
  allowEmptyPassword = false
): ValidationRule => {
  const schema = new PasswordValidator();
  /* eslint-disable */
  schema
    .is()
    .min(8)
    .is()
    .max(32)
    .has()
    .uppercase()
    .has()
    .lowercase()
    .has()
    .digits(1);
  /* eslint-enable */

  return helpers.withMessage(message, (value: string) => {
    return (
      value.trim() === "" ||
      (value === "-" && allowEmptyPassword) ||
      (value !== "-" && !!schema.validate(value))
    );
  });
};

export const ShortnameRule = (message: string): ValidationRule => {
  return helpers.withMessage(message, helpers.regex(/^[a-z0-9_]*$/));
};

export const RegExpRule = (message: string, regex: RegExp): ValidationRule => {
  return helpers.withMessage(message, helpers.regex(regex));
};

export const NotEmptyArrayRule = (message: string): ValidationRule => {
  return helpers.withMessage(message, (value) => {
    return Array.isArray(value) && value.length > 0;
  });
};

export const PhoneNumberRule = (message: string): ValidationRule => {
  return helpers.withMessage(message, (value: string) => {
    return value.trim() === "" || phone(value).isValid;
  });
};

export const ImageRule = (message: string): ValidationRule => {
  return helpers.withMessage(message, (value: string) => {
    return value.trim().startsWith("data:image/");
  });
};

export const UrlRule = (message: string): ValidationRule => {
  return helpers.withMessage(message, (value: string) => {
    return value === "" || isURL(value);
  });
};

export const ConsumerUrlRule = (message: string): ValidationRule => {
  return helpers.withMessage(
    message,
    helpers.withAsync(async (value: string) => {
      const consumers = await consumersList();
      const consumer = consumers.find((consumer) =>
        value.match(new RegExp(consumer.mask as string, "i"))
      );

      return value === "" || !!consumer;
    })
  );
};

export const EmojiForbiddenRule = (message: string): ValidationRule => {
  return helpers.withMessage(message, (value: string) => {
    const emojiPattern = /[\uD800-\uDBFF][\uDC00-\uDFFF]/;
    // const emojiPattern = /\p{Emoji}/u;

    return !emojiPattern.test(value);
  });
};

export const StringLengthRule = (
  message: string,
  min?: number,
  max?: number
): ValidationRule => {
  return helpers.withMessage(message, (value: string) => {
    if (min && value.length < min) return false;
    if (max && value.length > max) return false;
    return true;
  });
};

export const CustomRule = <T>(
  message: string,
  fn: (value: T) => boolean
): ValidationRule => {
  return helpers.withMessage(message, fn);
};
