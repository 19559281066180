import { apolloClient } from "@/providers/apollo.provider";

import {
  AffiliationTitle,
  Maybe,
  MutationError,
  MutationOrgProgramCreateArgs,
  MutationOrgTeamCreateArgs,
  MutationResponse,
  MutationUserActivityLeadsDeleteArgs,
  MutationUserActivityLeadsUpdateArgs,
  MutationUserAssociationCreateArgs,
  MutationUserAssociationDeleteArgs,
  MutationUserImpliedAffiliationDeleteArgs,
  MutationUserOrgAffiliationCreateArgs,
  MutationUserOrgAffiliationDeleteArgs,
  MutationUserOrgAffiliationUpdateArgs,
  MutationUserTeamLeadsCreateArgs,
  MutationUserTeamLeadsDeleteArgs,
  MutationUserTeamLeadsUpdateArgs,
  OrgsProperties,
  ProgramActivity,
  TeamTitle,
  OrgCampaignAffiliation,
  MutationRosterCreateArgs,
  OrgsRoster,
} from "@/types/graphql";

import OrgProgramCreateMutation from "@/graphql/orgProgramCreate.mutation.graphql";
import OrgTeamCreateMutation from "@/graphql/orgTeamCreate.mutation.graphql";
import UserActivityLeadsDeleteMutation from "@/graphql/userActivityLeadsDelete.mutation.graphql";
import UserActivityLeadsUpdateMutation from "@/graphql/userActivityLeadsUpdate.mutation.graphql";
import UserAssociationCreateMutation from "@/graphql/userAssociationCreate.mutation.graphql";
import UserAssociationDeleteMutation from "@/graphql/userAssociationDelete.mutation.graphql";
import UserImpliedAffiliationDeleteMutation from "@/graphql/userImpliedAffiliationDelete.mutation.graphql";
import UserOrgAffiliationCreateMutation from "@/graphql/userOrgAffiliationCreate.mutation.graphql";
import UserOrgAffiliationDeleteMutation from "@/graphql/userOrgAffiliationDelete.mutation.graphql";
import UserOrgAffiliationUpdateMutation from "@/graphql/userOrgAffiliationUpdate.mutation.graphql";
import UserTeamLeadsCreateMutation from "@/graphql/userTeamLeadsCreate.mutation.graphql";
import UserTeamLeadsDeleteMutation from "@/graphql/userTeamLeadsDelete.mutation.graphql";
import UserTeamLeadsUpdateMutation from "@/graphql/userTeamLeadsUpdate.mutation.graphql";
import UserGroupLeadershipQuery from "@/graphql/userGroupLeadership.query.graphql";
import RosterCreateMutation from "@/graphql/rosterCreate.mutation.graphql";
import RostersQuery from "@/graphql/rosters.query.graphql";

import { CombinedAffiliation, isTeamTitle, Relation } from "@/types/orgs";

const throwException = (errors: Maybe<MutationError>[]): never => {
  throw new Error(errors[0]?.message);
};

const parseMutationResponse = (
  response: MutationResponse
): OrgsProperties | never =>
  response.success
    ? (response.properties as OrgsProperties)
    : throwException(response.errors);

export const userAssociationCreate = async (
  association: MutationUserAssociationCreateArgs
): Promise<MutationResponse> => {
  return apolloClient
    .mutate({
      mutation: UserAssociationCreateMutation,
      variables: association,
    })
    .then(({ data }) => data.userAssociationCreate);
};

export const userAssociationDelete = async (
  association: MutationUserAssociationDeleteArgs
): Promise<MutationResponse> => {
  return apolloClient
    .mutate({
      mutation: UserAssociationDeleteMutation,
      variables: association,
    })
    .then(({ data }) => data.userAssociationDelete);
};

export const userOrgAffiliationCreate = async (
  affiliation: MutationUserOrgAffiliationCreateArgs
): Promise<OrgsProperties> => {
  return apolloClient
    .mutate({
      mutation: UserOrgAffiliationCreateMutation,
      variables: affiliation,
    })
    .then(({ data }) => parseMutationResponse(data.userOrgAffiliationCreate));
};

export const userOrgAffiliationUpdate = async (
  affiliation: MutationUserOrgAffiliationUpdateArgs
): Promise<OrgsProperties> => {
  return apolloClient
    .mutate({
      mutation: UserOrgAffiliationUpdateMutation,
      variables: affiliation,
    })
    .then(({ data }) => parseMutationResponse(data.userOrgAffiliationUpdate));
};

export const userOrgAffiliationDelete = async (
  affiliation: MutationUserOrgAffiliationDeleteArgs
): Promise<OrgsProperties> => {
  return apolloClient
    .mutate({
      mutation: UserOrgAffiliationDeleteMutation,
      variables: affiliation,
    })
    .then(({ data }) => parseMutationResponse(data.userOrgAffiliationDelete));
};

export const orgProgramCreate = async (
  program: MutationOrgProgramCreateArgs
): Promise<OrgsProperties> => {
  return apolloClient
    .mutate({
      mutation: OrgProgramCreateMutation,
      variables: program,
    })
    .then(({ data }) => parseMutationResponse(data.orgProgramCreate));
};

export const orgTeamCreate = async (
  team: MutationOrgTeamCreateArgs
): Promise<OrgsProperties> => {
  return apolloClient
    .mutate({
      mutation: OrgTeamCreateMutation,
      variables: team,
    })
    .then(({ data }) => parseMutationResponse(data.orgTeamCreate));
};

export const userTeamLeadsCreate = async (
  leadership: MutationUserTeamLeadsCreateArgs
): Promise<OrgsProperties> => {
  return apolloClient
    .mutate({
      mutation: UserTeamLeadsCreateMutation,
      variables: leadership,
    })
    .then(({ data }) => parseMutationResponse(data.userTeamLeadsCreate));
};

export const userTeamLeadsUpdate = async (
  leadership: MutationUserTeamLeadsUpdateArgs
): Promise<OrgsProperties> => {
  return apolloClient
    .mutate({
      mutation: UserTeamLeadsUpdateMutation,
      variables: leadership,
    })
    .then(({ data }) => parseMutationResponse(data.userTeamLeadsUpdate));
};

export const userTeamLeadsDelete = async (
  leadership: MutationUserTeamLeadsDeleteArgs
): Promise<OrgsProperties> => {
  return apolloClient
    .mutate({
      mutation: UserTeamLeadsDeleteMutation,
      variables: leadership,
    })
    .then(({ data }) => parseMutationResponse(data.userTeamLeadsDelete));
};

export const userActivityLeadsUpdate = async (
  leadership: MutationUserActivityLeadsUpdateArgs
) => {
  return apolloClient
    .mutate({
      mutation: UserActivityLeadsUpdateMutation,
      variables: leadership,
    })
    .then(({ data }) => parseMutationResponse(data.userActivityLeadsUpdate));
};

export const userActivityLeadsDelete = async (
  leadership: MutationUserActivityLeadsDeleteArgs
) => {
  return apolloClient
    .mutate({
      mutation: UserActivityLeadsDeleteMutation,
      variables: leadership,
    })
    .then(({ data }) => parseMutationResponse(data.userActivityLeadsDelete));
};

export const userImpliedAffiliationDelete = async (
  affiliation: MutationUserImpliedAffiliationDeleteArgs
) => {
  return apolloClient
    .mutate({
      mutation: UserImpliedAffiliationDeleteMutation,
      variables: affiliation,
    })
    .then(({ data }) =>
      parseMutationResponse(data.userImpliedAffiliationDelete)
    );
};

export const relationsCreate = async (
  userId: string,
  orgId: string,
  relations: Array<Relation>
) => {
  for (const relation of relations) {
    if (isTeamTitle(relation.title)) {
      for (const teamId of relation.teamIds || []) {
        await userTeamLeadsCreate({
          userId: userId,
          teamId: teamId,
          isConfirmed: true,
          title: relation.title as TeamTitle,
        });
      }
    } else {
      await userOrgAffiliationCreate({
        userId: userId,
        orgId: orgId,
        title: relation.title as AffiliationTitle,
        description:
          relation.title === AffiliationTitle.Other ? relation.description : "",
        isConfirmed: true,
      });
    }
  }
};

export const affiliationRemove = async (
  userId: string,
  affiliation: CombinedAffiliation,
  shouldRemoveActivity = false
) => {
  for (const relation of affiliation.affiliations) {
    await userOrgAffiliationDelete({
      orgId: affiliation.org.id,
      title: relation.title,
      userId: userId,
    });
  }

  for (const team of affiliation.teams) {
    for (const title of team.titles) {
      await userTeamLeadsDelete({
        teamId: team.team.id,
        title: title.title,
        userId: userId,
      });
    }
  }

  if (shouldRemoveActivity) {
    for (const program of affiliation.programs) {
      for (const title of program.titles) {
        if (!title.isConfirmed) {
          await userActivityLeadsDelete({
            activity: program.program.name?.toUpperCase() as ProgramActivity,
            orgId: affiliation.org.id,
            title: title.title,
            userId: userId,
          });
        }
      }
    }
  }
};

export const affiliationConfirm = async (
  userId: string,
  affiliation: CombinedAffiliation
) => {
  for (const relation of affiliation.affiliations) {
    if (!relation.isConfirmed)
      await userOrgAffiliationUpdate({
        isConfirmed: true,
        orgId: affiliation.org.id,
        title: relation.title,
        userId: userId,
      });
  }

  for (const team of affiliation.teams) {
    for (const title of team.titles) {
      if (!title.isConfirmed) {
        await userTeamLeadsUpdate({
          isConfirmed: true,
          teamId: team.team.id,
          title: title.title,
          userId: userId,
        });
      }
    }
  }

  for (const program of affiliation.programs) {
    for (const title of program.titles) {
      if (!title.isConfirmed) {
        await userActivityLeadsUpdate({
          activity: program.program.name?.toUpperCase() as ProgramActivity,
          isConfirmed: true,
          orgId: affiliation.org.id,
          title: title.title,
          userId: userId,
        });
      }
    }
  }
};

export const userGroupLeadership = async (): Promise<
  OrgCampaignAffiliation[]
> => {
  return apolloClient
    .query({
      query: UserGroupLeadershipQuery,
    })
    .then(({ data }) => data.userGroupLeadership || [])
    .catch((e) => {
      console.error(e.message);
      return [];
    });
};

export const rosterCreate = async (data: MutationRosterCreateArgs) => {
  return apolloClient
    .mutate({
      mutation: RosterCreateMutation,
      variables: data,
    })
    .then(({ data }) => {
      if (data.rosterCreate.errors && data.rosterCreate.errors.length > 0) {
        throw new Error(data.rosterCreate.errors[0].message);
      }
      return data.rosterCreate || [];
    })
    .catch((e) => {
      console.error(e.message);
      throw e;
    });
};

export const getRosters = async (orgId: string): Promise<OrgsRoster[]> => {
  return apolloClient
    .query({
      query: RostersQuery,
      variables: { orgId },
    })
    .then(({ data }) => data.rosters || [])
    .catch((e) => {
      console.error(e.message);
      return [];
    });
};
