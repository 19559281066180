import { Module, GetterTree, ActionTree, MutationTree } from "vuex";

import type { RootState } from "./index";

import type { UserChallenge } from "@/types/graphql";

export type ChallengesState = Array<UserChallenge>;

const state: ChallengesState = [];

const getters: GetterTree<ChallengesState, RootState> = {
  hasChallenges: (state) => state.length > 0,
  hasChallenge:
    (state) =>
    (name: string): boolean =>
      !!state.find((challenge) => challenge.name === name),
  nextChallenge: (state): UserChallenge | undefined =>
    state.length > 0 ? state[0] : undefined,
};

const mutations: MutationTree<ChallengesState> = {
  setChallenges(state, challenges: ChallengesState) {
    state.length = 0;
    Object.assign(state, challenges);
  },

  completeChallenge(state, name: string) {
    const challenges = state.filter((challenge) => challenge.name !== name);

    state.length = 0;
    Object.assign(state, challenges);
  },

  clearChallenges(state): void {
    state.length = 0;
  },
};

const actions: ActionTree<ChallengesState, RootState> = {
  setChallenges({ commit }, challenges?: ChallengesState) {
    if (Array.isArray(challenges)) {
      commit("setChallenges", challenges);
    }
  },

  completeChallenge({ commit }, name: string) {
    commit("completeChallenge", name);
  },
};

export const challenges: Module<ChallengesState, RootState> = {
  state,
  getters,
  mutations,
  actions,
};
