<template>
  <form @submit.prevent="update()">
    <snap-avatar
      :image-src="avatar"
      size="profile-lg"
      class="text-center block mb-2"
    ></snap-avatar>

    <div class="text-center mb-3 md:hidden">
      <Upload
        v-model="profilePicture"
        :validation="v$.profilePicture"
        class="inline-block cursor-pointer"
        accept="image/*"
      >
        <snap-button icon="camera-solid" size="xs" variant="tertiary"
          >Change
        </snap-button>
      </Upload>
    </div>

    <p class="text-sm text-center">
      A picture helps people recognize you and lets you know when you’re signed
      in to your account
    </p>

    <Upload
      v-model="profilePicture"
      :validation="v$.profilePicture"
      class="hidden md:block"
      accept="image/*"
    >
      <div
        class="text-center text-sm my-4 p-4 bg-blue-50 border-2 border-gray-200 border-dashed rounded-xl"
      >
        <snap-icon
          icon="cloud-upload-solid"
          color="#2563EB"
          bg-color="#ffffff"
          size="xl"
          state="rounded"
          class="mb-2 border border-solid border-blue-600 rounded-full"
        ></snap-icon>
        <p class="font-semibold">
          Drag & Drop Files or
          <span class="text-blue-600 cursor-pointer">Browse</span>
        </p>
        <p class="text-gray-500">Supported formats: PNG or JPG up to 5MB</p>
      </div>
    </Upload>
  </form>
</template>

<script lang="ts">
import { Options } from "vue-class-component";
import { mapGetters } from "vuex";

import { Form, FormSubmitError, Model, Submit } from "@/core/Form";
import Upload from "@/components/ui/Upload.vue";
import { User } from "@/types/graphql";
import { $notify } from "@/services/notification";
import { ImageRule } from "@/util/validator";

@Options({
  components: { Upload },
  computed: {
    ...mapGetters(["me"]),
  },

  watch: {
    error(value: FormSubmitError) {
      if (value)
        $notify({
          title: value.message,
          description: value.description,
          type: "danger",
        });
    },
  },
})
export default class ProfilePictureUpdateForm extends Form {
  me?: User;

  @Model({ image: ImageRule("Please choose the picture") })
  profilePicture = "";

  get hasProfilePicture(): boolean {
    return this.me?.profilePicture !== "";
  }

  get avatar(): string {
    return this.profilePicture !== ""
      ? this.profilePicture
      : this.me?.profilePicture || "";
  }

  @Submit()
  async update(): Promise<void | boolean> {
    if (await this.isPartialValid(["profilePicture"])) {
      await this.$store.dispatch("userUpdate", {
        id: this.me?.id,
        profilePicture:
          this.profilePicture !== "" ? this.profilePicture : undefined,
      });

      this.resetValidation(["profilePicture"]);
    } else {
      return false;
    }
  }

  @Submit()
  async remove(): Promise<void | boolean> {
    await this.$store.dispatch("userUpdate", {
      id: this.me?.id,
      profilePicture: "",
    });

    return false;
  }
}
</script>
