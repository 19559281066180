<template>
  <Select
    v-model="language"
    :label="label"
    :options="Languages"
    placeholder="Select Your Language"
    modalTitle="Select Your Language"
    @update:modelValue="$emit('update:modelValue', $event)"
  ></Select>
</template>

<script lang="ts">
import { watch } from "vue";
import { Options } from "vue-class-component";

import Field from "@/core/Field";
import Select from "@/components/snap-ui/Select.vue";
import { Languages } from "@/services/languages";

@Options<LanguageSelect>({
  props: ["label"],
  components: { Select },
})
export default class LanguageSelect extends Field {
  label?: string;
  language?: string;

  Languages = Languages;

  beforeMount() {
    this.language = this.modelValue;

    watch<string>(
      () => this.modelValue,
      (value) => (this.language = value)
    );
  }
}
</script>
