import { renderSlot as _renderSlot, withModifiers as _withModifiers, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("snap-input", _mergeProps({ class: "block bg-transparent" }, _ctx.attrs, {
    value: _ctx.modelValue,
    onInput: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.onInput($event)), ["prevent"])),
    onSnapInputFocus: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('focus', $event))),
    onSnapInputBlur: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('blur', $event)))
  }), [
    _renderSlot(_ctx.$slots, "default")
  ], 16, _hoisted_1))
}