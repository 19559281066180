<template>
  <snap-link :href="href" :size="size" class="inline-block" v-if="href">
    <slot />
  </snap-link>

  <router-link
    :to="to"
    v-slot="{ href, navigate }"
    class="inline-block"
    v-if="to"
  >
    <snap-link :href="href" :size="size" @click="navigate">
      <slot />
    </snap-link>
  </router-link>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({
  props: ["href", "to", "size"],
})
export default class Link extends Vue {
  href?: string;
  to?: object;
  size?: string;
}
</script>
