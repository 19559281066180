<template>
  <div class="block cursor-pointer relative">
    <snap-avatar
      :image-src="user?.profilePicture || ''"
      :size="mq.mobile ? 'md' : 'lg'"
      class="rounded-full border border-blue-600"
    ></snap-avatar>
    <span
      v-if="icon"
      class="block absolute right-0 -bottom-px bg-blue-500 rounded-full border-2 border-white w-6 h-6 leading-6 text-center"
    >
      <snap-icon
        v-if="icon"
        :icon="icon"
        color="#fff"
        size="xs"
        class="pt-0.5"
      ></snap-icon>
    </span>
  </div>
</template>

<script lang="ts">
import { PropType } from "vue";
import { Options, Vue } from "vue-class-component";

import { User } from "@/types/graphql";
import { mediaQueryState } from "@/util/mediaQuery";

@Options({
  props: {
    user: Object as PropType<User>,
    icon: String,
  },
})
export default class Avatar extends Vue {
  user?: User;
  icon?: string;

  mq = mediaQueryState;
}
</script>
