import { Module, GetterTree, ActionTree, MutationTree } from "vuex";

import type { TransportEnum, TransportType } from "@/types/graphql";
import type { RootState } from "./index";

export type ConfirmationData = {
  attempts?: number;
  availableTransports?: Array<TransportEnum>;
  email?: string;
  occupation?: string;
  usedTransports?: Array<TransportType>;
};

const state = {};

const getters: GetterTree<ConfirmationData, RootState> = {
  confirmationAttempts: (state) => state.attempts,
  confirmationAvailableTransports: (state) => state.availableTransports,
  confirmationEmail: (state) => state.email,
  confirmationOccupation: (state) => state.occupation,
  confirmationUsedTransports: (state) => state.usedTransports,

  isWaitingForConfirmation: (state) =>
    state.email !== undefined && Array.isArray(state.usedTransports),
};

const mutations: MutationTree<ConfirmationData> = {
  updateConfirmationState(state, confirmation: ConfirmationData) {
    if (confirmation.email) {
      state.email = confirmation.email;
    }

    if (confirmation.occupation) {
      state.occupation = confirmation.occupation;
    }

    if (Array.isArray(confirmation.availableTransports)) {
      state.availableTransports = [...confirmation.availableTransports];
    }

    if (Array.isArray(confirmation.usedTransports)) {
      state.usedTransports = [...confirmation.usedTransports];
    }

    if (state.attempts === undefined) {
      state.attempts = 1;
    } else {
      state.attempts = state.attempts + 1;
    }
  },

  clearConfirmationState(state) {
    delete state.attempts;
    delete state.availableTransports;
    delete state.email;
    delete state.usedTransports;
    delete state.occupation;
  },
};

const actions: ActionTree<ConfirmationData, RootState> = {
  setConfirmationData({ commit }, confirmation: ConfirmationData) {
    commit("updateConfirmationState", confirmation);
  },

  clearConfirmationData({ commit }) {
    commit("clearConfirmationState");
  },
};

export const confirmation: Module<ConfirmationData, RootState> = {
  state,
  getters,
  mutations,
  actions,
};
