<template>
  <Teleport to="body">
    <div
      class="notifications fixed h-auto inset-x-2 bottom-2 pt-6 md:top-4 md:right-4 md:w-1/2 md:bottom-auto md:left-auto md:pt-0 md:pl-6 lg:w-1/3 transform transition-transform"
      :class="classState"
    >
      <!--    <div
      class="
        notification
        bg-white
        shadow-md
        p-4
        border border-gray-300 border-l-4
        rounded-r-md
      "
      :style="{ borderLeftColor: borderColor }"
    >
      <p class="font-bold">{{ currentNotification?.title }}</p>
      <p class="text-sm" v-if="currentNotification?.description">
        {{ currentNotification?.description }}
      </p>

      <p class="text-right mt-2" v-if="currentNotification?.name === 'alert'">
        <snap-button size="xs" variant="primary" @click="close">OK</snap-button>
      </p>

      <p class="text-right mt-2" v-if="currentNotification?.name === 'confirm'">
        <snap-button
          size="xs"
          variant="tertiary"
          class="inline-block mr-2"
          @click="close(false)"
          >Cancel</snap-button
        >
        <snap-button
          size="xs"
          variant="primary"
          class="inline-block"
          @click="close(true)"
          >OK</snap-button
        >
      </p>
    </div>-->

      <snap-alert
        v-if="currentNotification?.id !== ''"
        :type="currentNotification?.type"
        close="true"
        border="true"
        @snap-alert-closed="close(false)"
      >
        <snap-alert-title>{{ currentNotification?.title }}</snap-alert-title>
        <snap-alert-description v-if="currentNotification?.description">
          <ul v-if="Array.isArray(currentNotification?.description)">
            <li v-for="text in currentNotification?.description" :key="text">
              {{ text }}
            </li>
          </ul>
          <span v-else>{{ currentNotification?.description }}</span>
        </snap-alert-description>

        <snap-alert-action
          slot="start"
          v-show="currentNotification?.name === 'alert'"
        >
          <snap-link
            text="OK"
            @click="close(true)"
            size="sm"
            :variant="currentNotification?.type"
          ></snap-link>
        </snap-alert-action>

        <snap-alert-action
          slot="start"
          v-show="currentNotification?.name === 'confirm'"
        >
          <snap-link
            text="OK"
            @click="close(true)"
            size="sm"
            :variant="currentNotification?.type"
          ></snap-link>
        </snap-alert-action>

        <snap-alert-action
          slot="end"
          v-show="currentNotification?.name === 'confirm'"
        >
          <snap-link
            text="Cancel"
            @click="close(false)"
            size="sm"
            :variant="currentNotification?.type"
          ></snap-link>
        </snap-alert-action>
      </snap-alert>
    </div>
  </Teleport>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

import { $on, $off, $emit } from "@/services/event";
import type { NotificationPayload } from "@/services/notification";
import { sleep } from "@/util/helper";

type NotificationName = "alert" | "confirm";
type NotificationData = NotificationPayload & {
  name?: NotificationName;
};

// const BORDER_COLOR = {
//   danger: "rgba(239, 68, 68)",
//   warning: "rgba(245, 158, 11)",
//   success: "rgba(16, 185, 129)",
//   info: "rgba(59, 130, 246)",
// };

@Options({})
export default class Notification extends Vue {
  queue: Array<NotificationPayload> = [];
  currentNotification: NotificationData = { id: "" };

  beforeMount() {
    $on("notification:alert.open", this.alert);
    $on("notification:confirm.open", this.confirm);
  }

  beforeUnmount() {
    $off("notification:alert.open", this.alert);
    $off("notification:confirm.open", this.confirm);
  }

  get classState() {
    return this.currentNotification.id === ""
      ? ["translate-y-full md:translate-y-0 md:translate-x-full"]
      : [];
  }

  // get borderColor() {
  //   return this.currentNotification?.type &&
  //     BORDER_COLOR[this.currentNotification.type]
  //     ? BORDER_COLOR[this.currentNotification.type]
  //     : "rgba(209, 213, 219)";
  // }

  alert(payload: NotificationPayload) {
    this.open({
      name: "alert",
      id: payload.id,
      title: payload.title,
      description: payload.description,
      type: payload.type,
    });
  }

  confirm(payload: NotificationPayload) {
    this.open({
      name: "confirm",
      id: payload.id,
      title: payload.title,
      description: payload.description,
      type: payload.type,
    });
  }

  open(data: NotificationData) {
    if (this.currentNotification.id === "") {
      this.currentNotification = data;
    } else {
      this.queue.push(data);
    }
  }

  async close(result?: boolean) {
    const { id, name } = this.currentNotification as NotificationData;
    this.currentNotification.id = "";

    $emit(`notification:${name}.close`, { id, result });

    const next = this.queue.shift();
    if (next) {
      await sleep(500);
      this.open(next);
    }
  }
}
</script>

<style scoped lang="scss">
.notifications {
  z-index: 10010;
}
</style>
