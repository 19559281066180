<template>
  <slot v-bind="{ ...me }" v-if="me.id"></slot>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { mapGetters } from "vuex";

import type { User } from "@/types/graphql";

@Options({
  computed: {
    ...mapGetters(["me"]),
  },
})
export default class Me extends Vue {
  me?: User;
}
</script>

<style scoped lang="scss"></style>
