import { NavigationGuard } from "vue-router";

import store from "@/store";
import { invite } from "@/services/invite.service";

export const InviteCtrl: NavigationGuard = async (to, from, next) => {
  const { id } = to.params;
  const { source = "direct" } = to.query;

  if (typeof id === "string") {
    const {
      email,
      type,
      phoneNumber,
      requester,
      params,
      joincode,
      flow,
      flowParams,
    } = await invite(id);

    if (flow) {
      store.commit("setInvite", {
        id,
        email,
        type,
        phoneNumber,
        requester,
        joincode,
        params,
        source,
      });
      return next({ name: flow, query: flowParams || {} });
    }
  }

  return next({ name: "home" });
};
