import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "block cursor-pointer relative" }
const _hoisted_2 = ["image-src", "size"]
const _hoisted_3 = {
  key: 0,
  class: "block absolute right-0 -bottom-px bg-blue-500 rounded-full border-2 border-white w-6 h-6 leading-6 text-center"
}
const _hoisted_4 = ["icon"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("snap-avatar", {
      "image-src": _ctx.user?.profilePicture || '',
      size: _ctx.mq.mobile ? 'md' : 'lg',
      class: "rounded-full border border-blue-600"
    }, null, 8, _hoisted_2),
    (_ctx.icon)
      ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
          (_ctx.icon)
            ? (_openBlock(), _createElementBlock("snap-icon", {
                key: 0,
                icon: _ctx.icon,
                color: "#fff",
                size: "xs",
                class: "pt-0.5"
              }, null, 8, _hoisted_4))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}