<template>
  <Teleport to="body">
    <Transition name="drawer">
      <div
        v-if="isOpen"
        class="drawer fixed bg-white shadow-md inset-0 flex flex-col md:inset-y-0 md:right-0 md:left-auto md:w-2/3 lg:w-1/3"
        role="dialog"
      >
        <header class="flex flex-row items-center px-5 py-2">
          <div class="pr-2" v-if="isBack">
            <snap-link
              size="base"
              variant="default"
              icon="arrow-left-solid"
              icon-position="left"
              @click="$emit('back')"
            ></snap-link>
          </div>

          <div class="flex-1 pr-2 text-base font-bold text-gray-800">
            {{ title }}
          </div>

          <snap-close-button color="gray" @click="close"></snap-close-button>
        </header>

        <div class="flex-1 overflow-x-hidden overflow-y-auto">
          <div class="mx-5 my-3">
            <slot :close="close" :state="state"></slot>
          </div>
        </div>

        <footer class="px-5 py-2 flex md:justify-end">
          <slot name="actions" :close="close" :state="state"></slot>
        </footer>
      </div>
    </Transition>
  </Teleport>
</template>

<script lang="ts">
import { Options } from "vue-class-component";

import Modal from "@/core/Modal";

@Options({
  props: { isBack: Boolean },
  emits: ["back"],
})
export default class Drawer extends Modal {
  isBack = false;
}
</script>

<style scoped lang="scss">
.drawer-enter-active,
.drawer-leave-active {
  transition: transform 500ms ease-in-out;
}

.drawer-enter-from,
.drawer-leave-to {
  transform: translate(0, 100%);

  @screen md {
    transform: translate(100%, 0);
  }
}

.drawer-enter-to,
.drawer-leave-from {
  transform: translate(0, 0);
}

.drawer {
  z-index: 10005;
}

header {
  border-bottom: 1px solid #e2e8f0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

footer {
  border-top: 1px solid #e2e8f0;
  box-shadow: 0 -1px 2px rgba(0, 0, 0, 0.05);

  &:empty {
    display: none;
  }

  :slotted(snap-button) {
    flex-grow: 1;

    @screen md {
      flex-grow: 0;
    }
  }

  :slotted(snap-button + snap-button) {
    margin-left: 0.5rem;
  }
}
</style>
