import { AuthenticationFlow } from "@/types/graphql";
import { serverApiUrl } from "./api";

type ProviderType = {
  name: string;
  mask: RegExp;
  redirectUrl: string;
};

const ProviderList: Array<ProviderType> = [
  {
    name: "azure",
    mask: /^[^+]+@(snapraise.com|snap-raise.com)$/i,
    redirectUrl: serverApiUrl("/auth/azure"),
  },
];

export const authenticationFlow = (email: string): AuthenticationFlow => {
  return ProviderList.reduce(
    (flow: AuthenticationFlow, provider: ProviderType) => {
      if (provider.mask.test(email))
        return {
          name: provider.name,
          redirectUrl: provider.redirectUrl,
        };
      else return flow;
    },
    { name: "password" }
  );
};

export const isInternalEmail = (email?: string): boolean =>
  !!email && authenticationFlow(email)?.name !== "password";
