<template>
  <div>
    <InputField
      class="inline-block w-full"
      icon="search-solid"
      icon-position="left"
      placeholder="Search orgs by name..."
      v-model="search"
    ></InputField>

    <Loading
      class="w-10 h-10 mx-auto my-10"
      v-if="$apollo.queries.orgs.loading"
    ></Loading>

    <div
      v-if="!$apollo.queries.orgs.loading && orgs?.length > 0"
      class="text-sm text-gray-800 py-3"
    >
      <div
        v-for="org in orgs"
        :key="org.id"
        class="cursor-pointer px-4 py-2 hover:bg-gray-200"
        @click="$emit('select', org)"
      >
        <div class="font-semibold">{{ org.name }}</div>
        <div>{{ orgAddress(org) }}</div>
      </div>
    </div>

    <div
      v-if="!$apollo.queries.orgs.loading && orgs?.length === 0"
      class="py-3 text-center"
    >
      Not found.
    </div>
  </div>

  <div v-if="canAddOrg"></div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { Org, QueryOrgsArgs } from "@/types/graphql";

import InputField from "@/components/snap-ui/InputField.vue";

import OrgsQuery from "@/graphql/orgs.query.graphql";
import { orgAddress } from "@/util/orgs";
import Loading from "@/components/ui/Loading.vue";

@Options({
  components: { Loading, InputField },
  props: {
    canAddOrg: Boolean,
  },
  emits: ["select", "add"],

  apollo: {
    orgs: {
      query: OrgsQuery,
      variables(): QueryOrgsArgs {
        return { nameIncludes: this.search };
      },
      skip() {
        return !this.isSearchValid;
      },
      context: {
        debounceKey: "orgs",
        debounceTimeout: 1000,
      },
    },
  },
})
export default class OrgsSearchList extends Vue {
  orgs?: Array<Org>;

  search = "";

  orgAddress = orgAddress;

  get isSearchValid(): boolean {
    return this.search.length > 3;
  }
}
</script>
