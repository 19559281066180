import { Module, GetterTree, ActionTree, MutationTree } from "vuex";
import type { User, UserInviteParams } from "@/types/graphql";

import type { RootState } from "./index";

export type InviteSource = "direct" | "email" | "sms";

export type InviteState = {
  id?: string;
  email?: string;
  phoneNumber?: string;
  type?: string;
  requester?: User;
  joincode?: string;
  params?: UserInviteParams;
  source?: InviteSource;
};

const state: InviteState = {};

const getters: GetterTree<InviteState, RootState> = {
  inviteId: (state) => state.id,
  inviteEmail: (state) => state.email,
  invitePhoneNumber: (state) => state.phoneNumber,
  inviteType: (state) => state.type,
  inviteRequester: (state) => state.requester,
  inviteParams: (state) => state.params,
  joincode: (state) => state.joincode,
  inviteSource: (state): InviteSource => state.source || "direct",
};

const mutations: MutationTree<InviteState> = {
  setInvite(state, invite: InviteState) {
    Object.assign(state, invite);
  },

  clearInvite(state) {
    delete state.id;
    delete state.email;
    delete state.phoneNumber;
    delete state.type;
    delete state.requester;
    delete state.joincode;
    delete state.params;
  },
};

const actions: ActionTree<InviteState, RootState> = {};

export const invite: Module<InviteState, RootState> = {
  state,
  getters,
  mutations,
  actions,
};
