<template>
  <div
    v-if="error"
    class="flex items-start gap-2"
    data-testid="form-error-inline"
  >
    <snap-icon
      icon="exclamation-circle-solid"
      color="#DC2626"
      size="sm"
    ></snap-icon>
    <div class="flex-1 text-red-600 text-sm font-semibold">
      <p>{{ error.message }}</p>
      <ul
        role="list"
        v-if="Array.isArray(error.description) && error.description.length > 0"
      >
        <li v-for="text in error.description" :key="text">{{ text }}</li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import { PropType } from "vue";
import { Options, Vue } from "vue-class-component";

import { FormSubmitError } from "@/core/Form";

@Options({
  props: {
    error: Object as PropType<FormSubmitError>,
  },
})
export default class FormErrorInline extends Vue {
  error?: FormSubmitError;
}
</script>
