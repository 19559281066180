<template>
  <PageContent title="Guardian Portal">
    <template #actions></template>

    <FamilyTree
      ref="familyTree"
      @child:create="onCreateChild"
      @child:update="onUpdateChild"
    ></FamilyTree>
  </PageContent>

  <Drawer ref="childCreatePanel" title="Add Child Account">
    <template v-slot="{ close, state }">
      <UserChildCreateForm
        ref="childCreateForm"
        @form:success="
          close();
          refetchFamilyTree();
        "
        @state:normal="state.set('NORMAL')"
        @state:submitting="state.set('SUBMITTING')"
      ></UserChildCreateForm>
    </template>

    <template v-slot:actions="{ close, state }">
      <snap-button
        button-type="button"
        size="sm"
        variant="tertiary"
        @click="close"
        :disabled="state.is('SUBMITTING')"
        full-width
        >Cancel
      </snap-button>

      <snap-button
        button-type="button"
        size="sm"
        variant="primary"
        @click="($refs.childCreateForm as UserChildCreateForm)?.create()"
        class="inline-block"
        :disabled="state.is('SUBMITTING')"
        full-width
        >Save
      </snap-button>
    </template>
  </Drawer>

  <Drawer ref="childUpdatePanel" title="Update Child Account">
    <template v-slot="{ close, state }">
      <UserChildUpdateForm
        :child="child"
        ref="childUpdateForm"
        @form:success="
          close();
          refetchFamilyTree();
        "
        @state:normal="state.set('NORMAL')"
        @state:submitting="state.set('SUBMITTING')"
      ></UserChildUpdateForm>
    </template>

    <template v-slot:actions="{ close, state }">
      <snap-button
        button-type="button"
        size="sm"
        variant="tertiary"
        @click="close"
        :disabled="state.is('SUBMITTING')"
        full-width
        >Cancel
      </snap-button>

      <snap-button
        button-type="button"
        size="sm"
        variant="primary"
        @click="($refs.childUpdateForm as UserChildUpdateForm)?.update()"
        class="inline-block"
        :disabled="state.is('SUBMITTING')"
        full-width
        >Save
      </snap-button>
    </template>
  </Drawer>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { mapGetters } from "vuex";

import Drawer from "@/components/ui/Drawer.vue";
import FamilyTree from "@/components/internal/family/FamilyTree.vue";
import PageContent from "@/components/internal/common/PageContent.vue";
import UserChildCreateForm from "@/components/internal/family/UserChildCreateForm.vue";
import UserChildUpdateForm from "@/components/internal/family/UserChildUpdateForm.vue";

import { User, UserWithPermissions } from "@/types/graphql";
import { FeatureToggle } from "@/store/treatments.module";
import { DrawerManager, useDrawers } from "@/util/drawers";

@Options({
  components: {
    UserChildUpdateForm,
    Drawer,
    FamilyTree,
    PageContent,
    UserChildCreateForm,
  },
  computed: {
    ...mapGetters(["me", "featureOn"]),
  },
})
export default class Family extends Vue {
  me?: UserWithPermissions;
  featureOn?: FeatureToggle;

  drawers!: DrawerManager;
  child?: User;

  mounted() {
    this.drawers = useDrawers(this, ["childCreatePanel", "childUpdatePanel"]);
  }

  onCreateChild() {
    this.drawers.open("childCreatePanel");
  }

  onUpdateChild(child: User) {
    this.child = child;
    this.drawers.open("childUpdatePanel");
  }

  refetchFamilyTree() {
    (this.$refs.familyTree as FamilyTree).refetch();
  }
}
</script>
