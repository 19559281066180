import { apolloClient } from "@/providers/apollo.provider";

import type {
  Permission,
  Role,
  RoleArguments,
  UserPermissionAssignment,
  UserPermissionsList,
} from "@/types/graphql";

import RolesQuery from "@/graphql/roles.query.graphql";
import RoleCreateQuery from "@/graphql/roleCreate.mutation.graphql";
import RoleUpdateMutation from "@/graphql/roleUpdate.mutation.graphql";
import RoleDeleteMutation from "@/graphql/roleDelete.mutation.graphql";
import RolePermissionsUpdateMutation from "@/graphql/rolePermissionsUpdate.mutation.graphql";

import PermissionsQuery from "@/graphql/permissions.query.graphql";

import UserPermissionsUpdateMutation from "@/graphql/userPermissionsUpdate.mutation.graphql";
import UserPermissionsQuery from "@/graphql/userPermissions.query.graphql";

export const getRolesList = async (): Promise<Array<Role>> => {
  return apolloClient
    .query({ query: RolesQuery })
    .then(({ data }) => data.roles)
    .catch(() => []);
};

export const getRoleById = async (id: string): Promise<Role> => {
  return apolloClient
    .query({ query: RolesQuery, variables: { id } })
    .then(({ data }) => data.roles[0]);
};

export const getPermissionsList = async (): Promise<Array<Permission>> => {
  return apolloClient
    .query({ query: PermissionsQuery })
    .then(({ data }) => data.permissions)
    .catch(() => []);
};

export const rolePermissionsUpdate = async (
  roleId: string,
  permissionIds: Array<string>
): Promise<Role> => {
  return apolloClient
    .mutate({
      mutation: RolePermissionsUpdateMutation,
      variables: { roleId, permissionIds },
      // update(cache, { data: { updateRolePermissions } }) {
      //   cache.modify({
      //     fields: {
      //       roles(existingRoles: Array<Role> = []) {
      //         return existingRoles.map((role) => {
      //           if (
      //             role.id === updateRolePermissions.id &&
      //             role.permissions === null
      //           ) {
      //             return {
      //               ...role,
      //               permissions: updateRolePermissions.permissions,
      //             };
      //           } else {
      //             return role;
      //           }
      //         });
      //       },
      //     },
      //   });
      // },
    })
    .then(({ data }) => {
      return data.rolePermissionsUpdate;
    });
};

export const roleCreate = async (data: RoleArguments): Promise<Role> => {
  return apolloClient
    .mutate({
      mutation: RoleCreateQuery,
      variables: { data },
      // update(cache, { data: { roleCreate } }) {
      //   cache.modify({
      //     fields: {
      //       roles(existingRoles: Array<Role> = []) {
      //         return [...existingRoles, roleCreate];
      //       },
      //     },
      //   });
      // },
    })
    .then(({ data }) => {
      return data.roleCreate;
    });
};

export const roleUpdate = async (
  id: string,
  data: RoleArguments
): Promise<Role> => {
  return apolloClient
    .mutate({
      mutation: RoleUpdateMutation,
      variables: {
        id,
        data,
      },
    })
    .then(({ data }) => {
      return data.roleUpdate;
    });
};

export const roleDelete = async (id: string): Promise<Role> => {
  return apolloClient
    .mutate({
      mutation: RoleDeleteMutation,
      variables: { id },
      // update(cache, { data: { roleDelete } }) {
      //   cache.modify({
      //     fields: {
      //       roles(existingRoles: Array<Role> = []) {
      //         return existingRoles.filter((role) => role.id !== roleDelete.id);
      //       },
      //     },
      //   });
      // },
    })
    .then(({ data }) => {
      return data.deleteRole;
    });
};

export const userPermissions = async (
  userId: string
): Promise<UserPermissionsList> => {
  return apolloClient
    .query({ query: UserPermissionsQuery, variables: { userId } })
    .then(({ data }) => {
      return data.userPermissions;
    });
};

export const userPermissionsUpdate = async (
  userId: string,
  roleIds: Array<string>,
  permissions: Array<UserPermissionAssignment>,
  negativePermissions: Array<UserPermissionAssignment>
): Promise<UserPermissionsList> => {
  return apolloClient
    .mutate({
      mutation: UserPermissionsUpdateMutation,
      variables: { userId, roleIds, permissions, negativePermissions },
    })
    .then(({ data }) => {
      return data.userPermissionsUpdate;
    });
};
