import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["icon", "iconPosition", "title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.link)
    ? (_openBlock(), _createElementBlock("snap-button", {
        key: 0,
        size: "xs",
        icon: _ctx.link.icon,
        iconPosition: _ctx.link.iconPosition,
        variant: "tertiary",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.leadTo(_ctx.link.href))),
        title: _ctx.link.href
      }, _toDisplayString(_ctx.link.text), 9, _hoisted_1))
    : _createCommentVNode("", true)
}