import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputField = _resolveComponent("InputField")!
  const _directive_keyboard_submit = _resolveDirective("keyboard-submit")!

  return _withDirectives((_openBlock(), _createElementBlock("form", {
    onSubmit: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.update()), ["prevent"]))
  }, [
    _createVNode(_component_InputField, {
      class: "block mb-4",
      label: "Current Password",
      name: "oldPassword",
      type: "password",
      modelValue: _ctx.oldPassword,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.oldPassword) = $event)),
      validation: _ctx.v$.oldPassword
    }, null, 8, ["modelValue", "validation"]),
    _createVNode(_component_InputField, {
      class: "block mb-4",
      label: "New Password",
      name: "newPassword",
      type: "password",
      modelValue: _ctx.newPassword,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.newPassword) = $event)),
      validation: _ctx.v$.newPassword,
      "help-text": "Your password must be 8-32 characters, and include at least one lowercase letter, one uppercase letter, and a number."
    }, null, 8, ["modelValue", "validation"]),
    _createVNode(_component_InputField, {
      class: "block mb-4",
      label: "Confirm Password",
      name: "confirmPassword",
      type: "password",
      modelValue: _ctx.confirmPassword,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.confirmPassword) = $event)),
      validation: _ctx.v$.confirmPassword
    }, null, 8, ["modelValue", "validation"])
  ], 32)), [
    [_directive_keyboard_submit]
  ])
}