import { NavigationGuard } from "vue-router";

import store from "@/store";
import { redirectOnExpectation } from "@/util/consumer";

export const UserImpersonateCtrl: NavigationGuard = async (to, from, next) => {
  const { id } = to.params;

  if (id) {
    try {
      await store.dispatch("userImpersonate", id);
      if (await redirectOnExpectation("impersonate")) {
        location.replace("/");
      }
      // eslint-disable-next-line no-empty
    } catch {}
  }

  return next({ name: "home" });
};

export const UserImpersonateLogoutCtrl: NavigationGuard = async (
  to,
  from,
  next
) => {
  try {
    await store.dispatch("userImpersonateLogout");
    if (await redirectOnExpectation("impersonate")) {
      location.replace("/");
    }
    // eslint-disable-next-line no-empty
  } catch {}

  return next({ name: "home" });
};
