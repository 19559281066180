import { Module, GetterTree, ActionTree, MutationTree } from "vuex";

import type { RootState } from "./index";

type LoadingStatus = "loading" | "ready" | "redirecting";

import { config } from "@/services/config";

export type UIState = {
  status: LoadingStatus;
  theme: string;
  referrer?: string;
};

const state: UIState = {
  status: "loading",
  theme: config.DEFAULT_THEME,
};

const getters: GetterTree<UIState, RootState> = {
  isLoading: (state) =>
    state.status === "loading" || window.__IS_REDIRECTING === true,

  theme: (state) => state.theme || config.DEFAULT_THEME,

  referrer: (state) => state.referrer,
};

const mutations: MutationTree<UIState> = {
  status(state, status: LoadingStatus = "ready") {
    state.status = status;
    if (status === "redirecting") window.__IS_REDIRECTING = true;
  },

  theme(state, theme: string) {
    state.theme = theme;
  },

  referrer(state, referrer?: string) {
    if (referrer) {
      state.referrer = referrer;
    } else {
      delete state.referrer;
    }
  },
};

const actions: ActionTree<UIState, RootState> = {
  status({ commit }, status = "ready") {
    commit("status", status);
  },

  theme({ commit }, theme = config.DEFAULT_THEME) {
    commit("theme", theme);
  },

  storeReferrer({ commit }, referrer) {
    commit("referrer", referrer);
  },

  removeReferrer({ commit }) {
    commit("referrer");
  },
};

export const ui: Module<UIState, RootState> = {
  state,
  getters,
  mutations,
  actions,
};
