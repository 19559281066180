import { RouteRecordRaw } from "vue-router";

import {
  UserImpersonateCtrl,
  UserImpersonateLogoutCtrl,
} from "@/controllers/UserImpersonate";

import { InviteCtrl } from "@/controllers/Invite";
import { PageContext } from "@/types/ui";

export const LinkRoutes: Array<RouteRecordRaw> = [
  {
    path: "/link/impersonate/logout",
    name: "link/impersonate_logout",
    component: {},

    beforeEnter: UserImpersonateLogoutCtrl,

    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/link/impersonate/:id",
    name: "link/impersonate",
    component: {},

    beforeEnter: UserImpersonateCtrl,

    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/link/invite/:id",
    name: "link/invite",
    component: {},

    beforeEnter: InviteCtrl,
  },

  {
    path: "/link/channel-invite-accepted",
    name: "link/channel_invite_accepted",
    component: () =>
      import(
        /* webpackChunkName: "staff" */ "@/views/messages/ChannelInviteAccepted.vue"
      ),

    meta: {
      title: "Invite Accepted",
      requiresAuth: true,
      context: PageContext.User,
    },
  },
];
