import { NavigationGuard } from "vue-router";

import store from "@/store";
import { UserAuthState } from "@/types/user";

export const AuthGuard: NavigationGuard = async (to, from, next) => {
  if (store.getters.authState === UserAuthState.Unknown)
    await store.dispatch("me");

  return next();
};
