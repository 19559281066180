import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Teleport as _Teleport, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-86e4b908"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_snap_alert_title = _resolveComponent("snap-alert-title")!
  const _component_snap_alert_description = _resolveComponent("snap-alert-description")!
  const _component_snap_link = _resolveComponent("snap-link")!
  const _component_snap_alert_action = _resolveComponent("snap-alert-action")!
  const _component_snap_alert = _resolveComponent("snap-alert")!

  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _createElementVNode("div", {
      class: _normalizeClass(["notifications fixed h-auto inset-x-2 bottom-2 pt-6 md:top-4 md:right-4 md:w-1/2 md:bottom-auto md:left-auto md:pt-0 md:pl-6 lg:w-1/3 transform transition-transform", _ctx.classState])
    }, [
      (_ctx.currentNotification?.id !== '')
        ? (_openBlock(), _createBlock(_component_snap_alert, {
            key: 0,
            type: _ctx.currentNotification?.type,
            close: "true",
            border: "true",
            onSnapAlertClosed: _cache[3] || (_cache[3] = ($event: any) => (_ctx.close(false)))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_snap_alert_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.currentNotification?.title), 1)
                ]),
                _: 1
              }),
              (_ctx.currentNotification?.description)
                ? (_openBlock(), _createBlock(_component_snap_alert_description, { key: 0 }, {
                    default: _withCtx(() => [
                      (Array.isArray(_ctx.currentNotification?.description))
                        ? (_openBlock(), _createElementBlock("ul", _hoisted_1, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currentNotification?.description, (text) => {
                              return (_openBlock(), _createElementBlock("li", { key: text }, _toDisplayString(text), 1))
                            }), 128))
                          ]))
                        : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.currentNotification?.description), 1))
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _withDirectives(_createVNode(_component_snap_alert_action, { slot: "start" }, {
                default: _withCtx(() => [
                  _createVNode(_component_snap_link, {
                    text: "OK",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.close(true))),
                    size: "sm",
                    variant: _ctx.currentNotification?.type
                  }, null, 8, ["variant"])
                ]),
                _: 1
              }, 512), [
                [_vShow, _ctx.currentNotification?.name === 'alert']
              ]),
              _withDirectives(_createVNode(_component_snap_alert_action, { slot: "start" }, {
                default: _withCtx(() => [
                  _createVNode(_component_snap_link, {
                    text: "OK",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.close(true))),
                    size: "sm",
                    variant: _ctx.currentNotification?.type
                  }, null, 8, ["variant"])
                ]),
                _: 1
              }, 512), [
                [_vShow, _ctx.currentNotification?.name === 'confirm']
              ]),
              _withDirectives(_createVNode(_component_snap_alert_action, { slot: "end" }, {
                default: _withCtx(() => [
                  _createVNode(_component_snap_link, {
                    text: "Cancel",
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.close(false))),
                    size: "sm",
                    variant: _ctx.currentNotification?.type
                  }, null, 8, ["variant"])
                ]),
                _: 1
              }, 512), [
                [_vShow, _ctx.currentNotification?.name === 'confirm']
              ])
            ]),
            _: 1
          }, 8, ["type"]))
        : _createCommentVNode("", true)
    ], 2)
  ]))
}