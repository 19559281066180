import { Options, Vue } from "vue-class-component";

import { useFiniteStateMachine } from "@/core/FiniteStateMachine";

type ModalState = "NORMAL" | "SUBMITTING";
@Options<Modal>({
  props: { title: String },
  emits: ["open", "close"],
})
export default class Modal extends Vue {
  title?: string;

  isOpen = false;
  state = useFiniteStateMachine<ModalState>(["NORMAL", "SUBMITTING"]);

  open(emitting = true): void {
    if (!this.isOpen) {
      this.isOpen = true;
      if (emitting) this.$emit("open");
    }
  }

  close(emitting = true): void {
    if (this.isOpen) {
      this.isOpen = false;
      if (emitting) this.$emit("close");
    }
  }

  toggle(isOpen: boolean): void {
    if (this.isOpen !== isOpen) {
      this.isOpen = isOpen;
      this.$emit(this.isOpen ? "open" : "close");
    }
  }
}
